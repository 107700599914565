import React, { useState, useRef } from 'react';
import SelectionButton from '../../../SelectionButton/SelectionButton';

const HorizontalSelections = ({ selected, setSelected, options }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef(null);

  const handleSetSelected = (option) => {
    setSelected(option);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Multiply by 2 for faster scrolling
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  return (
    <div className='flex items-center justify-start w-full'>
      <div
        ref={containerRef}
        className="flex items-center gap-3 overflow-x-scroll scroll-smooth w-full min-w-0 md:grid-cols-4 md:grid no-scrollbar"
      >
        {options.map((op, key) => (
          <SelectionButton
            text={op.label}
            key={key}
            className="p-2 text-nowrap"
            onClick={() => handleSetSelected(op)}
            selected={selected.value === op.value}
          />
        ))}
      </div>
    </div>
  );
};

export default HorizontalSelections;
