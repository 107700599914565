import React, { useCallback, useEffect, useState } from 'react'
import Modal from '../../Modal/Modal'
import Toggle from '../../Toggle/Toggle'
import DottedLine from '../../DottedLine/DottedLine'
import CopyLink from '../../CopyLink'
import { ref, update } from 'firebase/database'
import { database } from '../../../firebase'
import { XIcon } from '../../../assets/icons'

const EventDetailsViewInviteModal = ({ isOpen, onClose, eventInfo, circleCode }) => {
  const [toggled, setToggled] = useState(!!eventInfo?.allowGuests);

  const handleEnableInviteLinkModalConfirm = useCallback(async (allowGuests) => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), { allowGuests });
    } catch (err) {
      console.error('EventDetailsView: ', err);
    }
  }, [circleCode]);

  const handleToggle = useCallback(async () => {
    const newToggled = !toggled;
    setToggled(newToggled);
    await handleEnableInviteLinkModalConfirm(newToggled);
  }, [toggled, handleEnableInviteLinkModalConfirm]);

  useEffect(() => {
    setToggled(!!eventInfo?.allowGuests);
  }, [eventInfo?.allowGuests]);

  return <Modal isOpen={isOpen} onClose={onClose} className={"max-w-[450px] w-full mx-5"}>
    <div className='z-50'>
      <div className="bg-white rounded-input relative w-full flex flex-col">
        <div className='w-full flex items-center justify-end px-6 mt-6' >
          <XIcon className="cursor-pointer z-50" fill={"black"} width={22} height={22} onClick={onClose} />
        </div>

        <div className="absolute inset-0 w-full h-full left-5 flex">
          <DottedLine color={"#80808033"} />
        </div>

        <div className='flex items-center justify-between px-10 mt-3 mb-2'>
          <span className='mr-5 text-[14px]'>To {toggled ? 'stop' : 'continue'} accepting invites, turn {toggled ? 'off' : 'on'} access here.</span>

          <Toggle
            textOn="On"
            textOff="Off"
            value={toggled}
            setValue={handleToggle}
          />
        </div>

        <div className='w-full h-[1px] bg-[#80808033]' />

        <div className='py-2 text-secondary mb-5'>
          <h2 className='px-10'>Send invites using the link below</h2>

          <div className='flex flex-row px-5 items-start mt-4'>
            <div className='w-[3px] h-5 bg-primary' />

            <span className='pl-4 text-[14px] text-black'>Copy the invite link, then paste it into an email or text message to send to your guests.</span>
          </div>
        </div>

        <div className='flex flex-row items-center justify-center mb-10 z-50'>
          <CopyLink
            id="copy-select"
            isDisabled={!eventInfo?.allowGuests}
            url={
              eventInfo.type === 'event'
                ? `event/${circleCode}/rsvp/new`
                : `join/${circleCode}`
            }
          />
        </div>
      </div>
    </div>
  </Modal>
}

export default EventDetailsViewInviteModal
