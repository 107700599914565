import React, { memo } from 'react'
import {
  EditIcon,
  NoResponse,
  TrashIcon,
  UndefinedResponse,
  YesResponse
} from '../../../assets/icons'

const GuestItem = ({ index, guest, onDelete, removeRsvp }) => {
  return (
    <tr className="border-t-1">
      <td className="py-3">
        <div className="flex-1 flex ml-1 md:ml-4 items-center">{index}</div>
      </td>
      <td className="py-3">
        <div className="flex-1 flex ml-1 md:ml-4 items-center">
          <span className="max-w-[80px] md:max-w-[230px] sm:max-w-[200px] overflow-hidden whitespace-nowrap overflow-ellipsis">
            {guest.name}
          </span>
        </div>
      </td>
      <td className="py-3">
        <div className="flex-1 flex text-placeholder items-center">
          {guest.phone}
        </div>
      </td>
      {removeRsvp && (
        <td className="flex flex-1 items-center justify-center md:justify-start py-[28px] md:py-[18px]">
          {!guest.response ? (
            <UndefinedResponse fill={'#999999'} width={20} height={20} />
          ) : guest.response === 'yes' ? (
            <YesResponse fill={'#5B4ABC'} width={20} height={20} />
          ) : (
            <NoResponse fill={'#c91634'} width={20} height={20} />
          )}
        </td>
      )}
      {!!onDelete && (
        <td className="py-3">
          <div className="flex-1 flex items-center justify-evenly">
            <TrashIcon
              fill="#00000099"
              className="cursor-pointer"
              onClick={() => onDelete(guest)}
            />
          </div>
        </td>
      )}
    </tr>
  )
}

export default memo(GuestItem)
