import {
  getSpotifyLikedSongs,
  getSpotifyRecentlyPlayed,
  getSpotifyRecommendations,
  getTopItems
} from '../Spotify'
import {
  getAppleMusicRecentTracks,
  getAppleMusicHeavyRotation,
  getAppleMusicLibrary,
  getAppleMusicRecommendations
} from '../AppleMusic/index'
import { database, get } from '../../firebase'
import { ref } from 'firebase/database'

const fetchRecentTracks = async (
  provider,
  { spotifyToken, appleMusicToken }
) => {
  if (provider === 'apple_music') {
    return await getAppleMusicRecentTracks(appleMusicToken, 0, 30, spotifyToken)
  } else {
    return await getSpotifyRecentlyPlayed(spotifyToken)
  }
}

const fetchRecommendations = async (
  tracks,
  limit,
  provider,
  { spotifyToken, appleMusicToken }
) => {
  if (provider === 'apple_music') {
    return await getAppleMusicRecommendations(appleMusicToken, spotifyToken)
  } else {
    return await getSpotifyRecommendations(spotifyToken, tracks)
  }
}

const fetchTopTracks = async (provider, { spotifyToken, appleMusicToken }) => {
  if (provider === 'apple_music') {
    return await getAppleMusicHeavyRotation(
      appleMusicToken,
      0,
      30,
      spotifyToken
    )
  } else {
    return await getTopItems(spotifyToken, 'long_term', 30)
  }
}

const fetchLibrary = async (provider, { spotifyToken, appleMusicToken }) => {
  if (provider === 'apple_music') {
    return await getAppleMusicLibrary(appleMusicToken, 0, 30, spotifyToken)
  } else {
    return await getSpotifyLikedSongs(spotifyToken)
  }
}

const fetchCircleTracks = async (code) => {
  const circlePlaylistSnap = await get(
    ref(database, `circles/${code}/playlist`)
  )
  return circlePlaylistSnap.val() || []
}

// const fetchConnections = async (
//   provider,
//   code,
//   tokens,
//   uid,
//   withTopTracks = false
// ) => {
//   let circleTracksObj = {}
//   let circleArtistsObj = {}
//   const myTracks = []
//   const myRecentTracks = await fetchRecentTracks(provider, tokens)
//   const myTopTracks = await fetchTopTracks(provider, tokens)
//   const myLibrary = await fetchLibrary(provider, tokens)

//   myTracks.push(...myRecentTracks, ...myTopTracks, ...myLibrary)

//   const circleMembersSnap = await get(ref(database, `circles/${code}/members`))
//   const circleMembers = circleMembersSnap.val() || {}

//   // Get all tracks in the current circle
//   Object.keys(circleMembers).forEach((memberID) => {
//     if (memberID !== uid) {
//       const data = circleMembers[memberID]
//       const songs = [
//         ...(data.recentlyPlayed || []),
//         ...(data.topItems || []),
//         ...(data.likedSongs || [])
//       ]
//       songs.forEach((track) => {
//         circleArtistsObj[track.artistName] = true
//         circleTracksObj[track.isrc] = true
//       })
//     }
//   })

//   if (withTopTracks) {
//     return myTracks
//       .filter((x) => !!circleTracksObj[x.isrc])
//       .concat(myTopTracks)
//       .slice(0, 30)
//   }
//   const matchingTracks = myTracks
//     .filter((x) => !!circleTracksObj[x.isrc])
//     .slice(0, 30)
//   return { matchingTracks, myTracks, circleTracksObj, circleArtistsObj }
// }

export {
  fetchRecentTracks,
  fetchRecommendations,
  fetchTopTracks,
  fetchCircleTracks,
  fetchLibrary
}
