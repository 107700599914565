import React, { memo, useMemo, useState, useCallback, useEffect } from 'react'
import Modal from '../../Modal/Modal'
import { database } from '../../../firebase'
import { ref, update, remove } from 'firebase/database'
import { useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import Time from '../../DateTime/components/Time'
import Offset from '../../DateTime/components/Offset'
import Button from '../../Button/Button'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import AmPm from '../../DateTime/components/AmPm'

export const schema = yup
  .object({
    when: yup.object().shape({
      time: yup.string().required(),
      offset: yup.string().required(),
      amPm: yup.string()
    })
  })
  .required()

const TimeModal = ({ isOpen, handleOnClose, overrideConfirm, time, offset, amPm }) => {
  const { circleCode } = useParams()
  const toast = useToast()

  const form = useForm({
    resolver: yupResolver(schema),
  })

  const handleUpdateEventDate = async (newEventData) => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), {
        time: newEventData.when.time
          ? newEventData.when.time + newEventData.when.amPm
          : null,
        timezone: newEventData.when.offset
      })

      handleOnClose()

      form.reset()

      toast({
        status: 'success',
        title: 'Time and timezone updated',
        position: 'top'
      })
      // eslint-disable-next-line no-unreachable
    } catch (err) {
      console.log('TimeModal: ', err)
      toast({
        status: 'error',
        title: 'Error updating time and timezone',
        position: 'top'
      })
    }
  }

  const handleReturn = () => {
    form.reset()
    handleOnClose()
  }

  const handleSetValue = useCallback(() => {
    form.setValue('when.time', time)
    form.setValue('when.offset', offset)
    form.setValue('when.amPm', amPm)
  }, [amPm, form, offset, time])

  useEffect(() => {
    if (time || offset || amPm) {
      handleSetValue()
    }
  }, [amPm, handleSetValue, offset, time])

  return (
    <Modal isOpen={isOpen} onClose={handleReturn} className={'mx-5'}>
      <div className="p-10 bg-white rounded-input md:mx-0 w-full sm:w-[400px]">
        <h2 className="mb-[15px]">Edit your event time and timezone</h2>

        <div className="flex flex-col items-center gap-5">
          <Time form={form} className={'w-full p-1'} />

          <AmPm form={form} className={'w-full'} />

          <Offset form={form} className={'w-full p-1'} />
        </div>

        <div className="flex items-center justify-between mt-[25px]">
          <Button type={'text'} text={'Back'} onClick={handleReturn} />
          <Button
            text={'Save'}
            onClick={form.handleSubmit(overrideConfirm || handleUpdateEventDate)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default memo(TimeModal)
