import { post } from '../api'

export const startPhoneVerification = async ({
  phone,
  channel,
  deviceOTP,
  deviceName,
  circleCode,
  guestId,
  simulateProd
}) => {
  try {
    const res = await post(`events/startPhoneVerification`, {
      body: {
        phone,
        channel,
        deviceOTP,
        deviceName,
        circleCode,
        guestId,
        simulateProd
      }
    })
    return res
  } catch (e) {
    console.log('startPhoneVerification', e)
    return { status: 'error' }
  }
}

export const verifyPhone = async ({
  circleCode,
  guestId,
  phone,
  code,
  trustDevice,
  deviceName
}) => {
  try {
    const res = await post(`events/verifyPhone`, {
      body: { circleCode, guestId, phone, code, trustDevice, deviceName }
    })
    return res
  } catch (e) {
    console.log('verifyPhone', e)
    return { status: 'error' }
  }
}
