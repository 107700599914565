import React, { useState } from 'react';
import RSVPEventDetailsView from '../../components/RSVPEventDetailsView';
import EventCarouselView from '../../components/EventCarouselView';
import Base from '../base';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { useObject } from 'react-firebase-hooks/database';
import { ref } from 'firebase/database';
import { database } from '../../firebase';
import ClipLoader from 'react-spinners/ClipLoader';
import Button from '../../components/Button/Button';
import RSVPDecisionButton from '../../components/RSVP/RSVPDecisionButton/RSVPDecisionButton';

const RSVPDecision = () => {
  const { circleCode } = useParams();
  const navigate = useNavigate()
  const [goingStatus, setGoingStatus] = useState(null);

  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  );
  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  );

  const eventInfo = eventSnap?.val();
  const guestsInfo = guestsSnap?.val();

  const handleDecisionClick = (status) => {
    setGoingStatus(status);
  };

  const handleGoToDetails = () => {
    navigate(`/event/${circleCode}/rsvp/new/info`, {
      state: { going: goingStatus }
    })
  }

  const renderLoading = () => (
    <div className="flex flex-1 items-center justify-center">
      <ClipLoader color="#5B4ABC" />
    </div>
  );

  const renderContent = () => (
    <div className="flex flex-1 mt-[18px] items-center h-full flex-col relative">
      <div className="w-full flex mt-[18px] items-center h-full flex-col mb-[50px] relative">
        <div className="w-full flex gap-5 flex-col sm:flex-row">
          <div className="order-2 flex-1">
            <RSVPEventDetailsView
              rsvpId={''}
              circleCode={circleCode}
              eventInfo={eventInfo}
              guestsInfo={guestsInfo}
            />
          </div>
          <div className="order-1 sm:w-[42%] w-[100%]">
            <EventCarouselView
              isGuest
              rsvpId={''}
              circleCode={circleCode}
              eventInfo={eventInfo}
              removeAttendingCard
            />
          </div>
        </div>
      </div>

      <div className="w-full mt-[10px] mb-20">
        <div className="flex flex-col mt-4">
          <span className="text-[18px] font-bold sm:mb-3 mb-8 sm:self-start self-center">
            Respond to this event
          </span>
        </div>

        <div className="w-full flex flex-col items-center justify-between px-5 sm:flex-row gap-3">
          <RSVPDecisionButton
            going
            onClick={() => handleDecisionClick(true)}
            selected={goingStatus === true}
          />
          <RSVPDecisionButton
            onClick={() => handleDecisionClick(false)}
            selected={goingStatus === false}
          />
        </div>

        <div className="w-full flex items-center justify-center mt-8">
          <Button text="Continue" onClick={handleGoToDetails} disabled={goingStatus === null} />
        </div>
      </div>
    </div>
  );

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      verifiedOnly={true}
      blackLogo
      headerBackgroundColor={'transparent'}
      menuColor={'#000'}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Coteri${eventInfo?.name ? ` - Reply to ${eventInfo.name}` : ''} invitation`}</title>
        <meta name="description" content="Reply to the online invitation." />
      </Helmet>
      {eventLoading ? renderLoading() : renderContent()}
    </Base>
  );
};

export default RSVPDecision;
