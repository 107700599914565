export const generateMeetingTimes = () => {
  const meetingTimes = [];
  const hours = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  const minutes = ['00', '15', '30', '45'];

  hours.forEach((hour) => {
    minutes.forEach((minute) => {
      meetingTimes.push(`${hour}:${minute}`);
    });
  });

  return meetingTimes;
}