import React, { memo } from 'react'
import { CheckIcon } from '../../assets/icons'
import { twMerge } from 'tailwind-merge'

const Checkbox = ({ checked, onClick, text, className }) => {
  return (
    <div className={twMerge('flex items-center', className)}>
      <div
        className={`w-[20px] h-[20px] border-1 ${checked ? 'border-primary bg-primary' : 'border-black bg-transparent'} rounded-md flex items-center justify-center cursor-pointer`}
        onClick={onClick}
      >
        <CheckIcon width={14} height={14} fill={'#FFF'} />
      </div>
      {text && <span className="ml-[10px] font-[14px]">{text}</span>}
    </div>
  )
}

export default memo(Checkbox)
