import React, { memo, useRef, useState } from 'react'
import Button from '../../Button/Button'
import { Grid, Image, useToast } from '@chakra-ui/react'
import {
  ref as storageRef,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage'
import { database, storage } from '../../../firebase'
import Modal from '../../Modal/Modal'
import { ref, update } from 'firebase/database'
import { XIcon, UploadIcon } from '../../../assets/icons'
import ClipLoader from 'react-spinners/ClipLoader'
import BabyShower1 from '../../../assets/images/eventDefault/babyShower1.png'
import BabyShower2 from '../../../assets/images/eventDefault/babyShower2.png'
import BabyShower3 from '../../../assets/images/eventDefault/babyShower3.png'
import CocktailParty1 from '../../../assets/images/eventDefault/cocktailParty1.png'
import CocktailParty2 from '../../../assets/images/eventDefault/cocktailParty2.png'
import CocktailParty3 from '../../../assets/images/eventDefault/cocktailParty3.png'
import BirthdayParty1 from '../../../assets/images/eventDefault/birthday1.png'
import BirthdayParty2 from '../../../assets/images/eventDefault/birthday2.png'
import BirthdayParty3 from '../../../assets/images/eventDefault/birthday3.png'
import BirthdayParty4 from '../../../assets/images/eventDefault/birthday4.png'
import BirthdayParty5 from '../../../assets/images/eventDefault/birthday5.png'
import BirthdayParty6 from '../../../assets/images/eventDefault/birthday6.png'
import SummerBBQ1 from '../../../assets/images/eventDefault/summerBBQ1.png'
import SummerBBQ2 from '../../../assets/images/eventDefault/summerBBQ2.png'
import SummerBBQ3 from '../../../assets/images/eventDefault/summerBBQ3.png'
import PotluckParty1 from '../../../assets/images/eventDefault/potluckParty1.png'
import PotluckParty2 from '../../../assets/images/eventDefault/potluckParty2.png'
import PotluckParty3 from '../../../assets/images/eventDefault/potluckParty3.png'
import DinnerParty1 from '../../../assets/images/eventDefault/dinnerParty1.png'
import DinnerParty2 from '../../../assets/images/eventDefault/dinnerParty2.png'
import DinnerParty3 from '../../../assets/images/eventDefault/dinnerParty3.png'
import FamilyReunion1 from '../../../assets/images/eventDefault/familyReunion1.png'
import FamilyReunion2 from '../../../assets/images/eventDefault/familyReunion2.png'
import FamilyReunion3 from '../../../assets/images/eventDefault/familyReunion3.png'
import BridalShower1 from '../../../assets/images/eventDefault/bridalShower1.png'
import BridalShower2 from '../../../assets/images/eventDefault/bridalShower2.png'
import BridalShower3 from '../../../assets/images/eventDefault/bridalShower3.png'
import FourthJuly1 from '../../../assets/images/eventDefault/fourthJuly1.png'
import FourthJuly2 from '../../../assets/images/eventDefault/fourthJuly2.png'
import FourthJuly3 from '../../../assets/images/eventDefault/fourthJuly3.png'
import FourthJuly4 from '../../../assets/images/eventDefault/fourthJuly4.png'
import FourthJuly5 from '../../../assets/images/eventDefault/fourthJuly5.png'
import SimpleParty1 from '../../../assets/images/eventDefault/simpleParty1.png'
import SimpleParty2 from '../../../assets/images/eventDefault/simpleParty2.png'
import SimpleParty3 from '../../../assets/images/eventDefault/simpleParty3.png'
import SimpleParty4 from '../../../assets/images/eventDefault/simpleParty4.png'
import SimpleParty5 from '../../../assets/images/eventDefault/simpleParty5.png'
import HorizontalSelections from './HorizontalSelections/HorizontalSelections'
import OptionImage from './OptionImage/OptionImage'

const options = [
  {
    label: 'Cocktail parties',
    value: 'cocktail-parties',
    images: [CocktailParty1, CocktailParty2, CocktailParty3]
  },
  {
    label: 'Birthday parties',
    value: 'birthday-parties',
    images: [BirthdayParty1, BirthdayParty2, BirthdayParty3, BirthdayParty4, BirthdayParty5, BirthdayParty6]
  },
  {
    label: 'Summer BBQ',
    value: 'summer-bbq',
    images: [SummerBBQ1, SummerBBQ2, SummerBBQ3]
  },
  {
    label: 'Potlucks',
    value: 'potlucks',
    images: [PotluckParty1, PotluckParty2, PotluckParty3]
  },
  {
    label: 'Baby Showers',
    value: 'baby-showers',
    images: [BabyShower1, BabyShower2, BabyShower3]
  },
  {
    label: 'Bridal Showers',
    value: 'bridal-showers',
    images: [BridalShower1, BridalShower2, BridalShower3]
  },
  {
    label: '4th of July',
    value: 'fourth-of-july',
    images: [FourthJuly1, FourthJuly2, FourthJuly3, FourthJuly4, FourthJuly5]
  },
  {
    label: 'Simple designs',
    value: 'simple-designs',
    images: [SimpleParty1, SimpleParty2, SimpleParty3, SimpleParty4, SimpleParty5]
  },
  {
    label: 'Dinner party',
    value: 'dinner-party',
    images: [DinnerParty1, DinnerParty2, DinnerParty3]
  },
  {
    label: 'Family reunion',
    value: 'family-reunion',
    images: [FamilyReunion1, FamilyReunion2, FamilyReunion3]
  },
]

const PhotoOrVideoModal = ({ isOpen, handleOnClose, removeDesignOnCanva, overrideSelect, circleCode, action }) => {
  const [uploading, setUploading] = useState(false)
  const [selected, setSelected] = useState(options[0])
  const fileInputRef = useRef(null)
  const toast = useToast()

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = async (event, filesToUpload) => {
    const files = filesToUpload || Array.from(event.target.files)

    if (files.length === 0 || files.length > 1) return

    setUploading(true)

    const uploadPromises = files.map(async (file) => {
      const storagePath = `events/${circleCode}/image`
      const fileRef = storageRef(storage, storagePath)
      const result = await uploadBytes(fileRef, file)
        .then(() => ({ success: true, path: storagePath }))
        .catch((error) => ({ success: false, path: storagePath, error }))
      if (!result.success) return null
      const url = await getDownloadURL(fileRef)
      return url
    })

    const results = await Promise.all(uploadPromises)
    const successUploads = results.filter((result) => !!result)
    const failedUploads = results.filter((result) => !result)

    if (successUploads.length > 0) {
      await update(ref(database, `circles/${circleCode}/info`), {
        url: successUploads[0]
      })
    }

    if (successUploads.length > 0) {
      toast({
        title: 'Upload successful',
        description: `${successUploads.length} files uploaded successfully.`,
        status: 'success',
        duration: 5000,
        isClosable: true
      })
    }

    if (failedUploads.length > 0) {
      toast({
        title: 'Some uploads failed',
        description: `${failedUploads.length} files failed to upload.`,
        status: 'error',
        duration: 5000,
        isClosable: true
      })
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = null
    }
    handleOnClose()
    setUploading(false)
  }

  const handleSetImage = async (image) => {
    const response = await fetch(image);
    const blob = await response.blob();

    const parts = image.split('/');
    const fileName = parts[parts.length - 1];

    const file = new File([blob], fileName, { type: blob.type });

    if (overrideSelect) {
      overrideSelect([file])
      return
    }

    handleFileChange(null, [file])
  }

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} className={"max-w-[700px] w-full mx-5"}>
      <div className="p-2 bg-modal-bg rounded-input mx-3 md:mx-0 flex flex-col">
        <div className='flex items-center justify-between mt-4 mx-5'>
          <div className='gap-3 flex items-center'>
            {uploading ? (
              <div className="flex items-center justify-center">
                <ClipLoader color="#5B4ABC" />
              </div>
            ) : (<>
              <div className='flex sm:flex-row flex-col gap-2'>
                <>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    accept="image/*"
                  />
                  <Button
                    type="terciary"
                    size="small"
                    text={'Upload your own design'}
                    onClick={handleButtonClick}
                    className="text-sm flex items-center"
                    iconLeft={<UploadIcon className="mr-1" fill={"#5B4ABC"} width={20} height={20} />}
                  />
                </>
                {!removeDesignOnCanva && <Button
                  type="terciary"
                  size="small"
                  text={'Design on Canva'}
                  onClick={action}
                  className="text-sm"
                />}
              </div>
            </>
            )}
          </div>
          <div
            onClick={handleOnClose}
            className="cursor-pointer"
          >
            <XIcon fill={'black'} width={25} height={25} />
          </div>
        </div>

        <div className="flex flex-col px-5 items-center justify-center my-5 overflow-hidden">
          <HorizontalSelections setSelected={setSelected} selected={selected} options={options} />

          <Grid templateColumns={{
            base: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)'
          }} gap={3} className='overflow-scroll no-scrollbar mt-3 sm:max-h-[500px] max-h-[400px] min-h-[300px] flex items-center'>
            {selected.images?.map((image, key) => (
              <OptionImage key={key} image={image} incomingKey={key} handleSetImage={handleSetImage} />
            ))}
          </Grid>
        </div>
      </div>
    </Modal>
  )
}

export default memo(PhotoOrVideoModal)
