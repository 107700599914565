import React, { memo, useEffect, useMemo, useState } from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'

import { useObject } from 'react-firebase-hooks/database'
import { ref, database, auth } from '../../firebase'
import PhotoOrVideoModal from '../../components/EventDetails/PhotoOrVideoModal/PhotoOrVideoModal'
import { useNavigate, useParams } from 'react-router-dom'
import CanvaModal from '../../components/EventDetails/CanvaModal/CanvaModal'
import EventCarouselView from '../../components/EventCarouselView'
import EventDetailsView from '../../components/EventDetailsView'
import { Helmet } from 'react-helmet'
import RequestTab from './Tab/RequestTab/RequestTab'
import MusicTab from './Tab/MusicTab/MusicTab'
import { useAuthState } from 'react-firebase-hooks/auth'
import EventDetailsViewCreatedEvent from '../../components/EventDetailsView/EventDetailsViewCreatedEvent/EventDetailsViewCreatedEvent'
import { update } from 'firebase/database'

const EventDetails = () => {
  const { circleCode } = useParams()
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth)
  const [toggleTab, setToggleTab] = useState(0)
  const [showPopUp, setShowPopUp] = useState(null)
  const [canvaModal, setCanvaModal] = useState(false)
  const [uploadDesignModal, setUploadDesignModal] = useState(false)
  const [firstTimeModal, setFirstTimeModal] = useState(false)

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )
  const [playlistSnap, playlistLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/playlist`)
  )
  const [itemsSnap, itemsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/items`)
  )

  const playlist = playlistSnap?.val()
  const itemsInfo = itemsSnap?.val()
  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])
  const eventInfo = eventSnap?.val()

  const [toggleCreateItemsList, setToggleCreateItemsList] = React.useState(false)
  const [toggleCreatePlaylist, setToggleCreatePlaylist] = React.useState(false)

  useEffect(() => {
    if (!user && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
      return
    }
    if (!eventLoading && !loading) {
      const isHost = eventInfo?.hostID === user?.uid
      if (!eventInfo || !isHost) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      // if (eventInfo.withCanva && !eventInfo.completed) {
      //   navigate(`/event/update/${circleCode}/info`, { replace: true })
      //   return
      // }
    }
  }, [eventLoading, loading, user, eventInfo, navigate, circleCode])

  const handleCanvaModal = () => {
    setUploadDesignModal(false)
    setCanvaModal(true)
  }

  const handleTogglePlaylist = () => {
    setToggleCreatePlaylist(true)
    setToggleTab(1)
  }

  const handleToggleItemList = () => {
    setToggleCreateItemsList(true)
    setToggleTab(0)
  }

  const handleFirstTimeInEvent = async () => {
    try {
      setFirstTimeModal(true)

      await update(ref(database, `circles/${circleCode}/info`), {
        firstTime: false
      })

    } catch (err) {
      console.log('EventDetails: failed updating first time', err)
    }
  }

  useEffect(() => {
    if (itemsInfo) {
      setToggleCreateItemsList(true)
    }

    if (!!eventInfo?.musicCollab) {
      setToggleCreatePlaylist(true)
    }

    if (eventInfo?.musicCollab && Object.keys(itemsInfo || {})?.length === 0 && !itemsLoading && !eventLoading) {
      setToggleTab(1)
    }
  }, [eventInfo?.musicCollab, itemsInfo, itemsLoading, eventLoading])


  useEffect(() => {
    if (eventInfo && !eventLoading && eventInfo?.firstTime) {
      handleFirstTimeInEvent()
    }
  }, [eventInfo, eventLoading])

  if (
    loading ||
    eventLoading ||
    guestsLoading ||
    playlistLoading ||
    itemsLoading
  ) {
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        showPopUp={showPopUp}
        onChangePopUp={setShowPopUp}
        onSuccess={() => setShowPopUp(null)}
        onCloseLogin={() => setShowPopUp(null)}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - Online invitation details</title>
          <meta
            name="description"
            content="See and edit the details for your invitation. Track responses. See the music playlist. Copy the invite link. Anyone with the invite link can add songs to the music playlist."
          />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      verifiedOnly={true}
      showPopUp={showPopUp}
      onChangePopUp={setShowPopUp}
      onSuccess={() => setShowPopUp(null)}
      onCloseLogin={() => setShowPopUp(null)}
      blackLogo
      headerBackgroundColor={'transparent'}
      menuColor={'#000'}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Coteri${!!eventInfo?.name ? ` - ${eventInfo.name}` : ''}`}</title>
        <meta
          name="description"
          content="See and edit the details for your invitation. Track responses. See the music playlist. Copy the invite link. Anyone with the invite link can add songs to the music playlist."
        />
      </Helmet>
      {eventLoading ? (
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      ) : (
        <div className="flex flex-1 mt-[18px] items-center h-full flex-col mb-[50px] relative">
          <div className="w-full flex gap-5 flex-col sm:flex-row">
            <div className="order-1 sm:w-[42%] w-[100%]">
              <EventCarouselView
                handleOpenModal={() => setUploadDesignModal(true)}
                eventInfo={eventInfo}
              />
            </div>
            <div className="order-2 flex-1">
              <EventDetailsView
                circleCode={circleCode}
                eventInfo={eventInfo}
                guestsInfo={guestsInfo}
              />
            </div>
          </div>

          <div className="w-full mt-[10px]">
            <div>
              <div className='w-full flex items-center justify-center mt-10 sm:mb-0 mb-2'>
                <div className='flex flex-1 bg-off-white h-[2px] mr-2' />
                <span className='text-[13px] text-secondary'>Additional event features</span>
                <div className='flex flex-1 bg-off-white h-[2px] ml-2' />
              </div>

              <ul className={`flex w-full items-center mb-5 sm:gap-2 gap-5 flex-col mt-1 sm:flex-row`}>
                <li
                  onClick={() => toggleCreateItemsList ? setToggleTab(0) : handleToggleItemList()}
                  className={`cursor-pointer relative w-full sm:w-[50%] pb-1 text-center ${toggleCreateItemsList ? `${toggleTab === 0 ? 'border-b-2 border-primary' : ''}` : 'bg-off-white rounded-md py-2 px-4 text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]'} order-${toggleCreateItemsList && toggleCreatePlaylist ? '1' : toggleCreateItemsList ? '2' : '1'}`}
                >
                  {toggleCreateItemsList ? "What to bring" : "+ Add “What to bring” list"}
                </li>

                <li
                  onClick={() => toggleCreatePlaylist ? setToggleTab(1) : handleTogglePlaylist()}
                  className={`cursor-pointer relative w-full sm:w-[50%] pb-1 text-center ${toggleCreatePlaylist ? `${toggleTab === 1 ? 'border-b-2 border-primary' : ''}` : 'bg-off-white rounded-md py-2 px-4 text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]'} order-${toggleCreateItemsList && toggleCreatePlaylist ? '2' : toggleCreatePlaylist ? '2' : '1'}`}

                >
                  {toggleCreatePlaylist ? "Music" : "+ Add Music playlist"}
                </li>
              </ul>

              {toggleCreateItemsList && <RequestTab
                circleInfo={eventInfo}
                isToggled={toggleTab === 0}
                itemsInfo={itemsInfo}
                eventInfo={eventInfo}
                guestsInfo={guestsInfo}
                owner={true}
              />}

              {toggleCreatePlaylist && <MusicTab
                isToggled={toggleTab === 1}
                circleInfo={eventInfo}
                playlist={playlist}
              />}
            </div>
          </div>
        </div>
      )}

      <CanvaModal
        circleCode={circleCode}
        isOpen={canvaModal}
        handleOnClose={() => setCanvaModal(false)}
        oldOcassion={eventInfo.ocassion}
      />

      <PhotoOrVideoModal
        isOpen={uploadDesignModal}
        circleCode={circleCode}
        handleOnClose={() => setUploadDesignModal(false)}
        action={handleCanvaModal}
      />

      <EventDetailsViewCreatedEvent isOpen={firstTimeModal} onClose={() => setFirstTimeModal(false)} />
    </Base>
  )
}

export default memo(EventDetails)
