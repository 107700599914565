import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { auth, database } from '../firebase'
import Input from '../components/Input/Input'
import InvitesModal from '../components/InvitesModal'
import Button from '../components/Button/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import GuestList from '../components/ManageInvites/GuestsList/GuestList'
import { SearchIcon } from '../assets/icons'
import * as yup from 'yup'
import { Container, Flex } from '@chakra-ui/react'
import Base from './base'
import { useObject } from 'react-firebase-hooks/database'
import { ref, update } from 'firebase/database'
import { Helmet } from 'react-helmet'
import { setEventData } from '../services/database'

export const schema = yup
  .object({
    search: yup.string()
  })
  .required()

const RSVPs = () => {
  const { circleCode } = useParams()
  const [fullList, setFullList] = useState([])
  const [list, setList] = useState([])
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()
  const [toggled, setToggled] = useState(false)
  const [popUp, setPopUp] = useState(null)

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [search] = form.watch(['search'])

  const [circleSnap, circleLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const circleInfo = circleSnap?.val()
  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])

  const searchList = useCallback(
    (text) => {
      setList(
        fullList.filter(
          (x) =>
            x.name?.toLowerCase()?.search(text) > -1 ||
            x.name?.toLowerCase()?.includes(text) ||
            x.email?.toLowerCase()?.replace(/[() ]/g, '').search(text) > -1 ||
            x.email?.toLowerCase()?.includes(text)
        )
      )
    },
    [fullList]
  )

  const onSearch = useCallback(() => {
    if (!search || search === '') {
      setList(fullList)
    } else {
      searchList(search.toLowerCase())
    }
  }, [fullList, search, searchList])

  const handleReturn = () => {
    navigate(`/event/${circleCode}`)
  }

  const addNewGuests = () => {
    navigate(`/event/update/${circleCode}/guests`)
  }

  const handleToggle = async () => {
    setToggled(!toggled)
    if (!toggled) {
      setPopUp('invites')
    } else {
      await update(ref(database, `circles/${circleCode}/info`), {
        allowGuests: false
      })
    }
  }

  const setResponse = async (allow) => {
    if (allow) {
      await update(ref(database, `circles/${circleCode}/info`), {
        allowGuests: true
      })
      setToggled(true)
    } else {
      setToggled(false)
    }

    setPopUp(null)
  }

  const handleUpgrade = () => {
    setEventData({ circleCode })
    window.open(
      `https://buy.stripe.com/eVa5nwa4ifFz63e5kI?client_reference_id=${circleCode}`,
      '_blank',
      'noopener,noreferrer'
    )
  }

  useEffect(() => {
    onSearch(search)
  }, [fullList, onSearch, search])

  useEffect(() => {
    if (!user && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
      return
    }
    if (!circleLoading && !guestsLoading && !loading) {
      const isHost = circleInfo?.hostID === user?.uid
      if (!circleInfo || !isHost) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      // if (eventInfo.withCanva && !eventInfo.completed) {
      //   navigate(`/event/update/${circleCode}/info`, { replace: true })
      //   return
      // }
      const list = Object.entries(guestsInfo)
        .map(([gKey, gData]) => ({
          id: gKey,
          ...gData
        }))
        .sort((a, b) => (a.response === 'yes' ? -1 : !b.response ? 1 : 0))
      setFullList(list)
      setToggled(circleInfo?.allowGuests)
    }
  }, [
    circleLoading,
    guestsLoading,
    loading,
    user,
    circleInfo,
    guestsInfo,
    navigate,
    circleCode
  ])

  if (loading || !user || circleLoading || guestsLoading)
    return (
      <Base hideBg={true} bottomBar={true}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Track online invitation RSVPs - See guest responses - Coteri
          </title>
          <meta
            name="description"
            content="See the replies to your online invitation. Send more invitations via email."
          />
        </Helmet>
        <Flex bottom="62px" left="0" right="0" position="fixed" zIndex="2">
          <Container
            bg="white"
            p="0"
            w="100%"
            maxW="800px"
            borderTopWidth="1px"
            borderColor="#EEE"
            boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
          >
            <Flex direction="column">
              <Flex
                bg="white"
                h="80px"
                align="center"
                justify="space-between"
                px={{ base: '16px', md: '36px' }}
              ></Flex>
            </Flex>
          </Container>
        </Flex>
      </Base>
    )

  return (
    // @ts-expect-error nomeaning
    <Base hideBg={true} bottomBar={false} allowFullHeight={true}>
      <div className="flex flex-1 flex-col items-center md:items-stretch">
        <h2 className="mobile:text-[20px] text-[16px]">See who's attending</h2>

        <Input
          register={form.register('search')}
          form={form}
          type="icon"
          placeholder="Search through guests..."
          className="mt-[15px] w-full"
          iconRight={<SearchIcon fill="#000" />}
        />

        <div className="mt-[10px] flex flex-col items-center justify-around sm:flex-row">
          <div className="px-10 py-2 border-1 border-primary rounded-button mb-2 sm:mb-0">
            <span>
              <span className='font-semibold'>{list.filter((guest) => guest.response === 'yes').length}</span>{' '}
              I'm Going 🎉
            </span>
          </div>
          <div className="px-10 py-2 border-1 border-error rounded-button mb-2 sm:mb-0">
            <span>
              <span className='font-semibold'>{list.filter((guest) => guest.response === 'no').length}</span>{' '}
              Unable to attend 🙁
            </span>
          </div>
        </div>

        {list.length === 0 ? (
          <div className="flex flex-1 items-center justify-center">
            <span>No guests found</span>
          </div>
        ) : (
          <div className="flex flex-1 items-start justify-center w-full">
            <GuestList guests={list} removeRsvp />
          </div>
        )}

        <div className="flex items-end justify-between mt-[18px] w-full">
          <Button
            type="text"
            size="medium"
            text={'Back'}
            onClick={handleReturn}
          />
        </div>
      </div>

      <InvitesModal
        type={circleInfo?.type}
        popUp={popUp}
        setResponse={setResponse}
        handleUpgrade={handleUpgrade}
      />
    </Base>
  )
}

export default RSVPs
