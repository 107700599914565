import React, { useState } from 'react'
import { Flex, Image } from '@chakra-ui/react'
import EventDefaultImageVertical from '../../../assets/images/event_invited_vertical.png'
import { EditBorderIcon } from '../../../assets/icons'
import PhotoOrVideoModal from '../../EventDetails/PhotoOrVideoModal/PhotoOrVideoModal'
import { useWatch } from 'react-hook-form'

const NewEventImage = ({ form }) => {
  const [uploadDesignModal, setUploadDesignModal] = useState(false)

  const eventImage = useWatch({
    name: 'eventImage',
    control: form.control
  })

  const handleImage = (image) => {
    form.setValue('eventImage', image)

    setUploadDesignModal(false)
  }

  const eventImageSrc = eventImage && eventImage[0] && eventImage[0].name ? URL.createObjectURL(eventImage[0]) : EventDefaultImageVertical;

  return (
    <div className="flex flex-col w-[100%] relative sm:mb-0 mb-5">
      <Flex bg="white" overflow="hidden" direction="column" borderRadius="8px">
        <Image src={eventImageSrc} w="100%" h="auto" />
      </Flex>

      <div
        className="absolute bottom-0 right-0 w-[40px] h-[40px] bg-primary flex items-center justify-center cursor-pointer rounded-br"
        onClick={() => setUploadDesignModal(true)}
      >
        <EditBorderIcon fill={'white'} />
      </div>

      <PhotoOrVideoModal
        isOpen={uploadDesignModal}
        handleOnClose={() => setUploadDesignModal(false)}
        overrideSelect={handleImage}
        removeDesignOnCanva
      />
    </div>
  )
}

export default NewEventImage
