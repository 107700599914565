import React from 'react'

const RSVPDecisionButton = ({ going, selected, onClick }) => {
  return (
    <div className={`py-5 w-[300px] border-1 border-primary rounded-md flex items-center justify-center cursor-pointer ${selected ? 'bg-primary' : 'bg-transparent'}`} onClick={onClick}>
      <span className={`pointer-events-none font-semibold ${selected ? 'text-white' : 'text-black'}`}>{going ? "I'm Going 🎉" : "Unable to attend 🙁"}</span>
    </div>
  )
}

export default RSVPDecisionButton
