import React, { memo, useCallback } from 'react'
import Multiline from './variaties/Multiline'
import Icon from './variaties/Icon'
import Default from './variaties/Default'
import Select from './variaties/Select'
import Autocomplete from './variaties/Autocomplete'
import MultilineWithFixedPlaceholder from './variaties/MultilineWithFixedPlaceholder'
import MultilineWithButton from './variaties/MultilineWithButton'
import NoStyle from './variaties/NoStyle'
import LargeSelect from './variaties/LargeSelect'
import SearchableSelect from './variaties/SearchableSelect'
import MaskedInput from './variaties/MaskedInput'

const Input = ({ type, className, register, hasError, form, ...props }) => {
  const inputComponent = useCallback(() => {
    switch (type) {
      case 'select':
        return (
          <Select
            options={[]}
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'large-select':
        return (
          <LargeSelect
            options={[]}
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'icon':
        return (
          <Icon
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'multiline':
        return (
          <Multiline
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'masked':
        return (
          <MaskedInput
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'multiline-fixed-placeholder':
        return (
          <MultilineWithFixedPlaceholder
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'autocomplete':
        return (
          <Autocomplete
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'multiline-button':
        return (
          <MultilineWithButton
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'no-style':
        return (
          <NoStyle
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      case 'searchable-select':
        return (
          <SearchableSelect
            options={[]}
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
      default:
        return (
          <Default
            className={className}
            register={register}
            hasError={hasError}
            form={form}
            {...props}
          />
        )
    }
  }, [className, register, form, hasError, props, type])

  return inputComponent()
}

export default memo(Input)
