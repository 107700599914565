import React from 'react'
import { twMerge } from 'tailwind-merge'

const SelectionButton = ({ text, selected, onClick, className }) => {
  return (
    <button className={twMerge(`flex items-center justify-center w-[100%] py-3 rounded-input border-1 transition-opacity duration-300 ease-in-out ${selected ? 'border-primary bg-primary text-white font-semibold' : 'border-input-border opacity-70 hover:opacity-100'} relative`, className)} onClick={onClick}>
      <span>{text}</span>
    </button>
  )
}

export default SelectionButton