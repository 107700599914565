import React, { memo, useEffect, useMemo, useState } from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'
import { useObject } from 'react-firebase-hooks/database'
import { ref, database } from '../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import RSVPEventDetailsView from '../../components/RSVPEventDetailsView'
import EventCarouselView from '../../components/EventCarouselView'
import { Helmet } from 'react-helmet'
import RequestTab from '../EventDetails/Tab/RequestTab/RequestTab'
import MusicTab from '../EventDetails/Tab/MusicTab/MusicTab'
import PhoneVerificationModal from '../../components/NewGuest/PhoneVerificationModal/PhoneVerificationModal'

const RSVPEventDetails = () => {
  const { circleCode, rsvpId } = useParams()
  const navigate = useNavigate()
  const [toggleTab, setToggleTab] = useState(0)
  const [showPopUp, setShowPopUp] = useState(null)
  const [phoneToVerify, setPhoneToVerify] = useState(null)

  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId}`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )
  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode}/info`)
  )
  const [playlistSnap, playlistLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/playlist`)
  )
  const [itemsSnap, itemsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/items`)
  )

  const playlist = playlistSnap?.val()
  const itemsInfo = itemsSnap?.val()
  const eventInfo = eventSnap?.val()
  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])
  const rsvpInfo = rsvpSnap?.val()
  const oldAnswer = rsvpInfo?.response

  const hasRequestList = !!eventInfo?.extraCollab ?? false
  const hasMusic = !!eventInfo?.musicCollab ?? false

  useEffect(() => {
    if (!eventLoading && !rsvpLoading) {
      if (
        !eventInfo ||
        !rsvpInfo ||
        !rsvpInfo.phone ||
        (!eventInfo.completed && !eventInfo.withCanva)
      ) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      if (!rsvpInfo.verified) {
        setPhoneToVerify(rsvpInfo.phone)
        return
      }
      setPhoneToVerify(null)
    }
  }, [eventLoading, rsvpLoading, eventInfo, rsvpInfo, navigate, circleCode])

  useEffect(() => {
    if (!hasRequestList && hasMusic) {
      setToggleTab(1)
    }
  }, [hasMusic, hasRequestList])

  if (
    eventLoading ||
    rsvpLoading ||
    guestsLoading ||
    playlistLoading ||
    itemsLoading
  ) {
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        showPopUp={showPopUp}
        onChangePopUp={setShowPopUp}
        onSuccess={() => setShowPopUp(null)}
        onCloseLogin={() => setShowPopUp(null)}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - Online invitation details</title>
          <meta
            name="description"
            content="See the details for your invitation. Track responses. See the music playlist."
          />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      verifiedOnly={true}
      showPopUp={showPopUp}
      onChangePopUp={setShowPopUp}
      onSuccess={() => setShowPopUp(null)}
      onCloseLogin={() => setShowPopUp(null)}
      blackLogo
      headerBackgroundColor={'transparent'}
      menuColor={'#000'}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Coteri${!!eventInfo?.name ? ` - ${eventInfo.name}` : ''}`}</title>
        <meta
          name="description"
          content="See the details for your invitation. Track responses. See the music playlist."
        />
      </Helmet>

      <div className="flex flex-1 mt-[18px] items-center h-full flex-col relative">
        <div className="w-full flex gap-5 stripe-column:flex-row flex-col mb-10">
          <div className="w-full flex gap-5 flex-col sm:flex-row">
            <div className="order-1 sm:w-[42%] w-[100%]">
              <EventCarouselView
                isGuest
                rsvpId={rsvpId}
                circleCode={circleCode}
                eventInfo={eventInfo}
                oldAnswer={rsvpInfo?.response ?? 'no'}
              />
            </div>
            <div className="order-2 flex-1">
              <RSVPEventDetailsView
                rsvpId={rsvpId}
                circleCode={circleCode}
                eventInfo={eventInfo}
                guestsInfo={guestsInfo}
                oldAnswer={oldAnswer}
              />
            </div>
          </div>
        </div>

        <div className="w-full mt-[10px]">
          <div>
            <ul className={`flex w-full items-center sm:gap-2 gap-5 mt-7 mb-4 sm:flex-row flex-col`}>
              {hasRequestList && (
                <li
                  onClick={() => setToggleTab(0)}
                  className={`cursor-pointer relative w-full pb-1 text-center ${toggleTab === 0 ? 'border-b-2 border-primary' : ''}`}
                >
                  What to bring list
                </li>
              )}

              {hasMusic && (
                <li
                  onClick={() => setToggleTab(1)}
                  className={`cursor-pointer relative w-full pb-1 text-center ${toggleTab === 1 ? 'border-b-2 border-primary' : ''}`}
                >
                  Music
                </li>
              )}
            </ul>

            {hasRequestList ? (
              <RequestTab
                circleInfo={eventInfo}
                rsvpId={rsvpId}
                isToggled={toggleTab === 0}
                itemsInfo={itemsInfo}
                eventInfo={eventInfo}
                guestsInfo={guestsInfo}
                title={
                  'Help out your host. Bring something from the list below to the event. You may also add your own item by clicking the "Add" button above.'
                }
              />
            ) : null}
            {hasMusic ? (
              <MusicTab
                rsvpId={rsvpId}
                isToggled={toggleTab === 1}
                circleInfo={eventInfo}
                playlist={playlist}
                alreadyHasResponse={true}
              />
            ) : null}
          </div>
        </div>
      </div>
      {!!rsvpId && !!phoneToVerify ? (
        <PhoneVerificationModal
          circleCode={circleCode}
          rsvpId={rsvpId}
          phone={phoneToVerify}
          onClose={() => setPhoneToVerify(false)}
        />
      ) : null}
    </Base>
  )
}

export default memo(RSVPEventDetails)
