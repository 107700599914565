import React, { memo } from 'react'
import PopularCard from '../PopularCard/PopularCard'
import { useMediaQuery } from '../../../hook/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'

const PopularEvents = () => {
  const navigate = useNavigate()

  const breakpoints = {
    sm: 600
  }

  const { sm } = useMediaQuery(breakpoints)

  const handleCardClick = (selection) => {
    amplitude.track('Create Event CTA clicked', {
      'CTA label': selection
    })

    navigate(`/event/new/${selection}`)
  }

  return (
    <div className="relative flex flex-col justify-between">
      <div className="flex items-start justify-start">
        <div className="flex flex-col items-start justify-start w-full">
          <span className="text-xl font-bold text-secondary">
            Popular events
          </span>

          <div className="overflow-hidden mt-5 w-full">
            <div className="flex flex-col sm:flex-row sm:gap-5 w-full mb-5">
              <PopularCard
                text={'Cocktail Parties'}
                image={require('../../../assets/images/home/drinks.jpg')}
                onClick={() => handleCardClick('cocktail-parties')}
              />
              <PopularCard
                text={'Birthday Parties'}
                image={require('../../../assets/images/home/ballons.jpg')}
                right={sm ? true : false}
                onClick={() => handleCardClick('birthday-parties')}
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:gap-5 w-full mb-5">
              <PopularCard
                text={'Summer BBQ'}
                image={require('../../../assets/images/home/grill.jpg')}
                onClick={() => handleCardClick('summer-bbq')}
              />
              <PopularCard
                text={'Potlucks'}
                image={require('../../../assets/images/home/food.jpg')}
                right={sm ? true : false}
                onClick={() => handleCardClick('potlucks')}
              />
            </div>
            <div className="flex flex-col sm:flex-row sm:gap-5 w-full">
              <PopularCard
                text={'Baby Showers'}
                image={require('../../../assets/images/home/baby.jpg')}
                onClick={() => handleCardClick('baby-showers')}
              />
              <PopularCard
                text={'Bridal Showers'}
                image={require('../../../assets/images/home/ring.jpg')}
                right={sm ? true : false}
                onClick={() => handleCardClick('bridal-showers')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(PopularEvents)
