/* eslint-disable no-unreachable */
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'
import { Helmet } from 'react-helmet'
import { useToast } from '@chakra-ui/react'
import Button from '../../components/Button/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
import { createEvent } from '../../services/Circle'
import { useNavigate, useParams } from 'react-router-dom'
import { categories } from '../../utils/categories'
import {
  ref,
  database,
  logEvent,
  analytics,
  auth,
  get,
  query,
  storage
} from '../../firebase'
import moment from 'moment'
import DateModal from '../../components/EventDetails/DateModal/DateModal'
import TimeModal from '../../components/EventDetails/TimeModal/TimeModal'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as amplitude from '@amplitude/analytics-browser'
import { handleSelectAndSetAddress } from '../../utils/address'
import NewEventNote from '../../components/NewEvent/NewEventNote/NewEventNote'
import NewEventTitle from '../../components/NewEvent/NewEventTitle/NewEventTitle'
import NewEventHostName from '../../components/NewEvent/NewEventHostName/NewEventHostName'
import NewEventImage from '../../components/NewEvent/NewEventImage/NewEventImage'
import NewEventDateAndTime from '../../components/NewEvent/NewEventDateAndTime/NewEventDateAndTime'
import NewEventLocation from '../../components/NewEvent/NewEventLocation/NewEventLocation'
import NewEventOcassion from '../../components/NewEvent/NewEventOcassion/NewEventOcassion'
import {
  getDownloadURL,
  uploadBytes,
  ref as storageRef
} from 'firebase/storage'
import { update } from 'firebase/database'

export const schema = yup
  .object({
    eventTitle: yup.string().required('Event title is required'),
    ocassion: yup.object().required('Ocassion is required'),
    ocassionOther: yup.string().when('ocassion', (value, schema) => {
      const ocassion = value[0]

      if (ocassion?.value === 'other') {
        return schema.required()
      }

      return schema
    }),
    when: yup.object().shape({
      date: yup.string(),
      time: yup.string(),
      offset: yup.string(),
      amPm: yup.string()
    }),
    where: yup.string(),
    complementaryAddress: yup.string(),
    country: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string(),
    hostName: yup.string(),
    note: yup.string(),
    items: yup.array().of(yup.object()),
    eventImage: yup.array().optional()
  })
  .required()

const NewEvent = ({ type }) => {
  const { circleCode, ocassion: incomingOcassion } = useParams()
  const toast = useToast()
  const [user, loading] = useAuthState(auth)
  const navigate = useNavigate()

  const [eventInfo, setEventInfo] = useState(null)
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')

  const [dateModal, setDateModal] = useState(false)
  const [timeModal, setTimeModal] = useState(false)

  const [loadingPage, setLoadingPage] = useState(false)

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: {}
  })

  const [
    fieldWhere,
    fieldCity,
    fieldCountry,
    fieldState,
    fieldZip,
    fieldWhen,
    eventImage
  ] = form.watch([
    'where',
    'city',
    'country',
    'state',
    'zip',
    'when',
    'eventImage'
  ])

  const handleCancel = () => {
    if (type === 'edit') {
      navigate(`/event/${circleCode}`)
      return
    }
    navigate('/')
  }

  const handleSubmit = async (formValues) => {
    try {
      let oldCode = null
      let url = null

      if (type === 'edit') {
        oldCode = circleCode
      }

      if (eventImage && eventImage.length > 0) {
        const uploadPromises = eventImage.map(async (file) => {
          const storagePath = `events/${oldCode}/image`
          const fileRef = storageRef(storage, storagePath)
          const result = await uploadBytes(fileRef, file)
            .then(() => ({ success: true, path: storagePath }))
            .catch((error) => ({ success: false, path: storagePath, error }))
          if (!result.success) return null
          const url = await getDownloadURL(fileRef)
          return url
        })

        const results = await Promise.all(uploadPromises)
        const successUploads = results.filter((result) => !!result)
        const failedUploads = results.filter((result) => !result)

        if (successUploads.length > 0) {
          url = successUploads[0]
        }

        if (successUploads.length > 0) {
          toast({
            title: 'Upload successful',
            description: `${successUploads.length} files uploaded successfully.`,
            status: 'success',
            duration: 5000,
            isClosable: true
          })
        }

        if (failedUploads.length > 0) {
          toast({
            title: 'Some uploads failed',
            description: `${failedUploads.length} files failed to upload.`,
            status: 'error',
            duration: 5000,
            isClosable: true
          })
        }
      }

      const evData = {
        type: type === 'edit' ? 'edit' : 'new',
        oldCode,
        name: formValues.eventTitle,
        hostName: formValues.hostName,
        date: formValues.when.date || '',
        size: '10',
        time: formValues.when.time || '',
        amOrPm: formValues.when.amPm || '',
        timezone: formValues.when.offset || '',
        noteForGuests: formValues.note || '',
        ocassion: formValues.ocassion,
        ocassionOther: formValues.ocassionOther || '',
        completed: true,
        url: url || '',
        address: {
          label: address1 || '',
          label2: address2 || '',
          country: formValues.country || '',
          state: formValues.state || '',
          city: formValues.city || '',
          zip: formValues.zip || '',
          value: formValues.where || '',
          complementaryAddress: formValues.complementaryAddress || ''
        }
      }

      if (type === 'edit') {
        evData.noteForGuests = formValues.note || ''
      }

      if (type === 'new') {
        evData.allowGuests = true
        evData.firstTime = true
        amplitude.track('Event created', undefined, {
          user_id: user.uid
        })
        logEvent(analytics, 'create_event', {
          source: 'NEW_EVENT'
        })
      }

      const eventCircleCode = await createEvent(evData)

      navigate(`/event/${eventCircleCode}`)
    } catch (err) {
      console.log('Error submiting', err)
      toast({
        status: 'error',
        title: err.message || 'Error while hosting playlist',
        position: 'top'
      })
    }
  }

  const handleAddressSelect = useCallback(
    (option, manualAddress) => {
      handleSelectAndSetAddress(
        option,
        manualAddress,
        fieldCity,
        fieldState,
        fieldCountry,
        fieldZip,
        fieldWhere,
        form,
        setAddress1,
        setAddress2
      )
    },
    [fieldCity, fieldState, fieldZip, fieldCountry, fieldWhere, form]
  )

  const fetchEventData = useCallback(async () => {
    if (!user || type !== 'edit') return

    const eventSnap = await get(
      query(ref(database, `circles/${circleCode}/info`))
    )

    const info = eventSnap?.val()

    if (!!info) {
      setEventInfo(info)
    } else {
      // Event not found
      navigate(`/404?from=${window.location.pathname}`, {
        replace: true
      })
    }
  }, [circleCode, user, type, navigate])

  const handleOldEventData = useCallback(() => {
    if (!eventInfo) return

    form.setValue('eventTitle', eventInfo.name)
    form.setValue('ocassion', eventInfo.ocassion)
    form.setValue('ocassionOther', eventInfo?.ocassionOther)
    form.setValue('hostName', eventInfo.hostName)
    form.setValue(
      'when.date',
      eventInfo.date ? moment(eventInfo.date).format('MMM DD, YYYY') : ''
    )
    form.setValue('when.amPm', eventInfo.amOrPm)
    form.setValue('when.time', eventInfo.time)
    form.setValue('when.offset', eventInfo.timezone)
    form.setValue('note', eventInfo.noteForGuests)

    if (
      eventInfo.address?.value ||
      eventInfo.address?.zip ||
      eventInfo.address?.state ||
      eventInfo.address?.city ||
      eventInfo.address?.country ||
      eventInfo.address?.complementaryAddress
    ) {
      form.setValue('where', eventInfo.address?.value)
      form.setValue('zip', eventInfo.address?.zip)
      form.setValue('state', eventInfo.address?.state)
      form.setValue('city', eventInfo.address?.city)
      form.setValue('country', eventInfo.address?.country)
      form.setValue(
        'complementaryAddress',
        eventInfo.address?.complementaryAddress
      )
    }
  }, [eventInfo, form])

  const onTimeModalConfirm = (data) => {
    form.setValue('when.amPm', data.when?.amPm)
    form.setValue('when.offset', data.when?.offset)
    form.setValue('when.time', data.when?.time)
    setTimeModal(false)
  }

  const onDateModalConfirm = (data) => {
    form.setValue('when.date', data.date)
    setDateModal(false)
  }

  const formattedEventTime = useMemo(() => {
    if (!fieldWhen?.amPm && !fieldWhen?.offset) return

    const timeWithAmPm = `${fieldWhen.time} ${fieldWhen.amPm}`

    const formattedTime = moment(timeWithAmPm, 'hh:mm A').format(
      `hh:mm A [${fieldWhen.offset}]`
    )

    return formattedTime
  }, [fieldWhen?.amPm, fieldWhen?.offset, fieldWhen?.time])

  useEffect(() => {
    handleAddressSelect({}, true)
  }, [handleAddressSelect])

  useEffect(() => {
    if (eventInfo && type === 'edit') {
      handleOldEventData()
    }
  }, [eventInfo, handleOldEventData, type])

  useEffect(() => {
    if ((!user || user.isAnonymous) && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
    }
    // Fist load
    if (!!user && !loading) {
      form.setValue('when.amPm', 'AM')
      fetchEventData().then(() => null)
      if (user.displayName) {
        form.setValue('hostName', user.displayName)
      }
    }
  }, [user, loading, fetchEventData, navigate, form])

  useEffect(() => {
    if (incomingOcassion) {
      const findCategory = categories.find((c) => c.value === incomingOcassion)
      if (findCategory) {
        form.setValue('ocassion', findCategory)
      }
    }
  }, [incomingOcassion])

  useEffect(() => {
    if (type === 'edit') {
      setLoadingPage(true)
      setTimeout(() => {
        setLoadingPage(false)
      }, 350)
    }
  }, [type])

  if (!user || user?.isAnonymous) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - See your events history</title>
          <meta
            name="description"
            content="See events you’ve hosted or attended. Click on the tiles to see the event details."
          />
        </Helmet>
      </>
    )
  }

  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create online invitations with Coteri</title>
        <meta
          name="description"
          content={
            'Create your online invitation with Coteri. Enter your event details, such as when and where. Decide if you want to collaborate on a music playlist and request list.'
          }
        />
      </Helmet>

      {loadingPage ? (
        <div className="w-full h-full flex flex-1 justify-center items-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      ) : (
        <div className="w-full flex gap-5 flex-col sm:flex-row">
          <div className="order-1 sm:w-[40%] w-[100%]">
            <NewEventImage form={form} />
          </div>

          <div className="order-2 flex-1">
            <NewEventTitle form={form} />

            <NewEventHostName form={form} />

            <NewEventDateAndTime
              form={form}
              setDateModal={setDateModal}
              setTimeModal={setTimeModal}
            />

            <NewEventLocation
              form={form}
              address1={address1}
              address2={address2}
              handleAddressSelect={handleAddressSelect}
            />

            <NewEventOcassion form={form} />

            <NewEventNote form={form} />

            <Button
              text={type === 'edit' ? 'Update event' : 'Save event'}
              onClick={form.handleSubmit(handleSubmit)}
              className={'w-full mt-5'}
              size={'medium'}
            />
          </div>
        </div>
      )}

      {/* <div>
        <div className='w-full flex items-center justify-center mt-10'>
          <div className='flex flex-1 bg-off-white h-[2px] mr-2' />
          <span className='text-[13px] text-secondary'>Additional event features</span>
          <div className='flex flex-1 bg-off-white h-[2px] ml-2' />
        </div>

        <div className='flex items-center justify-center gap-5 mt-2'>
          <NewEventAdditionalInformation toggleTab={toggleTab} setToggleTab={setToggleTab} form={form} />
        </div>
      </div> */}

      <DateModal
        isOpen={dateModal}
        handleOnClose={() => setDateModal(false)}
        overrideConfirm={onDateModalConfirm}
        incomingDate={fieldWhen?.date}
      />

      <TimeModal
        time={fieldWhen?.time}
        offset={fieldWhen?.offset}
        amPm={fieldWhen?.amPm}
        isOpen={timeModal}
        handleOnClose={() => setTimeModal(false)}
        overrideConfirm={onTimeModalConfirm}
      />
    </Base>
  )
}

export default memo(NewEvent)
