export const categories = [
  {
    label: 'Cocktail parties',
    value: 'cocktail-parties'
  },
  {
    label: 'Birthday parties',
    value: 'birthday-parties'
  },
  {
    label: 'Summer BBQ',
    value: 'summer-bbq'
  },
  {
    label: 'Potlucks',
    value: 'potlucks'
  },
  {
    label: 'Baby Showers',
    value: 'baby-showers'
  },
  {
    label: 'Bridal Showers',
    value: 'bridal-showers'
  },
  {
    label: 'Other',
    value: 'other'
  }
]
