import React from 'react'
import Modal from '../../Modal/Modal'
import DottedLine from '../../DottedLine/DottedLine'
import Button from '../../Button/Button'

const EventDetailsViewCreatedEvent = ({ isOpen, onClose }) => {
  return <Modal isOpen={isOpen} onClose={onClose} className={"max-w-[450px] w-full mx-5"}>
    <div className='z-50'>
      <div className="bg-white rounded-input relative w-full flex flex-col">
        <div className='px-[20.5px] flex items-start mt-6'>
          <div className='w-[2px] h-4 bg-primary' />

          <div className='flex flex-col ml-2'>
            <h2 className='text-primary text-[20px] leading-4'>You created your event!</h2>
            <span className='mt-2'>Here's what to do next...</span>
          </div>
        </div>

        <div className="absolute inset-0 w-full h-full left-5 flex">
          <DottedLine color={"#80808033"} />
        </div>

        <div className='w-full h-[1px] bg-[#80808033] my-3' />

        <div className='mb-5 px-[20.5px]'>
          <div className='flex flex-row items-start mr-1 text-[15px]'>
            <div className='w-[2px] h-4 bg-primary mt-[3px]' />

            <span className='pl-4'>Consider adding the <span className='font-semibold text-primary'>Additional event features</span> below, prior to sending the invite.</span>
          </div>

          <div className='p-2 bg-off-white rounded-md mx-4 mt-2 leading-4'>
            <p className='text-[14px] font-semibold text-primary'>What to Bring list</p>
            <span className='text-[14px]'>Add items to a list for your guests to pick from.</span>
          </div>

          <div className='p-2 bg-off-white rounded-md mx-4 mt-2 leading-4'>
            <p className='text-[14px] font-semibold text-primary'>Music playlist</p>
            <span className='text-[14px]'>Curate the perfect party playlist using our guided process.</span>
          </div>

          <div className='flex flex-row items-start mr-1 mt-2 text-[15px]'>
            <div className='w-[2px] h-4 bg-primary mt-[3px]' />

            <span className='pl-4'>When your're ready to send the invites, click <span className='font-semibold text-primary'>Send invites</span> and follow the steps.</span>
          </div>
        </div>

        <div className='flex items-center justify-end mr-8 mb-5 z-50'>
          <Button type={"primary"} text={"Got it!"} size={'medium'} onClick={onClose} />
        </div>
      </div>
    </div>
  </Modal>
}

export default EventDetailsViewCreatedEvent
