import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ChakraProvider } from '@chakra-ui/react'
import theme from './theme'
import TokensProvider from './context/TokensContext'
import PlayingProvider from './context/PlayingContext'
import { BrowserRouter as Router } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <ChakraProvider theme={theme}>
    {/* <TokensProvider> */}
    <PlayingProvider>
      <div className="App">
        <Router>
          <App />
        </Router>
      </div>
    </PlayingProvider>
    {/* </TokensProvider> */}
  </ChakraProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
