import React, { memo, useEffect } from 'react'
import Modal from '../../Modal/Modal'
import { useWatch } from 'react-hook-form'
import { database } from '../../../firebase'
import { ref, update, remove } from 'firebase/database'
import Input from '../../Input/Input'
import { useParams } from 'react-router-dom'
import Calendar from '../../Calendar/Calendar'
import { useToast } from '@chakra-ui/react'
import DateTime from '../../DateTime/DateTime'
import Button from '../../Button/Button'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

export const schema = yup
  .object({
    date: yup.string().required()
  })
  .required()

const DateModal = ({ isOpen, handleOnClose, overrideConfirm, incomingDate }) => {
  const { circleCode } = useParams()
  const toast = useToast()

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const date = form.watch('date')

  const handleUpdateEventDate = async (newEventDate) => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), {
        date: newEventDate.date
      })

      handleOnClose()

      form.reset()

      toast({
        status: 'success',
        title: 'Date updated',
        position: 'top'
      })
    } catch (err) {
      console.log('DateModal: ', err)
      toast({
        status: 'error',
        title: 'Error updating date',
        position: 'top'
      })
    }
  }

  const handleSetDate = (date) => {
    form.setValue('date', date)
  }

  const handleReturn = () => {
    form.reset()
    handleOnClose()
  }

  useEffect(() => {
    if (incomingDate) {
      form.setValue('date', incomingDate)
    }
  }, [form, incomingDate])

  return (
    <Modal isOpen={isOpen} onClose={handleReturn} className={"mx-5"}>
      <div className="p-10 bg-white rounded-input md:mx-0 w-full sm:w-[500px]">
        <h2 className="mb-[15px]">Edit your event date</h2>

        <Calendar
          selectedDate={date || undefined}
          setSelectedDate={handleSetDate}
          className={'w-full bg-white'}
        />

        <div className="flex items-center justify-between mt-[25px]">
          <Button type={'text'} text={'Back'} onClick={handleReturn} />
          <Button
            text={'Save'}
            onClick={form.handleSubmit(overrideConfirm || handleUpdateEventDate)}
          />
        </div>
      </div>
    </Modal>
  )
}

export default memo(DateModal)
