import React, { memo, useEffect, useMemo, useState } from 'react'
import Button from '../../Button/Button'
import Input from '../../Input/Input'
import Modal from '../../Modal/Modal'
import Toggle from '../../Toggle/Toggle'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ClipLoader from 'react-spinners/ClipLoader'
import uuid from 'react-uuid'
import { update } from 'firebase/database'
import { ref, database, auth } from '../../../firebase'
import { useParams } from 'react-router-dom'
import { useObject } from 'react-firebase-hooks/database'
import { useAuthState } from 'react-firebase-hooks/auth'

const options = [
  {
    label: 'Claimed',
    value: 'claimed'
  },
  {
    label: 'Unclaimed',
    value: 'unclaimed'
  },
]

export const schema = yup
  .object({
    name: yup.string().required(),
    status: yup.object().required()
  })
  .required()

const RequestListItemModal = ({ isOpen, onClose, onDelete, item, userId, overrideSubmit, owner }) => {
  const { circleCode } = useParams()
  const [user, loading] = useAuthState(auth)
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )

  const form = useForm({
    resolver: yupResolver(schema),
  })

  const guests = useMemo(() => {
    const guestsVal = guestsSnap?.val()

    if (!guestsVal) {
      return []
    }

    const guestArray = Object.values(guestsVal)

    const allGuests = guestArray.filter((guest) => guest.response === 'yes').map((guest) => ({
      label: guest.name,
      value: guest.id
    }))

    allGuests.unshift({
      label: 'You',
      value: user.uid
    })

    return allGuests
  }, [guestsSnap, user.uid])


  const [status] = form.watch(['status'])

  const handleOnClose = () => {
    form.reset()
    onClose()
  }

  const handleSubmit = async (data) => {
    try {
      let newItem = {}

      const claimed = data.status?.value === 'claimed' ? true : false

      const ownerId = claimed ? data?.owner?.value ?? item.claimedBy ?? userId : null

      if (item) {
        newItem = {
          [item.id]: {
            id: item.id,
            name: data.name,
            claimed: claimed,
            claimedBy: ownerId,
          }
        }
      } else {
        const id = uuid().replace(/-/g, '')

        newItem = {
          [id]: {
            id,
            name: data.name,
            claimed: claimed,
            owner: userId,
            claimedBy: claimed ? userId : null
          }
        }
      }

      await update(ref(database, `circles/${circleCode}/info`), {
        extraCollab: true
      })
      await update(ref(database, `circles/${circleCode}/items`), newItem)

      handleOnClose()
    } catch (err) {
      console.log('Error creating request item', err)
    }
  }

  const handleDelete = () => {
    onClose()
    onDelete()
  }

  useEffect(() => {
    if (item && !guestsLoading && !loading) {
      const guest = guests.find((g) => g.value === item?.claimedBy)

      if (guest && item?.claimed) {
        form.setValue('owner', { label: guest?.label, value: guest?.value })
      }

      form.setValue('name', item?.name)
    }

    form.setValue('status', item?.claimed ? options[0] : options[1])

  }, [form, item, isOpen, guestsLoading, guests, loading])

  useEffect(() => {
    if (status?.value === 'claimed' && !item.claimedBy) {
      form.setValue('owner', { label: 'You', value: user.uid })
    }
  }, [form, item, status, status?.value, user.uid])


  return (
    <Modal isOpen={isOpen} onClose={handleOnClose}>
      <div className="p-7 bg-white rounded-input mx-3 md:mx-0 w-[350px] sm:w-[400px]">
        {onDelete && (
          <div
            className="flex items-center justify-end cursor-pointer"
            onClick={handleDelete}
          >
            <span className="text-error font-medium mb-2">Delete</span>
          </div>
        )}
        <span className="mobile:text-[20px] text-[16px] text-primary">
          Edit this item
        </span>

        <Input
          register={form.register('name')}
          form={form}
          type="default"
          placeholder="Item name"
          className="mt-[15px] w-full"
          hasError={form.formState.errors.name?.message}
        />

        <Input
          register={form.register('status')}
          type={'select'}
          className={`mt-[20px] ${status?.value === 'claimed' ? 'bg-primary border-primary text-white' : ''}`}
          options={options}
          form={form}
          arrowDownFill={status?.value === 'claimed' ? "white" : 'black'}
          optionsClassName={`text-black`}
          centerText
        />

        {owner && status?.value === 'claimed' &&
          <Input
            register={form.register('owner')}
            type={'select'}
            className={`mt-[20px]`}
            placeholder="Select a guest"
            options={guests}
            form={form}
          />
        }

        <div
          className={`flex flex-1 items-center ${form.formState.isSubmitting ? 'justify-center' : 'justify-between'} mt-[20px]`}
        >
          {form.formState.isSubmitting ? (
            <ClipLoader color="#5B4ABC" />
          ) : (
            <>
              <Button
                type="text"
                size="medium"
                text={'Cancel'}
                onClick={handleOnClose}
              />
              <Button
                text={item ? 'Update' : 'Add'}
                size="medium"
                onClick={form.handleSubmit(overrideSubmit || handleSubmit)}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default memo(RequestListItemModal)
