import React, { memo, useEffect, useRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { useWatch } from 'react-hook-form'

const AmPm = ({ form, className }) => {
  const amPm = useWatch({
    name: 'when.amPm',
    control: form.control
  })

  const handleSetAmPm = (value) => {
    form.setValue('when.amPm', value)
  }

  useEffect(() => {
    if (!amPm) {
      form.setValue('when.amPm', 'AM')
    }
  }, [])

  return (
    <div
      className={twMerge(
        'relative h-10 flex p-1 bg-gray items-center justify-around gap-2 rounded-input flex-1',
        className
      )}
    >
      <span
        className={`rounded-md h-full w-full flex flex-1 items-center justify-center cursor-pointer border-1 ${amPm === 'AM' ? 'border-primary bg-primary text-white' : 'border-gray bg-gray text-black'}`}
        onClick={() => handleSetAmPm('AM')}
      >
        AM
      </span>
      <span
        className={`rounded-md h-full w-full flex flex-1 items-center justify-center cursor-pointer border-1 ${amPm === 'PM' ? 'border-primary bg-primary text-white' : 'border-gray bg-gray text-black'}`}
        onClick={() => handleSetAmPm('PM')}
      >
        PM
      </span>
    </div>
  )
}

export default memo(AmPm)
