import React, { useState } from 'react'
import { LocationIcon } from '../../../assets/icons'
import { useWatch } from 'react-hook-form'
import { Tooltip } from '@chakra-ui/react'
import LocationModal from '../../EventDetails/LocationModal/LocationModal'

const NewEventLocation = ({ form, address1, address2, handleAddressSelect }) => {
  const [locationModal, setLocationModal] = useState(false)

  const [fieldComplementary, fieldCity, fieldState, fieldCountry, fieldWhere, fieldZip] = useWatch({
    name: ['complementaryAddress', 'city', 'state', 'country', 'where', 'zip'],
    control: form.control
  })

  const handleConfirm = (formValues) => {
    form.setValue('zip', formValues?.zip)
    form.setValue('where', formValues?.where)
    form.setValue('city', formValues?.city)
    form.setValue('state', formValues?.state)
    form.setValue('country', formValues?.country)
    form.setValue('complementaryAddress', formValues?.complementaryAddress)
    setLocationModal(false)
  }

  return (
    <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md cursor-pointer border-1' onClick={() => setLocationModal(true)}>
      <Tooltip label='Select another location' onClick={() => setLocationModal(true)}>
        <div className="flex flex-row items-center justify-start" >
          <LocationIcon fill="#5b4abc" pinColor="#422d8f" className="mr-[8px]" />
          {address1 ? (
            <div className='flex flex-col'>
              <span>{address1}{fieldComplementary ? `, ${fieldComplementary}` : ''}</span>
              <span>{address2}</span>
            </div>
          ) : <span
            className="text-primary cursor-pointer font-bold hover:underline"
          >
            Add event location
          </span>}
        </div>
      </Tooltip>

      <LocationModal
        isOpen={locationModal}
        handleOnClose={() => setLocationModal(false)}
        overrideConfirm={handleConfirm}
        incommingAddress={{ zip: fieldZip, where: fieldWhere, city: fieldCity, state: fieldState, country: fieldCountry, complementaryAddress: fieldComplementary, address1: address1 || undefined }}
      />
    </div>
  )
}

export default NewEventLocation