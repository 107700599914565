import React, { memo, useEffect, useState } from 'react'
import Button from '../components/Button/Button'
import ClipLoader from 'react-spinners/ClipLoader'
import { useMediaQuery } from '../hook/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { analytics, logEvent, auth, setUserId } from '../firebase'
import {
  Text,
  Container,
  Flex,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useToast,
  Modal,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  ModalOverlay,
  Textarea,
  ModalContent
} from '@chakra-ui/react'
import Header from '../components/Home/Header/Header'
import { sendIssue } from '../services/Support'
import { useAuthState } from 'react-firebase-hooks/auth'
import Footer from '../components/Home/Footer/Footer'
import * as amplitude from '@amplitude/analytics-browser'

const FAQ = () => {
  const [burguer, setBurguer] = useState(false)
  const [isInitialMount, setIsInitialMount] = useState(true)
  const [user, loading] = useAuthState(auth)
  const [issue, setIssue] = useState('')
  const [isReporting, setReporting] = useState(false)
  const toast = useToast()
  const {
    isOpen: isReportOpen,
    onOpen: onOpenReport,
    onClose: onCloseReport
  } = useDisclosure()

  const faqs = [
    {
      question: 'Is Coteri free?',
      answer:
        'Yes, Coteri is free to use. You can enjoy all the features and services provided by Coteri without any cost. Feel free to sign up and start using the platform right away!'
    },
    {
      question: `How does Coteri work with my Spotify?`,
      answer: `Coteri guides you through the process of authorizing with your Spotify. Coteri is an approved application with these services and integrates through their standard OAuth procedures. Your guests are not asked to connect an account during the RSVP process. Coteri simply leverages these integrations to display song data to your guests for them to select from.`
    },
    {
      question: `Do I have to connect my Spotify to use Coteri to create the perfect party playlist?`,
      answer: `You do not need to connect an account! When creating a playlist, click “Guest checkout” to continue without connecting an account. The playlist will be created as a publicly available playlist on Spotify.`
    },
    {
      question: `Do you offer RSVP tracking?`,
      answer: `Yes! You can view RSVPs by clicking on the “View guests” button on your event page.`
    },
    {
      question: `What if I need to update the invitation after I’ve sent it?`,
      answer: `You can edit all details for your event by clicking on the “Edit event details” button on the event page. We recommend notifying your guests of any changes made after sending invites, though they will get an alert to check the event page if you make any changes.`
    },
    {
      question: `What if I need help?`,
      answer: `Reach out to support@coteri.us if you require help at any time.`
    }
  ]

  const navigate = useNavigate()

  const breakpoints = {
    lg: 1024
  }

  const { lg } = useMediaQuery(breakpoints)

  const handleBurguerClick = () => {
    setIsInitialMount(false)
    setBurguer(!burguer)
  }

  const handleTermAndCondition = () => {
    navigate('/terms')
  }

  const handlePrivacyAndPolicy = () => {
    navigate('/privacy')
  }

  const handleMyEvents = () => {
    logEvent(analytics, 'menu_clicked', {
      source: 'MY_EVENTS'
    })
    navigate('/event-history')
  }

  const handleHome = () => {
    navigate('/')
  }

  const handleContactUs = () => {
    setIssue('')
    onOpenReport()
  }

  const handleReport = async () => {
    try {
      setReporting(true)
      await sendIssue(user?.uid || '1', user?.email || '', issue)
      onCloseReport()
      setIssue('')
      toast({
        title: `We have recieved your issue!`,
        position: 'top',
        status: 'success',
        isClosable: true
      })
      setReporting(false)
    } catch (e) {
      setReporting(false)
      console.log('handleReport e', e)
      toast({
        title: e.message || 'Error while sending request',
        position: 'top',
        status: 'error'
      })
    }
  }

  useEffect(() => {
    if (!loading && !!user) {
      setUserId(analytics, user.uid)

      const identifyEvent = new amplitude.Identify()
      identifyEvent.set('uid', user.uid)
      amplitude.identify(identifyEvent)
      amplitude.setUserId(user.uid)
    }
  }, [user, loading])

  return (
    <div>
      <Header isFaq />

      <Flex pb="100px" pt={'50px'}>
        <Container
          display="flex"
          flexDirection="column"
          alignItems="center"
          w="100%"
          maxW="1200px"
          px={{ base: '32px', md: '72px' }}
        >
          <Text
            mb="40px"
            color="#422d8f"
            lineHeight={{ base: '32px', md: '42px' }}
            fontSize={{ base: '28px', md: '38px' }}
            fontWeight="700"
          >
            FAQs
          </Text>
          <Flex flex="1" w="100%">
            <Accordion w="100%" allowToggle>
              {faqs.map(({ question, answer }) => (
                <AccordionItem
                  key={question}
                  w="100%"
                  borderWidth="1px"
                  mb="12px"
                >
                  <AccordionButton w="100%">
                    <Flex w="100%">
                      <Text flex="1" textAlign="start" fontWeight="700">
                        {question}
                      </Text>
                    </Flex>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>{answer}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
        </Container>
      </Flex>

      <div className="w-full flex flex-col items-center justify-center">
        <span className="text-[18px] font-bold mb-4">
          Still have questions?
        </span>
        <Button
          text={'Contact Us'}
          type={'border'}
          className={'text-terciary hover:text-white '}
          onClick={handleContactUs}
        />
      </div>

      <Footer className="mt-[80px]" />

      <Modal isOpen={isReportOpen} onClose={onCloseReport} isCentered={true}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please provide details on the issue</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              name="issue"
              h="130px"
              value={issue}
              onChange={(e) => setIssue(e.target.value)}
            />
          </ModalBody>
          <div
            className={`flex items-center ${isReporting ? 'justify-center' : 'justify-between'} px-[30px] py-4`}
          >
            {isReporting ? (
              <ClipLoader color="#5B4ABC" />
            ) : (
              <>
                <Button
                  type="text"
                  text={'Cancel'}
                  size={'medium'}
                  onClick={onCloseReport}
                />
                <Button
                  text={'Submit'}
                  size={'medium'}
                  onClick={handleReport}
                />
              </>
            )}
          </div>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default memo(FAQ)
