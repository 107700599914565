import React, { memo, useMemo, useState } from 'react'
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
  NoteIcon,
  PeopleOutlineIcon
} from '../assets/icons'
import Button from '../components/Button/Button'
import moment from 'moment'
import { logEvent } from 'firebase/analytics'
import { analytics } from '../firebase'
import GuestsModal from './GuestsModal'

const RSVPEventDetailsView = ({
  rsvpId,
  circleCode,
  eventInfo,
  guestsInfo
}) => {
  const [popUp, setPopUp] = useState(false)

  const timeOfEvent = useMemo(() => {
    if (!eventInfo || !eventInfo.time || !eventInfo.timezone) return

    const timeWithAmPm = `${eventInfo.time} ${eventInfo.amOrPm}`

    const formattedTime = moment(timeWithAmPm, 'hh:mm A').format(
      `hh:mm A [${eventInfo.timezone}]`
    )

    return formattedTime
  }, [eventInfo])

  const handleViewGuests = () => {
    logEvent(analytics, 'show_guests', {
      source: 'Invitation'
    })
    setPopUp(true)
  }

  if (!eventInfo) {
    return null
  }

  return (
    <div className='flex flex-col flex-1'>
      <div>
        <div className="flex flex-row items-center">
          <span className="mr-[35px] font-medium text-[24px]">
            {eventInfo.name}
          </span>
        </div>
      </div>

      <div className="mt-[20px]">
        <span className="mr-[35px] font-medium">
          Hosted by {eventInfo.hostName ? eventInfo.hostName : ''}
        </span>

        <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] gap-3 rounded-md'>
          <div className="flex flex-row items-center justify-start ">
            <CalendarIcon />
            {eventInfo.date ? (
              <span className="ml-[8px]">
                {moment(eventInfo.date).format('MMMM DD, YYYY')}
              </span>
            ) : (
              <span className="ml-[8px]">To Be Announced</span>
            )}
          </div>
          <div className="flex flex-row items-center justify-start ">
            <ClockIcon />
            {eventInfo.time ? (
              <span className="ml-[8px]">{timeOfEvent}</span>
            ) : (
              <span className="ml-[8px]">To Be Announced</span>
            )}
          </div>
        </div>

        <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md'>
          <div className="flex flex-row items-center justify-start">
            <LocationIcon fill="#5b4abc" pinColor="#422d8f" />
            {eventInfo.address?.label ? (
              <div className="flex flex-col">
                <span className="ml-[8px]">{eventInfo.address?.label}</span>
                <span className="ml-[8px]">{eventInfo.address?.label2}</span>
              </div>
            ) : (
              <span className="ml-[8px]">To Be Announced</span>
            )}
          </div>
        </div>

        <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md'>
          <div className="flex flex-row items-center justify-start">
            <PeopleOutlineIcon fill="#5b4abc" />
            <span className="ml-[8px]">
              {!!guestsInfo ? Object.values(guestsInfo).length : 0} RSVPs • {' '}
            </span>
            <span className="text-primary cursor-pointer font-bold hover:underline" onClick={handleViewGuests}>
              {' '}View guests
            </span>
          </div>
        </div>

        {eventInfo.noteForGuests && (
          <div className='flex flex-col justify-center mt-[12px]'>
            <span>Additional event information</span>
            <div className='w-full h-[2px] bg-secondary' />

            <div className='bg-off-white p-3 flex flex-col justify-center rounded-md mt-2'>
              <span>{eventInfo.noteForGuests}</span>
            </div>
          </div>
        )}
      </div>

      <GuestsModal
        rsvpId={rsvpId}
        circleCode={circleCode}
        popUp={popUp}
        setPopUp={setPopUp}
      />
    </div>
  )
}

export default memo(RSVPEventDetailsView)
