import React, { useState } from 'react'
import Ocassion from '../../../assets/icons/ocassion'
import NewEventOcassionModal from './NewEventOcassionModal'
import { useWatch } from 'react-hook-form'
import { Tooltip } from '@chakra-ui/react'

const NewEventOcassion = ({ form }) => {
  const [selectOcassionModal, setSelectOcassionModal] = useState(false)

  const [fieldOcassion, fieldOcassionOther] = useWatch({
    name: ['ocassion', 'ocassionOther'],
    control: form.control
  })


  const onCloseModal = () => {
    setSelectOcassionModal(false)
  }

  return (
    <div className={`${form.formState.errors.ocassion?.message && !fieldOcassion ? 'border-error' : 'bg-gray'} bg-off-white p-3 flex flex-col justify-center mt-[12px] gap-3 rounded-md border-1`}>
      <Tooltip label='Select another ocassion'>
        <div className="flex flex-row items-center justify-start cursor-pointer" onClick={() => setSelectOcassionModal(true)}>
          <Ocassion fill="#5b4abc" className="mr-[8px]" />
          {fieldOcassion ? (
            <div className='flex flex-col'>
              <span>{fieldOcassion.value === 'other' ? fieldOcassionOther : fieldOcassion.label}</span>
            </div>
          ) : <span
            className="text-primary font-bold hover:underline"
          >
            Select an ocassion *
          </span>}
        </div>
      </Tooltip>

      <NewEventOcassionModal form={form} isOpen={selectOcassionModal} onClose={onCloseModal} />
    </div>
  )
}

export default NewEventOcassion
