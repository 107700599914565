import moment from 'moment'
import { weekNames } from './weeks'

export const getNumberOfDaysInMonth = (year, month) => {
  return moment([year, month]).endOf('month').date()
}

export const getSortedDays = (year, month) => {
  const dayIndex = moment([year, month]).startOf('month').day()
  const firstHalf = weekNames.slice(dayIndex)  
  return [...firstHalf, ...weekNames.slice(0, dayIndex)]
}

export const range = (start, end) => {
  const length = Math.abs((end - start) / 1)

  const { result } = Array.from({ length }).reduce(
    ({ result, current }) => ({
      result: [...result, current],
      current: current + 1
    }),
    { result: [], current: start }
  )

  return result
}


export const daysWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]