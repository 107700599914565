import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'
import * as yup from 'yup'
import { useToast } from '@chakra-ui/react'
import Button from '../../components/Button/Button'
import { useObject } from 'react-firebase-hooks/database'
import { ref, database, logEvent, analytics } from '../../firebase'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate, useParams } from 'react-router-dom'
import { update, set } from 'firebase/database'
import Input from '../../components/Input/Input'
import ChangeResponseModal from '../../components/EventDetails/ChangeResponseModal/ChangeResponseModal'
import RSVPEventDetailsView from '../../components/RSVPEventDetailsView'
import EventCarouselView from '../../components/EventCarouselView'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import PhoneVerificationModal from '../../components/NewGuest/PhoneVerificationModal/PhoneVerificationModal'
import * as amplitude from '@amplitude/analytics-browser'

export const schema = yup
  .object({
    note: yup.string(),
    attending: yup.object().optional()
  })
  .required()

const RSVP = () => {
  const { circleCode, rsvpId } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const [showPopUp, setShowPopUp] = useState(null)
  const [changeResponseModal, setChangeResponseModal] = useState(false)
  const [updatingRSVP, setUpdatingRSVP] = useState(false)
  const [phoneToVerify, setPhoneToVerify] = useState(null)

  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId}`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )
  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )

  const eventInfo = eventSnap?.val()
  const guestsInfo = guestsSnap?.val()
  const rsvpInfo = rsvpSnap?.val()
  const oldAnswer = rsvpInfo?.response
  const hasRequestList = !!eventInfo?.extraCollab ?? false
  const hasMusic = !!eventInfo?.musicCollab ?? false

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [attending] = form.watch(['attending'])

  const handleCloseChangeResponseModal = useCallback(() => {
    setChangeResponseModal(false)
  }, [])

  const attendingOptions = useMemo(() => {
    return [
      {
        label: "I'm Going 🎉",
        value: 'yes'
      },
      {
        label: 'Unable to attend 🙁',
        value: 'no'
      }
    ]
  }, [])

  const handleUpdateRSVP = async () => {
    setUpdatingRSVP(true)

    const value = attending.value

    const response = value === 'yes' ? 'YES' : 'NO'

    logEvent(analytics, 'invite_response', {
      source: response
    })

    amplitude.track(
      'Invite responded to',
      {
        circleCode,
        response
      },
      {
        user_id: rsvpId
      }
    )

    try {
      await update(ref(database, `circles/${circleCode}/guests/${rsvpId}`), {
        response: value
      })
      // set(
      //   ref(database, `joinedCirclesRef/${rsvpId}/${circleCode}`),
      //   value === 'yes' ? moment().toISOString() : null
      // )
      // sendEmail('RSVP_CONFIRM', {
      //   rsvpId,
      //   code: circleCode,
      //   response: response,
      //   email: rsvpInfo.email,
      //   hostEmail: eventInfo.hostEmail,
      //   name: eventInfo.name,
      //   hostName: eventInfo.hostName,
      //   date: eventInfo.date,
      //   time: eventInfo.time,
      //   timezone: eventInfo.timezone,
      //   address: eventInfo.address
      // })
      if (value === 'no') {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      } else {
        if (!!hasRequestList || !!hasMusic) {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help`)
          return
        }
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      }
    } catch (e) {
      console.log('handleUpdateRSVP', e)
      toast({
        status: 'error',
        title: e.message || 'Error while sending response',
        position: 'top'
      })
    } finally {
      setUpdatingRSVP(false)
    }
  }

  const toggleChangeResponseModal = useCallback(() => {
    const formattedAnswer = {
      value: oldAnswer === 'yes' ? 'yes' : 'no',
      label: oldAnswer === 'yes' ? 'Yes' : 'No'
    }
    form.setValue('attending', formattedAnswer)
    setChangeResponseModal(true)
  }, [form, oldAnswer])

  useEffect(() => {
    if (!!eventInfo && eventInfo.noteForGuests) {
      form.setValue('note', eventInfo.noteForGuests)
    }
  }, [eventInfo, form])

  useEffect(() => {
    if (!eventLoading && !rsvpLoading && !guestsLoading) {
      if (
        !eventInfo ||
        !rsvpInfo ||
        !rsvpInfo.phone ||
        (!eventInfo.completed && !eventInfo.withCanva)
      ) {
        console.log('404', eventInfo, rsvpInfo, eventInfo?.completed)
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      if (!rsvpInfo.verified) {
        setPhoneToVerify(rsvpInfo.phone)
        return
      }
      setPhoneToVerify(null)
    }
  }, [
    eventLoading,
    rsvpLoading,
    guestsLoading,
    eventInfo,
    rsvpInfo,
    guestsInfo,
    circleCode,
    rsvpId,
    navigate,
    toast
  ])

  if (eventLoading || rsvpLoading || guestsLoading) {
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        showPopUp={showPopUp}
        onChangePopUp={setShowPopUp}
        onSuccess={() => setShowPopUp(null)}
        onCloseLogin={() => setShowPopUp(null)}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - Reply to online invitation</title>
          <meta name="description" content="Reply to the online invitation." />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  return (
    <Base
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
      verifiedOnly={true}
      showPopUp={showPopUp}
      onChangePopUp={setShowPopUp}
      onSuccess={() => setShowPopUp(null)}
      onCloseLogin={() => setShowPopUp(null)}
      blackLogo
      headerBackgroundColor={'transparent'}
      menuColor={'#000'}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Coteri${!!eventInfo?.name ? ` - Reply to ${eventInfo.name}` : ''} invitation`}</title>
        <meta name="description" content="Reply to the online invitation." />
      </Helmet>
      {eventLoading ? (
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      ) : (
        <div
          className={`flex flex-1 mt-[18px] items-center h-full flex-col relative`}
        >
          <div className="w-full flex mt-[18px] items-center h-full flex-col mb-[50px] relative">
            <div className="w-full flex gap-5 flex-col sm:flex-row">
              <div className="order-2 flex-1">
                <RSVPEventDetailsView
                  rsvpId={rsvpId}
                  circleCode={circleCode}
                  eventInfo={eventInfo}
                  guestsInfo={guestsInfo}
                />
              </div>
              <div className="order-1 sm:w-[42%] w-[100%]">
                <EventCarouselView
                  isGuest
                  rsvpId={rsvpId}
                  circleCode={circleCode}
                  eventInfo={eventInfo}
                  oldAnswer={rsvpInfo?.response ?? 'no'}
                  removeAttendingCard
                />
              </div>
            </div>
          </div>

          <div className="w-full mt-[10px]">
            <div className="flex flex-col mt-4">
              <span className="text-[18px] font-bold mb-[8px]">
                Respond to this event
              </span>
              <Input
                register={form.register('attending')}
                type={'select'}
                className={'mr-[20px]'}
                placeholder="Select a response"
                options={attendingOptions}
                form={form}
              />
            </div>

            <div className="flex flex-1 items-center justify-center mt-[25px] mb-20 sm:mb-0">
              {updatingRSVP ? (
                <ClipLoader color="#5B4ABC" />
              ) : (
                <Button
                  type="primary"
                  text={'Continue'}
                  onClick={handleUpdateRSVP}
                  disabled={!attending}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <ChangeResponseModal
        isOpen={changeResponseModal}
        handleOnClose={handleCloseChangeResponseModal}
        form={form}
        attendingOptions={attendingOptions}
        handleUpdateRSVP={handleUpdateRSVP}
        loading={updatingRSVP}
      />
      {!!rsvpId && !!phoneToVerify ? (
        <PhoneVerificationModal
          circleCode={circleCode}
          rsvpId={rsvpId}
          phone={phoneToVerify}
          onClose={() => setPhoneToVerify(false)}
        />
      ) : null}
    </Base>
  )
}

export default memo(RSVP)
