import React from 'react'

const DottedLine = ({ color }) => {
  const dottedStyle = {
    backgroundImage: 'radial-gradient(' + color + ' 1px, transparent 1px)',
    backgroundSize: '3px 10px'
  }

  return <div className="w-[1.5px] h-full" style={dottedStyle} />
}

export default DottedLine
