import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import {
  getNumberOfDaysInMonth,
  getSortedDays,
  monthNames,
  range
} from '../../constants'
import { twMerge } from 'tailwind-merge'
import { useCallback } from 'react'
import { daysWeek, getMonthDaysStartingOnMonday } from '../../constants/days'

const Calendar = ({ selectedDate, setSelectedDate, className }) => {
  const [days, setDays] = useState([])

  const [currentMonth, setCurrentMonth] = useState(
    selectedDate ? moment(selectedDate).toDate()?.getMonth() : moment().month()
  )
  const [currentYear, setCurrentYear] = useState(
    selectedDate ? moment(selectedDate).toDate()?.getFullYear() : moment().year()
  )

  const nextMonth = () => {
    if (currentMonth < 11) {
      setCurrentMonth((prev) => prev + 1)
    } else {
      setCurrentMonth(0)
      setCurrentYear((prev) => prev + 1)
    }
  }

  const prevMonth = () => {
    if (currentMonth > 0) {
      setCurrentMonth((prev) => prev - 1)
    } else {
      setCurrentMonth(11)
      setCurrentYear((prev) => prev - 1)
    }
  }

  const handleSelection = (event) => {
    const target = event.target

    if (target.id === 'day') {
      setSelectedDate(
        new Date(currentYear, currentMonth, target.getAttribute('data-day'))
      )
    }
  }



  const calculateDays = useCallback(() => {
    setDays([])

    const firstDayOfMonth = (new Date(currentYear, currentMonth).getDay() + 6) % 7;
    const lastDateOfMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const lastDayOfMonth = (new Date(currentYear, currentMonth, lastDateOfMonth).getDay() + 6) % 7;
    const lastDateOfLastMonth = new Date(currentYear, currentMonth, 0).getDate();

    for (let i = firstDayOfMonth; i > 0; i--) {
      setDays(p => [...p, { day: lastDateOfLastMonth - i + 1, inactive: true }])
    }

    for (let i = 1; i <= lastDateOfMonth; i++) {
      setDays(p => [...p, { day: i }])
    }

    for (let i = lastDayOfMonth; i < 6; i++) {
      setDays(p => [...p, { day: i - lastDayOfMonth + 1, inactive: true }])
    }


  }, [currentMonth, currentYear])

  useEffect(() => {
    calculateDays()
  }, [calculateDays])

  return (
    <div
      className={twMerge(
        'border-[1px] rounded-[7px] w-[300px] bg-background mb-[50px]',
        className
      )}
    >
      <div className="flex  items-center justify-center mx-2 my-2">
        <p className="flex-1 text-start text-xl font-bold">
          {monthNames[currentMonth]} {currentYear}
        </p>
        <ChevronLeftIcon
          onClick={prevMonth}
          className="cursor-pointer mr-5 hover:text-zinc-400"
          width={7}
          height={7}
        />
        <ChevronRightIcon
          onClick={nextMonth}
          className="cursor-pointer hover:text-zinc-400"
          width={7}
          height={7}
        />
      </div>

      <div className="mx-[25px] mb-[10px]">
        <div className="grid grid-cols-7 gap-10 justify-items-center mb-[20px]">
          {daysWeek.map((w, index) => <p key={index}>{w}</p>)}
        </div>

        <div
          className="grid grid-cols-7 gap-x-10 gap-y-5 justify-items-center"
        >
          {days.map((obj, index) => {
            const isSelected =
              moment(selectedDate).toDate()?.getTime() ===
              new Date(currentYear, currentMonth, obj.day).getTime()

            return (
              <div
                className={`group relative flex align-center justify-center ${obj.inactive ? 'cursor-default' : 'cursor-pointer'} w-[20px] h-[20px]`}
                key={index}
                onClick={obj.inactive ? () => { } : handleSelection}
              >
                <div
                  className={
                    obj.inactive ? 'absolute h-[30px] w-[30px] rounded-full' : isSelected
                      ? 'absolute bg-light-blue h-[30px] w-[30px] rounded-full'
                      : 'absolute h-[30px] w-[30px] rounded-full group-hover:bg-gray'
                  }
                  style={{
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                />
                <p
                  id="day"
                  data-day={obj.day}
                  className={
                    obj.inactive ? 'z-10 mt-[-1px] text-gray' :
                      isSelected
                        ? 'z-10 text-white mt-[-1px] pointer-events-none text-red'
                        : 'z-10 mt-[-1px]'
                  }
                >
                  {obj.day}
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default memo(Calendar)
