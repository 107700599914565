import React, { useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import Base from './base'
import '../styles/privacy.css'
import { Helmet } from 'react-helmet'

const Privacy = () => {
  const [showPopUp, setShowPopUp] = useState(null)

  return (
    <Base
      isPublic
      showPopUp={showPopUp}
      onChangePopUp={setShowPopUp}
      onSuccess={() => setShowPopUp(null)}
      onCloseLogin={() => setShowPopUp(null)}
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - Privacy Policy</title>
        <meta name="description" content="See the Coteri Privacy Policy." />
      </Helmet>
      <Flex flex="1" direction="column" mb="280px">
        <Text fontSize="20px" fontWeight="600" mb="30px">
          Privacy Policy
        </Text>
        <div className="privacy">
          <p className="c1">
            <span className="c2">Last updated April 12, 2023</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c3">
              This privacy notice for Coteri Services LLC (doing business as
              Coteri) ( "
            </span>
            <span className="c11">Coteri</span>
            <span className="c3">," "</span>
            <span className="c11">we</span>
            <span className="c3">," "</span>
            <span className="c11">us</span>
            <span className="c3">," or "</span>
            <span className="c11">our</span>
            <span className="c3">
              " ), describes how and why we might collect, store, use, and/or
              share ( "
            </span>
            <span className="c11">process</span>
            <span className="c3">
              " ) your information when you use our services ( "
            </span>
            <span className="c11">Services</span>
            <span className="c2">" ), such as when you:</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <ul className="c4 lst-kix_nnh3kjfeha5-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Visit our website at Coteri.us, or any website of ours that
                links to this privacy notice.{' '}
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Engage with us in other related ways, including any sales,
                marketing, or events.
              </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Questions or concerns? Reading this privacy notice will help you
              understand your privacy rights and choices. If you do not agree
              with our policies and practices, please do not use our Services.
              If you still have any questions or concerns, please contact us at
              support@coteri.us.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11 c12">SUMMARY OF KEY POINTS </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              This summary provides key points from our privacy notice.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">
              What personal information do we process?
            </span>
            <span className="c2">
              &nbsp;When you visit, use, or navigate our Services, we may
              process personal information depending on how you interact with
              Coteri and the Services, the choices you make, and the products
              and features you use.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">
              Do we process any sensitive personal information?{' '}
            </span>
            <span className="c2">
              &nbsp;We do not process sensitive personal information.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">
              Do we receive any information from third parties?{' '}
            </span>
            <span className="c2">
              &nbsp;We do not receive any information from third parties.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">How do we process your information?</span>
            <span className="c2">
              &nbsp;We process your information to provide, improve, and
              administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your
              information for other purposes with your consent. We process your
              information only when we have a valid legal reason to do so.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">
              In what situations and with which &nbsp;parties do we share
              personal information?
            </span>
            <span className="c2">
              &nbsp;We may share information in specific situations and with
              specific third parties. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c7"></span>
          </p>
          <p className="c1">
            <span className="c11">How do we keep your information safe?</span>
            <span className="c2">
              &nbsp;We have &nbsp;organizational &nbsp;and technical processes
              and procedures in place to protect your personal information.
              However, no electronic transmission over the internet or
              information storage technology can be guaranteed to be 100%
              secure, so we cannot promise or guarantee that hackers,
              cybercriminals, or other &nbsp;unauthorized &nbsp;third parties
              will not be able to defeat our security and improperly collect,
              access, steal, or modify your information.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">What are your rights? </span>
            <span className="c2">
              Depending on where you are located geographically, the applicable
              privacy law may mean you have certain rights regarding your
              personal information.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">How do you exercise your rights? </span>
            <span className="c2">
              The easiest way to exercise your rights is by contacting us. We
              will consider and act upon any request in accordance with
              applicable data protection laws. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c7"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">WHAT INFORMATION DO WE COLLECT?</span>
          </p>
          <p className="c1 c9">
            <span className="c7"></span>
          </p>
          <p className="c1">
            <span className="c7">Personal information you disclose to us </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: We collect personal information that you provide to us.
              &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">Personal Information Provided by You</span>
            <span className="c2">
              . The personal information that we collect depends on the context
              of your interactions with us and the Services, the choices you
              make, and the products and features you use. The personal
              information we collect may include the following:{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <ul className="c4 lst-kix_mym8wp3wm8b9-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">email addresses </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">passwords &nbsp; &nbsp; </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">Sensitive Information. </span>
            <span className="c2">
              &nbsp;We do not process sensitive information.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">Social Media Login Data.</span>
            <span className="c2">
              &nbsp;We may provide you with the option to register with us using
              your existing social media account details, like your Facebook,
              Twitter, or other social media account. If you choose to register
              in this way, we will collect the information described in the
              section called “HOW DO WE HANDLE YOUR SOCIAL LOGINS?” below.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information. &nbsp; &nbsp;{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c7">Information automatically collected</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected
              automatically when you visit our Services. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes. &nbsp; &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Like many businesses, we also collect information through cookies
              and similar technologies. &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">The information we collect includes: </span>
          </p>
          <ul className="c4 lst-kix_d960fvn1zmr0-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage, and performance information our servers
                automatically collect when you access or use our Services and
                which we record in log files. Depending on how you interact with
                us, this log data may include your IP address, device
                information, browser type, and settings and information about
                your activity in the Services &nbsp;(such as the date/time
                stamps associated with your usage, pages and files viewed,
                searches, and other actions you take such as which features you
                use), device event information (such as system activity, error
                reports (sometimes called &nbsp;"crash dumps"), and hardware
                settings). &nbsp; &nbsp; &nbsp;
              </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">HOW DO WE PROCESS YOUR INFORMATION?</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: We process your information to provide, improve, and
              administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your
              information for other purposes with your consent. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              To facilitate account creation and authentication and otherwise
              manage user accounts. We may process your information so you can
              create and log in to your account, as well as keep your account in
              working order.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              To deliver and facilitate delivery of services to the user. We may
              process your information to provide you with the requested
              service.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              To save or protect an individual's vital interest. We may process
              your information when necessary to save or protect an individual’s
              vital interest, such as to prevent harm. &nbsp;{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c12 c3"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION? &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: We only process your personal information when we
              believe it is necessary and we have a valid legal reason (i.e. ,
              &nbsp;legal basis) to do so under applicable law, like with your
              consent, to comply with laws, to provide you with services to
              enter into or &nbsp;fulfill &nbsp;our contractual obligations, to
              protect your rights, or to &nbsp;fulfill &nbsp;our legitimate
              business interests.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c3 c17">
              If you are located in the EU or UK, this section applies to you.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information:
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">Consent</span>
            <span className="c2">
              . We may process your information if you have given us permission
              (i.e. , &nbsp;consent) to use your personal information for a
              specific purpose. You can withdraw your consent at any time.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">Performance of a Contract</span>
            <span className="c2">
              . We may process your personal information when we believe it is
              necessary to &nbsp;fulfill &nbsp;our contractual obligations to
              you, including providing our Services or at your request prior to
              entering into a contract with you.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">Legal Obligations</span>
            <span className="c2">
              . We may process your information where we believe it is necessary
              for compliance with our legal obligations, such as to cooperate
              with a law enforcement body or regulatory agency, exercise or
              defend our legal rights, or disclose your information as evidence
              in litigation in which we are involved.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11">Vital Interests</span>
            <span className="c2">
              . We may process your information where we believe it is necessary
              to protect your vital interests or the vital interests of a third
              party, such as situations involving potential threats to the
              safety of any person.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">
              If you are located in Canada, this section applies to you.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We may process your information if you have given us specific
              permission (i.e. , &nbsp;express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e. , &nbsp;implied consent). You can
              withdraw your consent at any time.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In some exceptional cases, we may be legally permitted under
              applicable law to process your information without your consent,
              including, for example:
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <ul className="c4 lst-kix_joixe9qczi0p-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                If collection is clearly in the interests of an individual and
                consent cannot be obtained in a timely way
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                For investigations and fraud detection and prevention
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                For business transactions provided certain conditions are met
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                If it is contained in a witness statement and the collection is
                necessary to assess, process, or settle an insurance claim
                &nbsp; "" &nbsp;li &nbsp;For identifying injured, ill, or
                deceased persons and communicating with next of kin
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                If we have reasonable grounds to believe an individual has been,
                is, or may be victim of financial abuse
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                If it is reasonable to expect collection and use with consent
                would compromise the availability or the accuracy of the
                information and the collection is reasonable for purposes
                related to investigating a breach of an agreement or a
                contravention of the laws of Canada or a province
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                If disclosure is required to comply with a subpoena, warrant,
                court order, or rules of the court relating to the production of
                records
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                If it was produced by an individual in the course of their
                employment, business, or profession and the collection is
                consistent with the purposes for which the information was
                produced
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                If the collection is solely for journalistic, artistic, or
                literary purposes
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                If the information is publicly available and is specified by the
                regulations{' '}
              </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c12 c11"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION? &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: We may share information in specific situations
              described in this section and/or with the following &nbsp;third
              parties.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We &nbsp;may need to share your personal information in the
              following situations:
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <ul className="c4 lst-kix_1x3u74tdrocq-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Business Partners. We may share your information with our
                business partners to offer you certain products, services, or
                promotions.
              </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES? &nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: We may use cookies and other tracking technologies to
              collect and store your information. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              HOW DO WE HANDLE YOUR SOCIAL LOGINS? &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: If you choose to register or log in to our Services
              using a social media account, we may have access to certain
              information about you. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform. &nbsp; &nbsp; &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c12 c11"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              HOW LONG DO WE KEEP YOUR INFORMATION? &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: We keep your information for as long as necessary to
              &nbsp;fulfill &nbsp;the purposes outlined in this privacy notice
              unless otherwise required by law. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). &nbsp;No purpose in
              this notice will require us keeping your personal information for
              longer than one (1) months past the termination of the user's
              account.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or &nbsp;anonymize
              &nbsp;such information, or, if this is not possible (for example,
              because your personal information has been stored in backup
              archives), then we will securely store your personal information
              and isolate it from any further processing until deletion is
              possible. &nbsp;{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c12 c11"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              HOW DO WE KEEP YOUR INFORMATION SAFE? &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: We aim to protect your personal information through a
              system of &nbsp;organizational &nbsp;and technical security
              measures.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We have implemented appropriate and reasonable technical and
              &nbsp;organizational &nbsp;security measures designed to protect
              the security of any personal information we process. However,
              despite our safeguards and efforts to secure your information, no
              electronic transmission over the Internet or information storage
              technology can be guaranteed to be 100% secure, so we cannot
              promise or guarantee that hackers, cybercriminals, or other
              &nbsp;unauthorized &nbsp;third parties will not be able to defeat
              our security and improperly collect, access, steal, or modify your
              information. Although we will do our best to protect your personal
              information, transmission of personal information to and from our
              Services is at your own risk. You should only access the Services
              within a secure environment. &nbsp;{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              DO WE COLLECT INFORMATION FROM MINORS? &nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: We do not knowingly collect data from or market to
              &nbsp;children under 18 years of age.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the Services, you represent that you are
              at least 18 or that you are the parent or guardian of such a minor
              and consent to such minor dependent’s use of the Services. If we
              learn that personal information from users less than 18 years of
              age has been collected, we will deactivate the account and take
              reasonable measures to promptly delete such data from our records.
              If you become aware of any data we may have collected from
              children under age 18, please contact us at
              &nbsp;Support@coteri.us.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              WHAT ARE YOUR PRIVACY RIGHTS? &nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: &nbsp; em In some regions, such as &nbsp;the European
              Economic Area (EEA), United Kingdom (UK), and Canada , you have
              rights that allow you greater access to and control over your
              personal information. &nbsp;em &nbsp;You may review, change, or
              terminate your account at any time.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In some regions (like &nbsp;the EEA, UK, and Canada ), you have
              certain rights under applicable data protection laws. These may
              include the right (i) to request access and obtain a copy of your
              personal information, (ii) to request rectification or erasure;
              (iii) to restrict the processing of your personal information; and
              (iv) if applicable, to data portability. In certain circumstances,
              you may also have the right to object to the processing of your
              personal information. You can make such a request by contacting us
              by using the contact details provided in the section "HOW CAN YOU
              CONTACT US ABOUT THIS NOTICE?" &nbsp;below. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We will consider and act upon any request in accordance with
              applicable data protection laws. &nbsp; &nbsp; &nbsp;{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c3">
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your{' '}
            </span>
            <span className="c3 c5">
              <a
                className="c13"
                href="https://www.google.com/url?q=https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm&amp;sa=D&amp;source=editors&amp;ust=1681445899645329&amp;usg=AOvVaw15_v14UJNYxwQKsNE77qMN"
              >
                Member State data protection authority
              </a>
            </span>
            <span className="c3">&nbsp;or a </span>
            <span className="c5 c3">
              <a
                className="c13"
                href="https://www.google.com/url?q=https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints&amp;sa=D&amp;source=editors&amp;ust=1681445899645949&amp;usg=AOvVaw38QDW_TcB7hINuDE2VQYKW"
              >
                UK data protection authority.
              </a>
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c3">
              If you are located in Switzerland, you may contact the{' '}
            </span>
            <span className="c5 c3">
              <a
                className="c13"
                href="https://www.google.com/url?q=https://www.edoeb.admin.ch/edoeb/en/home.html&amp;sa=D&amp;source=editors&amp;ust=1681445899646522&amp;usg=AOvVaw0nZbnrfNZL_81azJXt3-XD"
              >
                Federal Data Protection and Information Commissioner
              </a>
            </span>
            <span className="c2">.</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c18 c3">Withdrawing your consent:</span>
            <span className="c2">
              &nbsp;If we are relying on your consent to process your personal
              information, &nbsp;which may be express and/or implied consent
              depending on the applicable law, &nbsp;you have the right to
              withdraw your consent at any time. You can withdraw your consent
              at any time by contacting us by using the contact details provided
              in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below .
              &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, &nbsp;when applicable
              law allows, &nbsp;will it affect the processing of your personal
              information conducted in reliance on lawful processing grounds
              other than consent. &nbsp; &nbsp;{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">Account Information</span>
          </p>
          <p className="c1">
            <span className="c2">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </span>
          </p>
          <ul className="c4 lst-kix_vymryl49aqa6-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Log in to your account settings and update your user account.
                &nbsp;{' '}
              </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c3 c18">Cookies and similar technologies</span>
            <span className="c3">
              : Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services. You may also{' '}
            </span>
            <span className="c5 c3">
              <a
                className="c13"
                href="https://www.google.com/url?q=http://www.aboutads.info/choices/&amp;sa=D&amp;source=editors&amp;ust=1681445899648661&amp;usg=AOvVaw19lHJGnoQVrx5hDm4GzGCW"
              >
                opt out of interest-based advertising by advertisers on our
                Services
              </a>
            </span>
            <span className="c3">.</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              If you have questions or comments about your privacy rights, you
              may email us at support@coteri.us.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11 c20">CONTROLS FOR DO-NOT-TRACK FEATURES </span>
            <span className="c2">&nbsp;</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ( "DNT" ) feature or setting
              you can activate to signal your privacy preference not to have
              data about your online browsing activities monitored and
              collected. At this stage no uniform technology standard for
              &nbsp;recognizing &nbsp;and implementing DNT signals has been
              &nbsp;finalized . As such, we do not currently respond to DNT
              browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online. If a standard
              for online tracking is adopted that we must follow in the future,
              we will inform you about that practice in a revised version of
              this privacy notice. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              California Civil Code Section 1798.83, also known as the
              &nbsp;"Shine The Light" &nbsp;law, permits our users who are
              California residents to request and obtain from us, once a year
              and free of charge, information about categories of personal
              information (if any) we disclosed to third parties for direct
              marketing purposes and the names and addresses of all third
              parties with which we shared personal information in the
              immediately preceding calendar year. If you are a California
              resident and would like to make such a request, please submit your
              request in writing to us using the contact information provided
              below.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g. ,
              &nbsp;backups, etc.).{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">CCPA Privacy Notice</span>
          </p>
          <p className="c1">
            <span className="c2">
              The California Code of Regulations defines a &nbsp;"resident"
              &nbsp;as:
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              All other individuals are defined as &nbsp;"non-residents." &nbsp;{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              If this definition of &nbsp;"resident" &nbsp;applies to you, we
              must adhere to certain rights and obligations regarding your
              personal information. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c7">
              What categories of personal information do we collect? &nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              We have collected the following categories of personal information
              in the past twelve (12) months:{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <a id="t.caaa508374147ef560c0eb7bd9ce87db1d61342f"></a>
          <a id="t.0"></a>
          <table className="c21">
            <tbody>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">Category</span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">Examples</span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">Collected</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0 start" start="1">
                    <li className="c14 c6 li-bullet-0">
                      <span className="c2">Identifiers</span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">
                      Contact details, such as real name, alias, postal address,
                      telephone or mobile contact number, unique personal
                      identifier, online identifier, Internet Protocol address,
                      email address, and account name
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">YES</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="2">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">
                        Personal information categories listed in the California
                        Customer Records statute
                      </span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">
                      Name, contact information, education, employment,
                      employment history, and financial information
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="3">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">
                        Protected classification characteristics under
                        California or federal law/
                      </span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">Gender and date of birth</span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                  <p className="c14 c9">
                    <span className="c2"></span>
                  </p>
                  <p className="c14 c9">
                    <span className="c2"></span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="4">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">Commercial information</span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">
                      Transaction information, purchase history, financial
                      details, and payment information
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="5">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">Biometric information</span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">Fingerprints and voiceprints/</span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="6">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">
                        Internet or other similar network activity
                      </span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">
                      Browsing history, search history, online &nbsp;behavior ,
                      interest data, and interactions with our and other
                      websites, applications, systems, and advertisements
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="7">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">Geolocation data</span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">Device location</span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="8">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">
                        Audio, electronic, visual, thermal, olfactory, or
                        similar information
                      </span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">
                      Images and audio, video or call recordings created in
                      connection with our business activities
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="9">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">
                        Professional or employment-related information
                      </span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">
                      Business contact details in order to provide you our
                      Services at a business level or job title, work history,
                      and professional qualifications if you apply for a job
                      with us
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="10">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">Education Information</span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">
                      Student records and directory information
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="11">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">
                        Inferences drawn from other personal information
                      </span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c1">
                    <span className="c2">
                      Inferences drawn from any of the collected personal
                      information listed above to create a profile or summary
                      about, for example, an individual’s preferences and
                      characteristics
                    </span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
              <tr className="c16">
                <td className="c10" colSpan="1" rowSpan="1">
                  <ol className="c4 lst-kix_i67oi52whxtu-0" start="12">
                    <li className="c1 c6 li-bullet-0">
                      <span className="c2">Sensitive Personal Information</span>
                    </li>
                  </ol>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14 c9">
                    <span className="c2"></span>
                  </p>
                </td>
                <td className="c10" colSpan="1" rowSpan="1">
                  <p className="c14">
                    <span className="c2">NO</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We will use and retain the collected personal information as
              needed to provide the Services or for:{' '}
            </span>
          </p>
          <ul className="c4 lst-kix_5cp4ivmq2pus-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Category A - &nbsp;As long as the user has an account with us
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp;{' '}
              </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              We may also collect other personal information outside of these
              categories through instances where you interact with us in person,
              online, or by phone or mail in the context of:
            </span>
          </p>
          <ul className="c4 lst-kix_33tz5umxnvhf-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Receiving help through our customer support channels
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Participation in customer surveys or contests; and
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Facilitation in the delivery of our Services and to respond to
                your inquiries.
              </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c7">
              How do we use and share your personal information?
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              More information about our data collection and sharing practices
              can be found in this privacy notice .
            </span>
          </p>
          <p className="c1">
            <span className="c2">&nbsp;</span>
          </p>
          <p className="c1">
            <span className="c3">You may contact us by email at </span>
            <span className="c5 c3">
              <a className="c13" href="mailto:support@coteri.us">
                support@coteri.us
              </a>
            </span>
            <span className="c2">
              , or by referring to the contact details at the bottom of this
              document.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              If you are using an &nbsp;authorized &nbsp;agent to exercise your
              right to opt out we may deny a request if the &nbsp;authorized
              &nbsp;agent does not submit proof that they have been validly
              &nbsp;authorized &nbsp;to act on your behalf. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c7">
              Will your information be shared with anyone else? &nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf, following the same
              strict privacy protection obligations mandated by the CCPA.
              &nbsp;We may use your personal information for our own business
              purposes, such as for undertaking internal research for
              technological development and demonstration. This is not
              considered to be &nbsp;"selling" &nbsp;of your personal
              information.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Coteri Services LLC has not disclosed, sold, or shared any
              personal information to third parties for a business or commercial
              purpose in the preceding twelve (12) months. Coteri Services LLC
              will not sell or share personal information in the future
              belonging to website visitors, users, and other consumers. &nbsp;
              &nbsp; &nbsp; &nbsp; &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c7">
              Your rights with respect to your personal data &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">
              Right to request deletion of the data — Request to delete &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">
              Right to be informed — Request to know &nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              Depending on the circumstances, you have a right to know:
            </span>
          </p>
          <ul className="c4 lst-kix_llroftrfjxym-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                whether we collect and use your personal information;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                the categories of personal information that we collect;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                the purposes for which the collected personal information is
                used;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                whether we sell or share personal information to third parties;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                the categories of personal information that we sold, shared, or
                disclosed for a business purpose; &nbsp; &nbsp;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                the categories of third parties to whom the personal information
                was sold, shared, or disclosed for a business purpose;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                the business or commercial purpose for collecting, selling, or
                sharing personal information; and
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                the specific pieces of personal information we collected about
                you. &nbsp;
              </span>
            </li>
          </ul>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In accordance with applicable law, we are not obligated to provide
              or delete consumer information that is de-identified in response
              to a consumer request or to re-identify individual data to verify
              a consumer request. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">
              Right to Non-Discrimination for the Exercise of a Consumer’s
              Privacy Rights &nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              We will not discriminate against you if you exercise your privacy
              rights.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">
              Right to Limit Use and Disclosure of Sensitive Personal
              Information
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              We do not process consumer's sensitive personal information.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">Verification process &nbsp;</span>
          </p>
          <p className="c1">
            <span className="c2">
              Upon receiving your request, we will need to verify your identity
              to determine you are the same person about whom we have the
              information in our system. These verification efforts require us
              to ask you to provide information so that we can match it with
              information you have previously provided us. For instance,
              depending on the type of request you submit, we may ask you to
              provide certain information so that we can match the information
              you provide with the information we already have on file, or we
              may contact you through a communication method (e.g. , &nbsp;phone
              or email) that you have previously provided to us. We may also use
              other verification methods as the circumstances dictate. &nbsp;We
              will only use personal information provided in your request to
              verify your identity or authority to make the request. To the
              extent possible, we will avoid requesting additional information
              from you for the purposes of verification. However, if we cannot
              verify your identity from the information already maintained by
              us, we may request that you provide additional information for the
              purposes of verifying your identity and for security or
              fraud-prevention purposes. We will delete such additionally
              provided information as soon as we finish verifying you. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">Other privacy rights</span>
          </p>
          <ul className="c4 lst-kix_ftk2qzsas2ri-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                You may object to the processing of your personal information.
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                You may request correction of your personal data if it is
                incorrect or no longer relevant, or ask to restrict the
                processing of the information.
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                You can designate an &nbsp;authorized &nbsp;agent to make a
                request under the CCPA on your behalf. We may deny a request
                from an &nbsp;authorized &nbsp;agent that does not submit proof
                that they have been validly &nbsp;authorized &nbsp;to act on
                your behalf in accordance with the CCPA.
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                You may request to opt out from future selling or sharing of
                your personal information to third parties. Upon receiving an
                opt-out request, we will act upon the request as soon as
                feasibly possible, but no later than fifteen (15) days from the
                date of the request submission. &nbsp; &nbsp; &nbsp;
              </span>
            </li>
          </ul>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              To exercise these rights, you can contact us by email at
              &nbsp;support@coteri.us, &nbsp; &nbsp; or by referring to the
              contact details at the bottom of this document. If you have a
              complaint about how we handle your data, we would like to hear
              from you.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: Yes, if you are a resident of Virginia, you may be
              granted specific rights regarding access to and use of your
              personal information.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c7">Virginia CDPA Privacy Notice </span>
          </p>
          <p className="c1">
            <span className="c2">
              Under the Virginia Consumer Data Protection Act (CDPA):{' '}
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              "Consumer" &nbsp;means a natural person who is a resident of the
              Commonwealth acting only in an individual or household context. It
              does not include a natural person acting in a commercial or
              employment context.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              "Personal data" &nbsp;means any information that is linked or
              reasonably linkable to an identified or identifiable natural
              person. &nbsp;"Personal data" &nbsp;does not include de-identified
              data or publicly available information.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              "Sale of personal data" &nbsp;means the exchange of personal data
              for monetary consideration.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              "If this definition &nbsp;"consumer" &nbsp;applies to you, we must
              adhere to certain rights and obligations regarding your personal
              data.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              The information we collect, use, and disclose about you will vary
              depending on how you interact with &nbsp;Coteri Services LLC and
              our Services.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">
              Your rights with respect to your personal data{' '}
            </span>
          </p>
          <ul className="c4 lst-kix_g8ch3io404f2-0 start">
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Right to be informed whether or not we are processing your
                personal data
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Right to access your personal data &nbsp;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Right to correct inaccuracies in your personal data &nbsp;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Right to request deletion of your personal data &nbsp;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Right to obtain a copy of the personal data you previously
                shared with us &nbsp;
              </span>
            </li>
            <li className="c1 c6 li-bullet-0">
              <span className="c2">
                Right to opt out of the processing of your personal data if it
                is used for ed advertising, the sale of personal data, or
                profiling in furtherance of decisions that produce legal or
                similarly significant effects ( "profiling" ) &nbsp; &nbsp;
              </span>
            </li>
          </ul>
          <p className="c0">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Coteri Services LLC has not sold any personal data to third
              parties for business or commercial purposes. &nbsp;Coteri Services
              LLC will not sell personal data in the future belonging to website
              visitors, users, and other consumers.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c3 c8">
              Exercise your rights provided under the Virginia CDPA &nbsp;
              &nbsp;
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              More information about our data collection and sharing practices
              can be found in this privacy notice .
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c3">You may contact us by email at &nbsp;</span>
            <span className="c5 c3">
              <a className="c13" href="mailto:support@coteri.us">
                support@coteri.us
              </a>
            </span>
            <span className="c2">.</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              If you are using an &nbsp;authorized &nbsp;agent to exercise your
              rights, we may deny a request if the &nbsp;authorized &nbsp;agent
              does not submit proof that they have been validly &nbsp;authorized
              &nbsp;to act on your behalf. &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">Verification process</span>
          </p>
          <p className="c1">
            <span className="c2">
              We may request that you provide additional information reasonably
              necessary to verify you and your consumer's request. If you submit
              the request through an &nbsp;authorized &nbsp;agent, we may need
              to collect additional information to verify your identity before
              processing your request. &nbsp;""Upon receiving your request, we
              will respond without undue delay, but in all cases, within
              forty-five (45) days of receipt. The response period may be
              extended once by forty-five (45) additional days when reasonably
              necessary. We will inform you of any such extension within the
              initial 45-day response period, together with the reason for the
              extension.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c8 c3">Right to appeal</span>
          </p>
          <p className="c1">
            <span className="c2">
              If we decline to take action regarding your request, we will
              inform you of our decision and reasoning behind it. If you wish to
              appeal our decision, please email us at &nbsp; support@coteri.us
              &gt;. Within sixty (60) days of receipt of an appeal, we will
              inform you in writing of any action taken or not taken in response
              to the appeal, including a written explanation of the reasons for
              the decisions. If your appeal if denied, you may contact the a
              https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
              &gt; &nbsp;&gt;Attorney General to submit a complaint . &nbsp;{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              DO WE MAKE UPDATES TO THIS NOTICE? &nbsp;
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              In Short: Yes, we will update this notice as necessary to stay
              compliant with relevant laws. &nbsp;We may update this privacy
              notice from time to time. The updated version will be indicated by
              an updated &nbsp;"Revised" &nbsp;date and the updated version will
              be effective as soon as it is accessible. If we make material
              changes to this privacy notice, we may notify you either by
              prominently posting a notice of such changes or by directly
              sending you a notification. We encourage you to review this
              privacy notice frequently to be informed of how we are protecting
              your information. &nbsp;id="contact" &nbsp;" ; &nbsp;id="control"
              &nbsp;&gt; 1"&gt;15.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c11 c20">
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?{' '}
            </span>
            <span className="c2">
              &nbsp;If you have questions or comments about this notice, you may
              email us at &nbsp;Support@coteri.us.
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c12 c11">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU? &nbsp;{' '}
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              You have the right to request access to the personal information
              we collect from you, change that information, or delete it.
              &nbsp;To request to review, update, or delete your personal
              information, please email{' '}
            </span>
            <span className="c5 c3">
              <a className="c13" href="mailto:support@coteri.us">
                support@coteri.us
              </a>
            </span>
            <span className="c2">.</span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c3">
              This privacy policy was created using Termly’s{' '}
            </span>
            <span className="c5 c3">
              <a
                className="c13"
                href="https://www.google.com/url?q=https://termly.io/products/privacy-policy-generator/&amp;sa=D&amp;source=editors&amp;ust=1681445899671590&amp;usg=AOvVaw2_WF9ADN5cR1Z3Ktr-1qmu"
              >
                Privacy Policy Generator
              </a>
            </span>
            <span className="c2">. </span>
          </p>
          <p className="c1">
            <span className="c2">&nbsp; &nbsp; &nbsp;</span>
          </p>
        </div>
      </Flex>
    </Base>
  )
}

export default Privacy
