import React, { memo, useEffect } from 'react'
import Modal from '../../Modal/Modal'
import { useWatch } from 'react-hook-form'
import { database } from '../../../firebase'
import { ref, update } from 'firebase/database'
import Input from '../../Input/Input'
import { useParams } from 'react-router-dom'
import { useToast } from '@chakra-ui/react'
import Button from '../../Button/Button'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import ClipLoader from 'react-spinners/ClipLoader'

export const schema = yup
  .object({
    note: yup.string()
  })
  .required()

const NoteModal = ({ isOpen, handleOnClose, eventInfo }) => {
  const { circleCode } = useParams()
  const toast = useToast()

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const handleFormSubmit = (form) => {
    try {
      update(ref(database, `circles/${circleCode}/info`), {
        noteForGuests: form.note
      })

      toast({
        status: 'success',
        title: 'Note for guests added',
        position: 'top'
      })

      handleOnClose()
    } catch (err) {
      console.log('NotesForGuests: ', err)
      toast({
        status: 'error',
        title: 'Error saving note',
        position: 'top'
      })
    }
  }

  useEffect(() => {
    if (eventInfo) {
      form.setValue('note', eventInfo.noteForGuests)
    }
  }, [eventInfo, form])

  return (
    <Modal isOpen={isOpen} onClose={handleOnClose} className={'max-w-[500px] w-full mx-5'}>
      <div className="bg-white rounded-input relative p-10 w-full flex flex-col">
        <div className="flex items-center justify-between mb-[15px]">
          <h2>Edit your guest note</h2>
        </div>

        <Input
          type="multiline"
          placeholder="Note from host:"
          register={form.register('note')}
          form={form}
          className="w-full"
          increaseHeight
        />

        <div className="mt-[15px] flex flex-row items-center justify-between">
          {form.formState.isSubmitting ? (
            <div className="flex flex-1 items-center justify-center">
              <ClipLoader color="#5B4ABC" />
            </div>
          ) : (
            <React.Fragment>
              <Button type="text" text={'Cancel'} onClick={handleOnClose} />
              <Button
                text={'Save'}
                onClick={form.handleSubmit(handleFormSubmit)}
                disabled={!form.formState.isDirty}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default memo(NoteModal)
