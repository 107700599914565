import {
  getAppleMusicRecentTracks,
  getAppleMusicHeavyRotation,
  getAppleMusicLibrary
} from '../AppleMusic'
import {
  getTopItems,
  getSpotifyRecentlyPlayed,
  getSpotifyLikedSongs
} from '../Spotify'
import { addToPlaylist, createSpotifyPlaylist } from '../Spotify/playlist'
import {
  addToAppleMusicPlaylist,
  createAppleMusicPlaylist
} from '../AppleMusic/playlist'
import { ref, set, update } from 'firebase/database'
import { auth, database, get, getProfile } from '../../firebase'
import moment from 'moment'
import { getCost } from '../../helpers'
import { sendEmail } from '../Support'

const verifyCircle = async (code) => {
  const circleSnap = await get(ref(database, `circles/${code}/info`))
  if (!circleSnap.exists()) {
    console.log('Invalid Circle Code', code)
    return null
  }
  return circleSnap.val()
}

// const fillRemainder = async (
//   provider,
//   circleCode,
//   tokens,
//   uid,
//   songsToAdd,
//   newSelectedSongs,
//   newPlaylistObj,
//   prevSelectedObj,
//   minLimit,
//   maxLimit
// ) => {
// const newTracks = []
// const selectedAndPlaylist = { ...newPlaylistObj, ...prevSelectedObj }
// // Filtered Songs that does not exist in the playlist
// console.log('add to current playlist', songsToAdd)
// // Filtered Songs that does not exist in user selected
// console.log('add to user selected', newSelectedSongs)
// const isFirstGroup = minLimit === 3 && maxLimit === 5
// const isSecondGroup = minLimit === 0 && maxLimit === 5
// let numToFill = maxLimit - songsToAdd.length
// if ((isFirstGroup && numToFill === 0) || isSecondGroup) {
//   numToFill = 1
// }
// console.log('numToFill', numToFill)
// if (numToFill <= 0) return newTracks
// const { matchingTracks, myTracks, circleArtistsObj } = await fetchConnections(
//   provider,
//   circleCode,
//   tokens,
//   uid
// )
// // Add matching song
// if (matchingTracks.length > 0) {
//   newTracks.push(
//     ...matchingTracks
//       .filter((x) => !selectedAndPlaylist[x.isrc])
//       .slice(0, numToFill)
//   )
// }
// // Return matching songs
// if (newTracks.length === numToFill) return newTracks
// // Not enough matching songs, then check for matching Artists
// const matchingTracksByArtists = myTracks.filter(
//   (x) => !!circleArtistsObj[x.artistName]
// )
// if (matchingTracksByArtists.length > 0) {
//   newTracks.push(
//     ...matchingTracksByArtists
//       .filter((x) => !selectedAndPlaylist[x.isrc])
//       .slice(0, newTracks.length - numToFill)
//   )
// }
// // Return matching songs
// if (newTracks.length === numToFill) return newTracks
// // Not enough matching artists, then get recommendations
// const recommendations = await fetchRecommendations(
//   myTracks.slice(0, 5),
//   20,
//   provider,
//   tokens
// )
// console.log('recommendations', recommendations)
// const matchingTracksByRecommendations = recommendations.filter(
//   (x) => !!circleArtistsObj[x.isrc]
// )
// // Add matching song
// if (matchingTracksByRecommendations.length > 0) {
//   newTracks.push(
//     ...matchingTracksByRecommendations
//       .filter((x) => !selectedAndPlaylist[x.isrc])
//       .slice(0, newTracks.length - numToFill)
//   )
// }
// // Return matching songs
// if (newTracks.length === numToFill) return newTracks
// // Not enough matching songs, then check for matching Artists
// const matchingTracksByRecommendationsArtists = recommendations.filter(
//   (x) => !!circleArtistsObj[x.artistName]
// )
// if (matchingTracksByRecommendationsArtists.length > 0) {
//   newTracks.push(
//     ...matchingTracksByRecommendationsArtists
//       .filter((x) => !selectedAndPlaylist[x.isrc])
//       .slice(0, newTracks.length - numToFill)
//   )
// }
// // Couldn't find more matching songs
// return newTracks
// }

const saveSelectedTracks = async (
  circleCode,
  selected,
  uid,
  userProvider,
  tokens,
  minLimit,
  maxLimit
) => {
  const circleInfoSnap = await get(ref(database, `circles/${circleCode}/info`))
  const prevSelectedSnap = await get(
    ref(database, `circles/${circleCode}/members/${uid}/selected`)
  )
  const circlePlaylistSnap = await get(
    ref(database, `circles/${circleCode}/playlist`)
  )
  const circleInfo = circleInfoSnap.val()
  const circlePlaylist = circlePlaylistSnap.val() || []
  const prevSelected = prevSelectedSnap.val() || []

  const { hostID, provider, playlistID } = circleInfo

  const tracksWError = []
  const songsToAdd = []
  const newPlaylistObj = circlePlaylist.reduce((map, track) => {
    map[track.isrc] = true
    return map
  }, {})

  const selectedSongs = []
  console.log('provider', userProvider, provider)
  if (provider === 'apple_music') {
    selected.forEach((track) => {
      if (track.provider === 'apple_music') {
        selectedSongs.push({ ...track })
      } else {
        if (track.appleMusicID) {
          selectedSongs.push({ ...track, id: track.appleMusicID })
        } else if (track.spotifyID) {
          selectedSongs.push({ ...track })
        } else {
          tracksWError.push({ ...track })
        }
      }
    })
  } else {
    selected.forEach((track) => {
      if (track.provider === 'spotify') {
        selectedSongs.push({ ...track })
      } else {
        if (track.spotifyID) {
          selectedSongs.push({ ...track, id: track.spotifyID })
        } else if (track.appleMusicID) {
          selectedSongs.push({ ...track })
        } else {
          tracksWError.push({ ...track })
        }
      }
    })
  }

  selectedSongs.forEach((track) => {
    if (!newPlaylistObj[track.isrc]) {
      newPlaylistObj[track.isrc] = true
      songsToAdd.push(track)
    }
  })

  const prevSelectedObj = prevSelected.reduce((map, track) => {
    map[track.isrc] = true
    return map
  }, {})
  const userNewTracks = [
    ...selectedSongs.filter((x) => !prevSelectedObj[x.isrc])
  ]
  const newSelectedSongs = [...prevSelected, ...userNewTracks]

  // Combine latest tracks with these new tracks
  const remainingFill = songsToAdd.length > 3 ? 3 : songsToAdd.length
  const latestSongs = [
    ...songsToAdd.slice(0, 3),
    ...circlePlaylist.slice(remainingFill - 5)
  ]

  // const moreTracks = await fillRemainder(
  //   userProvider,
  //   circleCode,
  //   tokens,
  //   uid,
  //   songsToAdd,
  //   newSelectedSongs,
  //   newPlaylistObj,
  //   prevSelectedObj,
  //   minLimit,
  //   maxLimit
  // )
  // console.log('moreTracks', moreTracks)
  // songsToAdd.push(...moreTracks)
  // newSelectedSongs.push(...moreTracks)

  try {
    const playlistIDs = songsToAdd.map((track) => track.id)
    console.log('playlistIDs', playlistIDs)
    if (playlistIDs.length > 0) {
      // const hostSnap = await get(ref(database, `users/${hostID}`))
      // const { accessToken, refreshToken, appleMusicToken } = hostSnap.val()
      // let spotifyToken = null
      if (provider === 'spotify') {
        // try {
        //   await getSpotifyRecentlyPlayed(accessToken, 1)
        //   spotifyToken = accessToken
        // } catch (e) {
        //   // Refresh host's refresh token since this could be a guest joining and the host's token could have expired
        //   const success = await refreshOtherSpotifyToken(refreshToken, hostID)
        //   if (success) {
        //     const newHostSnap = await get(ref(database, `users/${hostID}`))
        //     spotifyToken = newHostSnap.val().accessToken
        //   }
        // }
        await addToPlaylist(tokens.accessToken, playlistID, playlistIDs)
      } else {
        await addToAppleMusicPlaylist(
          tokens.appleMusicToken,
          playlistID,
          playlistIDs
        )
      }
      circlePlaylist.push(...songsToAdd)
      await set(ref(database, `circles/${circleCode}/playlist`), circlePlaylist)
    }
    await set(
      ref(database, `circles/${circleCode}/members/${uid}/selected`),
      newSelectedSongs
    )
    await set(ref(database, `circles/${circleCode}/latestTracks`), latestSongs)

    return tracksWError
  } catch (error) {
    console.log('addToPlaylist', error)
    return selectedSongs.filter((x) => !prevSelectedObj[x.isrc])
  }
}

const addUserToMembers = async (
  uid,
  userInfo,
  circleCode,
  likedSongs = [],
  recentlyPlayed = [],
  topItems = []
) => {
  const circleMembersSnap = await get(
    ref(database, `circles/${circleCode}/members`)
  )
  const circleMembersObj = circleMembersSnap.val() || {}
  const prevCircleUserProfile = circleMembersObj[uid] || {}
  const prevOrder = prevCircleUserProfile?.userInfo?.order
  const order =
    typeof prevOrder !== 'undefined'
      ? prevOrder
      : Object.keys(circleMembersObj).length
  const prevSelected = prevCircleUserProfile?.selected || []

  const data = {
    userInfo: {
      order,
      ...userInfo
    },
    selected: prevSelected.length > 0 ? prevSelected : null,
    likedSongs,
    recentlyPlayed,
    topItems
  }

  await set(ref(database, `circles/${circleCode}/members/${uid}`), data)
}

const addUserPlaylistToCircle = async (
  uid,
  email,
  tokens,
  circleCode,
  userProvider
) => {
  let recentlyPlayed = []
  let topItems = []
  let likedSongs = []
  let userInfo = {}
  const songs = {}

  const { spotifyToken, appleMusicToken } = tokens

  if (userProvider === 'spotify') {
    userInfo = { uid, email, accessToken: spotifyToken, provider: userProvider }
    likedSongs = await getSpotifyLikedSongs(spotifyToken)
    recentlyPlayed = await getSpotifyRecentlyPlayed(spotifyToken)
    topItems = await getTopItems(spotifyToken, 'long_term', 30)
  } else {
    likedSongs = await getAppleMusicLibrary(
      appleMusicToken,
      0,
      30,
      spotifyToken
    )
    recentlyPlayed = await getAppleMusicRecentTracks(
      appleMusicToken,
      0,
      30,
      spotifyToken
    )
    const heavyRotation = await getAppleMusicHeavyRotation(
      appleMusicToken,
      0,
      30,
      spotifyToken
    )
    console.log('heavyRotation', heavyRotation)
    userInfo = {
      uid,
      email,
      appleMusicToken,
      provider: userProvider
    }
  }
  console.log('recentlyPlayed', recentlyPlayed)
  console.log('likedSongs', likedSongs)
  console.log('topItems', topItems)

  // const songsArr = [...recentlyPlayed, ...topItems]
  // if (userProvider === 'spotify') {
  //   // Apple Music library doesn't have ISRC
  //   songsArr.push(...(likedSongs || []))
  // }
  // songsArr.forEach((track) => {
  //   songs[track.isrc] = {
  //     id: track.id,
  //     provider: userProvider,
  //     title: track.name,
  //     href: track.href,
  //     albumName: track.albumName,
  //     artistName: track.artistName,
  //     artists: track.artists || null,
  //     popularity: track.popularity || null,
  //     genres: track.genres || null,
  //     spotifyID: track.spotifyID || null,
  //     appleMusicID: track.appleMusicID || null,
  //     spotifyHREF: track.spotifyHREF || null,
  //     appleMusicHREF: track.appleMusicHREF || null,
  //     releaseDate: track.releaseDate || null
  //   }
  // })

  await addUserToMembers(
    uid,
    userInfo,
    circleCode,
    likedSongs,
    recentlyPlayed,
    topItems
  )

  // if (Object.keys(songs).length > 0) {
  //   await update(ref(database, 'songs'), songs)
  // }
}

const createEvent = async ({
  isCompleted,
  type,
  oldCode,
  ocassion,
  ocassionOther,
  amOrPm,
  name,
  hostName,
  url,
  firstTime,
  noteForGuests,
  allowGuests,
  size,
  date,
  time,
  timezone,
  address,
  completed
}) => {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const circleCode =
    oldCode ||
    Array.from(
      { length: 5 },
      () => possible[Math.floor(Math.random() * possible.length)]
    ).join('')

  const hostID = auth.currentUser.uid
  const hostEmail = auth.currentUser.email
  const cost = getCost(size) || null

  if (type === 'new') {
    const data = {
      type: 'event',
      name,
      hostName,
      cost,
      size,
      date,
      time,
      timezone,
      noteForGuests,
      amOrPm,
      address,
      firstTime,
      allowGuests,
      ocassion,
      ocassionOther,
      url,
      hostID,
      completed,
      hostEmail: auth.currentUser.email,
      createdAt: moment().toISOString()
    }
    await Promise.all([
      update(ref(database, `circles/${circleCode}/info`), data),
      set(
        ref(database, `circlesRef/${hostID}/${circleCode}`),
        moment().toISOString()
      )
    ])

    await sendEmail('EVENT_CREATED', {
      code: circleCode,
      name,
      hostEmail,
      hostName,
      date,
      time,
      timezone,
      address
    })
  } else {
    const updatedData = isCompleted
      ? {
          name,
          date,
          time,
          timezone,
          ocassion,
          ocassionOther,
          noteForGuests,
          address,
          completed,
          url,
          amOrPm
        }
      : {
          name,
          hostName,
          size,
          date,
          time,
          timezone,
          ocassion,
          ocassionOther,
          noteForGuests,
          url,
          address,
          completed,
          amOrPm
        }

    await Promise.all([
      update(ref(database, `circles/${circleCode}/info`), updatedData)
    ])

    await sendEmail('EVENT_UPDATED', {
      code: circleCode,
      name,
      hostEmail,
      hostName,
      date,
      time,
      timezone,
      address
    })
  }

  return circleCode
}

const createEventPlaylist = async ({
  token,
  settingsToken,
  name,
  option,
  provider,
  circleCode
}) => {
  const playlistID =
    provider === 'spotify'
      ? await createSpotifyPlaylist(
          settingsToken.accessToken,
          settingsToken.spotifyID,
          name
        )
      : await createAppleMusicPlaylist(token.appleMusicToken, name)
  if (!playlistID) return null

  console.log('hostPlaylist playlistID', playlistID)

  await update(ref(database, `circles/${circleCode}/info`), {
    spotifyID: settingsToken.spotifyID,
    playlistID,
    option,
    provider,
    musicCollab: true
  })

  const hostID = auth.currentUser.uid
  const hostEmail = auth.currentUser.email
  const tokens =
    provider === 'spotify'
      ? { spotifyToken: token.accessToken }
      : { appleMusicToken: token.appleMusicToken }
  await addUserPlaylistToCircle(
    hostID,
    hostEmail,
    tokens,
    circleCode,
    provider,
    option
  )

  return circleCode
}

export {
  verifyCircle,
  addUserToMembers,
  saveSelectedTracks,
  addUserPlaylistToCircle,
  createEvent,
  createEventPlaylist
}
