import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useWatch } from 'react-hook-form'
import { WorldIcon } from '../../../assets/icons'
import { timezones } from '../../../utils/timezones'
import { twMerge } from 'tailwind-merge'

const Offset = ({ form, setToggleDivFocus, className }) => {
  const divRef = useRef(null)

  const [opened, setOpened] = useState(false)

  const offset = useWatch({
    name: 'when.offset',
    control: form.control
  })

  const filteredTimezoneArray = useMemo(() => {
    return timezones.filter((timezone) => {
      if (!offset) return true

      const searcher = offset?.toLowerCase()
      return timezone?.value.toLowerCase().includes(searcher)
    })
  }, [offset])

  const handleOffset = (offset) => {
    form.setValue('when.offset', offset)
    setOpened(false)
  }

  const onFocus = () => {

    setOpened(true)
    if (setToggleDivFocus) setToggleDivFocus(true)
  }

  const onBlur = () => {
    setTimeout(() => {
      if (!divRef.current?.contains(document?.activeElement)) {
        setOpened(false)
      }
    }, 100)
  }

  const handleOutsideClick = useCallback(
    (event) => {
      if (divRef.current && !divRef.current?.contains(event?.target)) {
        setOpened(false)
      }
    },
    []
  )

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div
      ref={divRef}
      className={twMerge(
        'relative flex-1 px-4 h-10 flex bg-gray items-center justify-center rounded-input cursor-pointer',
        className
      )}
    >
      <input
        id="dateOffsetInput"
        type="text"
        className="w-full bg-gray outline-none text-center"
        {...form.register('when.offset')}
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="off"
        value={offset || ''}
      />
      {!opened && !offset && (
        <WorldIcon
          fill={'grey'}
          width={22}
          height={22}
          className="absolute w-full left-0 right-0 pointer-events-none"
        />
      )}
      {opened && (
        <div className="border-1 absolute top-full right-0 transform flex flex-col items-start rounded-input mt-2 max-h-56 overflow-y-auto z-10 bg-background w-[280px] scrollbar-custom">
          {filteredTimezoneArray.map((timezone, index) => (
            <div
              key={index}
              className="hover:bg-primary hover:text-white w-full p-2 rounded-input cursor-pointer"
              onClick={() => handleOffset(timezone.value)}
            >
              <span>{timezone.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default memo(Offset)
