import React, { useMemo } from 'react'
import {
  CalendarIcon,
  ClockIcon,
} from '../../../assets/icons'
import { useWatch } from 'react-hook-form'
import { Tooltip } from '@chakra-ui/react'
import moment from 'moment'

const NewEventDateAndTime = ({ form, setDateModal, setTimeModal }) => {
  const fieldWhen = useWatch({
    name: 'when',
    control: form.control
  })

  const formattedEventTime = useMemo(() => {
    if (!fieldWhen?.amPm && !fieldWhen?.offset) return

    const timeWithAmPm = `${fieldWhen.time} ${fieldWhen.amPm}`

    const formattedTime = moment(timeWithAmPm, 'hh:mm A').format(
      `hh:mm A [${fieldWhen.offset}]`
    )

    return formattedTime
  }, [fieldWhen?.amPm, fieldWhen?.offset, fieldWhen?.time])

  return (
    <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] gap-3 rounded-md border-1'>
      <div className="flex flex-row items-center justify-start">
        <CalendarIcon className="mr-[8px]" />
        {fieldWhen?.date ? (
          <Tooltip label="Click to change">
            <span className='cursor-pointer' onClick={() => setDateModal(true)}>{moment(fieldWhen.date).format('MMM DD, YYYY')}</span>
          </Tooltip>
        ) : (
          <span
            className="text-primary cursor-pointer font-bold hover:underline"
            onClick={() => setDateModal(true)}
          >
            Add date
          </span>
        )}
      </div>
      <div className="flex flex-row items-center justify-start">
        <ClockIcon className="mr-[8px]" />
        {fieldWhen?.time ? (
          <Tooltip label="Click to change">
            <span className='cursor-pointer' onClick={() => setTimeModal(true)}>{formattedEventTime}</span>
          </Tooltip>
        ) : (
          <span
            className="text-primary cursor-pointer font-bold hover:underline"
            onClick={() => setTimeModal(true)}
          >
            Add time
          </span>
        )}
      </div>
    </div>
  )
}

export default NewEventDateAndTime
