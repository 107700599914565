import React, { memo, useCallback, useEffect, useState } from 'react'
import Base from '../base'
import Tab from './Tab/Tab'
import {
  auth,
  database,
  query,
  get,
  ref,
  orderByValue,
  logEvent,
  analytics
} from '../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useNavigate } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import { Helmet } from 'react-helmet'
import { getSavedPhone, removeEventData } from '../../services/database'
import Button from '../../components/Button/Button'
import CancelEventModal from '../../components/MyEvents/CancelEventModal/CancelEventModal'
import * as amplitude from '@amplitude/analytics-browser'

const MyEvents = () => {
  const [user, loading] = useAuthState(auth)
  const [loadingEvent, setLoadingEvent] = useState(false)
  const [pastEvents, setPastEvents] = useState([])
  const [futureEvents, setFutureEvents] = useState([])
  const [deleteEventModal, setDeleteEventModal] = useState(null)

  const navigate = useNavigate()

  const fetchCircles = useCallback(async () => {
    const savedPhone = getSavedPhone() ?? {}
    const userId = user?.uid ?? savedPhone.phone

    if (!userId) {
      return
    }

    setLoadingEvent(true)

    const currentDate = new Date()
    const ftEvents = []
    const psEvents = []
    let circlesObj = {}

    if (!!user) {
      circlesObj =
        (
          await get(
            query(ref(database, `circlesRef/${userId}`), orderByValue())
          )
        )?.val() || {}
    } else {
      circlesObj =
        (
          await get(
            query(ref(database, `joinedCirclesRef/${userId}`), orderByValue())
          )
        )?.val() || {}
    }

    let promises = []
    const _circles = Object.keys(circlesObj).map((key) => ({
      key,
      joinedAt: circlesObj[key]
    }))
    _circles.sort((a, b) => new Date(b.joinedAt) - new Date(a.joinedAt))
    _circles.forEach(({ key }) => {
      promises.push(get(ref(database, `circles/${key}/info`)))
    })

    const results = await Promise.all(promises)

    results.forEach((item, index) => {
      _circles[index] = { ..._circles[index], ...(item?.val() || {}) }
    })

    _circles.forEach((event) => {
      if (!event.date || new Date(event.date) > currentDate) {
        ftEvents.push(event)
      } else {
        psEvents.push(event)
      }
    })

    setPastEvents(psEvents)
    setFutureEvents(ftEvents)

    setLoadingEvent(false)
  }, [user])

  const handleCreateEvent = () => {
    amplitude.track('Create Event CTA clicked', {
      'CTA label': 'primary action'
    })
    logEvent(analytics, 'new_event', {
      source: 'HOME_BANNER'
    })
    removeEventData()
    navigate('/event/new')
  }

  useEffect(() => {
    if (!loading) {
      fetchCircles().then(() => null)
    }
  }, [loading, navigate, fetchCircles])

  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - See your events history</title>
        <meta
          name="description"
          content="See events you’ve hosted or attended. Click on the tiles to see the event details."
        />
      </Helmet>
      <div className="flex flex-1 flex-col md:items-stretch">
        <div className="w-full flex items-center justify-between">
          <h2 className="text-[20px]">My events</h2>
          <Button
            text={'Create event'}
            size={'extra-small'}
            type={'border'}
            onClick={handleCreateEvent}
          />
        </div>

        {loadingEvent || loading ? (
          <div className="flex flex-1 items-center justify-center">
            <ClipLoader color="#5B4ABC" />
          </div>
        ) : (
          <Tab
            futureEvents={futureEvents}
            pastEvents={pastEvents}
            fetchCircles={fetchCircles}
            setDeleteEventModal={setDeleteEventModal}
          />
        )}
      </div>

      {!!deleteEventModal ? (
        <CancelEventModal
          opened
          onClose={() => setDeleteEventModal(false)}
          eventInfo={deleteEventModal}
          fetchCircles={fetchCircles}
        />
      ) : null}
    </Base>
  )
}

export default memo(MyEvents)
