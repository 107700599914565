import React, { memo } from 'react'
import GuestItem from './GuestItem'

const GuestList = ({ guests, onDeleteGuest, removeRsvp }) => {
  return (
    <div className="border-1 rounded-input mt-[14px] min-w-[370px] flex items-center w-full">
      <table className="overflow-hidden w-full">
        <thead>
          <tr>
            <th className="py-3 w-[5%] rounded-tl-input bg-terciary"></th>
            <th className="py-3 w-[30%] bg-terciary">
              <div className="w-full flex ml-1 md:ml-4 text-white">
                Guest Name
              </div>
            </th>
            <th className="py-3 w-[35%] bg-terciary">
              <div className="w-full flex text-white">Contact Information</div>
            </th>
            {removeRsvp && <th
              className={`py-3 w-[20%] bg-terciary ${!onDeleteGuest && 'rounded-tr-input'}`}
            >
              <div className="w-full flex items-center md:justify-start justify-center text-white"><span>RSVP</span></div>
            </th>}
            {!!onDeleteGuest && (
              <th
                className={`py-3 w-[10%] rounded-tr-input bg-primary ${!!onDeleteGuest && 'rounded-tr-input'}`}
              >
                <div className="w-full flex items-center justify-center text-white">
                  Action
                </div>
              </th>
            )}
          </tr>
        </thead>

        <tbody className="text-center">
          {guests.map((guest, index) => (
            <GuestItem
              key={index}
              guest={guest}
              onDelete={onDeleteGuest}
              index={index + 1}
              removeRsvp={removeRsvp}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default memo(GuestList)
