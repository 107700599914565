import React, {
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import Input from '../../../components/Input/Input'
import { PlusIcon, SearchIcon } from '../../../assets/icons'
import ClipLoader from 'react-spinners/ClipLoader'
import MusicCard from '../../../components/EventDetails/MusicCard/MusicCard'
import Button from '../../../components/Button/Button'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getTokens, maxLimitSongs } from '../../../services/helpers'
import {
  getSpotifyRecommendations,
  searchSpotify
} from '../../../services/Spotify'
import { searchAppleMusic } from '../../../services/AppleMusic'
import { Container, Flex, useToast } from '@chakra-ui/react'
import SelectedModal from '../../../components/SelectedModal'
import TracksWErrorModal from '../../../components/TracksWErrorModal'
import SimilarSongsModal from '../../../components/SimilarSongsModal'
import { get, ref } from 'firebase/database'
import { analytics, auth, database, logEvent } from '../../../firebase'
import { useNavigate } from 'react-router-dom'
import {
  fetchLibrary,
  fetchRecentTracks,
  fetchTopTracks
} from '../../../services/Circle/user'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useObject } from 'react-firebase-hooks/database'
import { useRef } from 'react'

let TIMEOUT_ID = null

const schema = yup
  .object({
    search: yup.string().notRequired(),
    filter: yup.string().notRequired()
  })
  .required()

const SelectSongsStep = ({
  circleCode,
  circleInfo,
  rsvpId,
  setStep,
  provider,
  option
}) => {
  const [searchResults, setSearchResults] = useState([])
  const [popUp, setPopUp] = useState(null)
  const [fullPlaylist, setFullPlaylist] = useState([])
  const [searchText, setSearchText] = useState([])
  const [selected, setSelected] = useState([])
  const [tracksWError, setTracksWError] = useState([])
  const [trackWithError, setTrackWithError] = useState(null)
  const fetchingRef = useRef(false)
  const [playlist, setPlaylist] = useState([])
  const toast = useToast()
  const inputRef = createRef()
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth)
  const [pageSize, setPageSize] = useState(15)

  const [memberSnap, memberLoading] = useObject(
    ref(
      database,
      `circles/${circleCode || '1'}/members/${user?.uid || '1'}/userInfo`
    )
  )
  const [prevSelectedSongsSnap, prevSelectedSongsLoading] = useObject(
    ref(
      database,
      `circles/${circleCode || '1'}/members/${
        user?.uid || rsvpId || '1'
      }/selected`
    )
  )
  const memberInfo = memberSnap?.val()
  const prevSelectedSongs = useMemo(
    () => prevSelectedSongsSnap?.val() || [],
    [prevSelectedSongsSnap]
  )
  const maxLimit = maxLimitSongs(memberInfo?.order)
  const minLimit = 0 // minLimitSongs(memberInfo?.order)
  // const limitReached =
  //   maxLimit !== -1 && (prevSelectedSongs || []).length >= maxLimit

  const filterOptions = useMemo(
    () =>
      [
        prevSelectedSongs.length > 0
          ? {
              value: 'recommendations',
              label: 'Coteri suggestions'
            }
          : null,
        {
          value: '4zAkxb3J6a5cM6KAP0nVGm',
          label: 'Popular Today'
        },
        {
          value: '7ejtgMFVvgow5Pk2Zq3KeG',
          label: 'Popular All Time'
        },
        {
          value: '6xMqitAbn0EF8pQs5UVUcZ',
          label: '2010s'
        },
        {
          value: '6oVSYR8QHmeN2tWSFqRm6o',
          label: '2000s'
        },
        {
          value: '16Zwb7N1OCH4JqzOAL9FLx',
          label: '1990s'
        },
        {
          value: '5pD9zzBjztHtTrABt7Abcb',
          label: '1980s'
        },
        {
          value: '3gHktisWbRE7EulXb0VCVh',
          label: '1970s'
        },
        {
          value: '0yN4owB2wQJ41oZFo9u8X3',
          label: '1960s'
        },
        option !== 'guest'
          ? {
              value: 'recently',
              label: 'Recently played songs'
            }
          : null,
        option !== 'guest'
          ? {
              value: 'library',
              label: 'Liked songs'
            }
          : null
      ].filter((x) => !!x),
    [prevSelectedSongs, option]
  )

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [search, filter] = form.watch(['search', 'filter'])

  const searchProvider = useCallback(async () => {
    console.log('searchProvider', option, provider)
    if (!provider || !option) return
    const searchText = search

    let results = []

    try {
      const token = await getTokens(option, `streaming-${circleCode}`)
      if (!token.appleMusicToken && !token.accessToken) return

      if (provider === 'spotify') {
        results = await searchSpotify(token.accessToken, searchText)
      } else {
        results = await searchAppleMusic(
          token.appleMusicToken,
          'us',
          searchText,
          token.accessToken
        )
      }

      setSearchResults(
        results.filter(
          (value, index, self) =>
            index === self.findIndex((x) => x.isrc === value.isrc)
        )
      )
    } catch (e) {
      toast({
        title: e.message || 'Error while searching track',
        position: 'top',
        status: 'error'
      })
    }
  }, [provider, option, search, toast, circleCode])

  const debouncedSearch = (searchText) => {
    clearTimeout(TIMEOUT_ID)
    TIMEOUT_ID = window.setTimeout(
      () => searchProvider(searchText).then(() => null),
      500
    )
  }

  const searchTracks = (text, playlist) => {
    const searchText = text.toLowerCase()
    setSearchText(searchText)
    setPlaylist(
      playlist.filter(
        (x) =>
          x.name?.toLowerCase().search(searchText) > -1 ||
          x.name?.toLowerCase().includes(searchText) ||
          x.artistName?.toLowerCase().search(searchText) > -1 ||
          x.artistName?.toLowerCase().includes(searchText) ||
          x.albumName?.toLowerCase().search(searchText) > -1 ||
          x.albumName?.toLowerCase().includes(searchText) ||
          (searchText.length === 4 &&
            x.releaseDate?.toLowerCase().startsWith(searchText)) ||
          x.genres?.find((genre) => genre.toLowerCase().search(searchText) > -1)
      )
    )
    debouncedSearch(searchText)
  }

  const onSearch = () => {
    if (!searchText || searchText === '') {
      setPlaylist(fullPlaylist)
    } else {
      inputRef.current.scrollIntoView({ block: 'start' })
      searchTracks(searchText, fullPlaylist)
    }
  }

  const searchSimilars = (item) => {
    setTrackWithError(item)
  }

  const toggleSelectSong = (item) => {
    const _selected = selected.concat([])
    const selectedIdx = _selected.findIndex((x) => x.isrc === item.isrc)
    if (selectedIdx > -1) {
      _selected.splice(selectedIdx, 1)
    } else {
      _selected.push(item)
    }
    if (_selected.length === 0) {
      setPopUp(false)
    }
    setSelected(_selected)
  }

  const replaceSong = (item, replace) => {
    const _tracks = tracksWError.concat([])
    const trackIdx = _tracks.findIndex((x) => x.isrc === item.isrc)
    if (trackIdx > -1) {
      _tracks[trackIdx] = { ...replace, original: item.original || item }
      setTracksWError(_tracks)
    }
    setTrackWithError(null)
  }

  const handleSelect = () => {
    logEvent(analytics, 'songs_selected', {
      source: 'SELECT_SONGS_EVENT'
    })
    navigate(`/event/${circleCode}`)
  }

  const handlePreviousStep = () => {
    setStep(0)
  }

  const handleConfirm = () => {
    if (selected.length === 0) {
      handleSelect()
    } else {
      setPopUp('selected')
    }
  }

  const handleLoadMoreSongs = () => {
    const playlist = search ? searchResults : fullPlaylist

    if (pageSize >= playlist.length) {
      toast({
        status: 'info',
        title: 'No more songs to load',
        position: 'top'
      })
      return
    }

    setPageSize(pageSize + 15)
  }

  const handleOption = useCallback(
    async (val) => {
      // console.log('handleOption', val, option, provider)
      if (!provider || !option) return

      fetchingRef.current = true

      try {
        let tracks = []
        setFullPlaylist([])

        const token = await getTokens(option, `streaming-${circleCode}`)

        if (!token.appleMusicToken && !token.accessToken) {
          toast({
            status: 'error',
            title: 'Error while refreshing tokens',
            position: 'top'
          })
          fetchingRef.current = false
          return
        }

        switch (val) {
          case 'recommendations':
            // Get Latest tracks
            const latestTracksSnap = await get(
              ref(database, `circles/${circleCode}/latestTracks`)
            )
            const latestTracks = latestTracksSnap.val() || []

            if (latestTracks.length > 0) {
              tracks = await getSpotifyRecommendations(
                token.accessToken,
                latestTracks.slice(0, 5),
                15
              )
            }
            break
          case 'recently':
            tracks = await fetchRecentTracks(provider, {
              spotifyToken: token.accessToken,
              appleMusicToken: token.appleMusicToken
            })
            break
          case 'top':
            tracks = await fetchTopTracks(provider, {
              spotifyToken: token.accessToken,
              appleMusicToken: token.appleMusicToken
            })
            break
          case 'library':
            tracks = await fetchLibrary(provider, {
              spotifyToken: token.accessToken,
              appleMusicToken: token.appleMusicToken
            })
            break
          default:
            const playlistTracksSnap = await get(
              ref(database, `coteriPlaylists/${val}/tracks`)
            )
            tracks = playlistTracksSnap.val() || []
            break
        }

        setFullPlaylist(tracks)

        fetchingRef.current = false
      } catch (e) {
        fetchingRef.current = false
        toast({
          status: 'error',
          title: e.message || 'Error while fetching tracks',
          position: 'top'
        })
      }
    },
    [circleCode, provider, option, toast]
  )

  useEffect(() => {
    if (search) {
      searchProvider()
    } else {
      setSearchResults([])
    }
  }, [search, searchProvider])

  useEffect(() => {
    if (!!option && !prevSelectedSongsLoading && !fetchingRef.current) {
      setPageSize(15)
      if (!!filter?.value) {
        handleOption(filter.value).then(() => null)
      } else {
        form.setValue('filter', filterOptions[0])
      }
    }
  }, [
    handleOption,
    prevSelectedSongsLoading,
    provider,
    option,
    filter,
    form,
    filterOptions
  ])

  useEffect(() => {
    if (!loading && !prevSelectedSongsLoading && !memberLoading) {
      if (!memberInfo) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
      }
    }
  }, [
    loading,
    circleCode,
    prevSelectedSongsLoading,
    memberLoading,
    memberInfo,
    selected,
    navigate
  ])

  return (
    <>
      <div className="mt-[20px] flex flex-1 flex-col visible">
        <h2>
          Search for artists or songs you’d like to listen to at your event.
        </h2>

        <span className="text-[14px] font-medium my-3">
          Click
          <span
            className={`w-[18px] h-[18px] inline-flex border-terciary border-1 items-center justify-center rounded-full mx-1`}
            style={{ verticalAlign: -7 }}
          >
            <PlusIcon fill={'#120a31'} />
          </span>
          to add songs to your cart, then click “Continue” when you’re ready to
          add them to the playlist.
        </span>

        {!!filter?.value ? (
          <div className="flex items-center mt-[15px] sm:flex-row flex-col gap-4">
            <Input
              register={form.register('filter')}
              type={'select'}
              placeholder="Select filter"
              options={filterOptions}
              form={form}
            />
            <Input
              register={form.register('search')}
              form={form}
              type="icon"
              placeholder="Search for an artist or song..."
              className="w-full"
              iconRight={<SearchIcon fill="#000" />}
            />
          </div>
        ) : null}

        <div id="songs" className="flex flex-1 mb-28">
          {loading ||
          memberLoading ||
          prevSelectedSongsLoading ||
          fetchingRef.current ? (
            <div className="flex flex-1 items-center justify-center">
              <ClipLoader color="#5B4ABC" />
            </div>
          ) : (
            <div className="flex flex-1 mt-[15px] w-full flex-col p-2">
              {[...(search ? searchResults : fullPlaylist)]
                .splice(0, pageSize)
                .map((music, index) => (
                  <MusicCard
                    key={index}
                    music={music}
                    canPlay={true}
                    className="mb-3"
                    onClick={() => toggleSelectSong(music)}
                    checked={[...selected, ...prevSelectedSongs].find(
                      (selectedMusic) => music.id === selectedMusic.id
                    )}
                    provider={provider}
                  />
                ))}
              {pageSize >=
              (search ? searchResults : fullPlaylist).length ? null : (
                <div className="w-full flex items-center justify-center">
                  <Button
                    text={'Load more songs'}
                    size={'medium'}
                    onClick={handleLoadMoreSongs}
                    className={'max-w-[250px]'}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Flex bottom="0" left="0" right="0" position="fixed" zIndex="2">
        <Container
          bg="white"
          w="100%"
          maxW="800px"
          borderTopWidth="1px"
          borderColor="#EEE"
          py="16px"
          px={{ base: '16px', md: '36px' }}
          boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
        >
          <div className="flex flex-row justify-between items-end">
            <Button
              type="text"
              disabled={memberLoading || prevSelectedSongsLoading}
              text={'Back'}
              onClick={handlePreviousStep}
            />
            <Button
              disabled={memberLoading || prevSelectedSongsLoading}
              text={'View selections'}
              onClick={handleConfirm}
              iconRight={
                <span
                  className="ml-2 inline-flex items-center justify-center rounded-full bg-off-white"
                  style={{ width: '28px', height: '28px' }}
                >
                  <span className="text-secondary">
                    {selected?.length > 9 ? '9+' : selected?.length}
                  </span>
                </span>
              }
              className={'flex-row items-center justify-center'}
            />
          </div>
        </Container>
      </Flex>
      {!!memberInfo ? (
        <SelectedModal
          uid={user?.uid || rsvpId}
          isGuest={false}
          popUp={popUp}
          circleCode={circleCode}
          memberInfo={memberInfo}
          setPopUp={setPopUp}
          provider={provider}
          option={option}
          selected={selected}
          minLimit={minLimit}
          maxLimit={maxLimit}
          connections={[]}
          setTracksWError={setTracksWError}
          prevSelectedSongs={prevSelectedSongs}
          toggleSelectSong={toggleSelectSong}
          onSelect={handleSelect}
        />
      ) : null}
      {!!memberInfo ? (
        <TracksWErrorModal
          uid={user?.uid || rsvpId}
          selected={selected}
          tracksWError={tracksWError}
          setTracksWError={setTracksWError}
          setTrackWithError={setTrackWithError}
          provider={provider}
          option={option}
          circleCode={circleCode}
          memberInfo={memberInfo}
          minLimit={minLimit}
          maxLimit={maxLimit}
          connections={[]}
          searchSimilars={searchSimilars}
          onSelect={handleSelect}
        />
      ) : null}
      {!!memberInfo ? (
        <SimilarSongsModal
          track={trackWithError}
          circleCode={circleCode}
          memberInfo={memberInfo}
          circleInfo={circleInfo}
          provider={provider}
          option={option}
          maxLimit={maxLimit}
          selected={selected}
          prevSelectedSongs={prevSelectedSongs}
          connections={[]}
          replaceSong={replaceSong}
          setTrackWithError={setTrackWithError}
        />
      ) : null}
    </>
  )
}

export default memo(SelectSongsStep)
