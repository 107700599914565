import React, { useEffect, useState } from 'react'
import RSVPSelectSongsStep from './Step2/RSVPSelectSongsStep'
import { useObject } from 'react-firebase-hooks/database'
import { database, ref } from '../../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import PhoneVerificationModal from '../../../components/NewGuest/PhoneVerificationModal/PhoneVerificationModal'

const RSVPPlaylist = () => {
  const { circleCode, rsvpId } = useParams()
  const [phoneToVerify, setPhoneToVerify] = useState(null)
  const navigate = useNavigate()

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [rsvpSnap, rsvpLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests/${rsvpId || '1'}`)
  )

  const eventInfo = eventSnap?.val()
  const rsvpInfo = rsvpSnap?.val()

  useEffect(() => {
    if (!eventLoading && !rsvpLoading) {
      if (
        !eventInfo ||
        !rsvpInfo ||
        !rsvpInfo.phone ||
        (!eventInfo.completed && !eventInfo.withCanva)
      ) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      if (!rsvpInfo.verified) {
        setPhoneToVerify(rsvpInfo.phone)
        return
      }
      setPhoneToVerify(null)
      if (!rsvpInfo.response) {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}`, {
          replace: true
        })
        return
      }
      if (rsvpInfo.response !== 'yes') {
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`, {
          replace: true
        })
        return
      }
    }
  }, [
    eventLoading,
    rsvpLoading,
    eventInfo,
    rsvpInfo,
    circleCode,
    rsvpId,
    navigate
  ])

  if (eventLoading || rsvpLoading) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <ClipLoader color="#5B4ABC" />
      </div>
    )
  }

  return (
    <div className="flex flex-1 flex-col md:items-stretch">
      <RSVPSelectSongsStep
        circleCode={circleCode}
        rsvpId={rsvpId}
        circleInfo={eventInfo}
        option="guest"
        provider="spotify"
      />
      {!!rsvpId && !!phoneToVerify ? (
        <PhoneVerificationModal
          circleCode={circleCode}
          rsvpId={rsvpId}
          phone={phoneToVerify}
          onClose={() => setPhoneToVerify(false)}
        />
      ) : null}
    </div>
  )
}

export default RSVPPlaylist
