import React, { useEffect } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import Base from './base'
import '../styles/privacy.css'
import { Helmet } from 'react-helmet'
import Button from '../components/Button/Button'
import { useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { analytics, logEvent } from '../firebase'

const ErrorPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    const params =
      typeof window != null ? queryString.parse(window.location.search) : {}
    logEvent(analytics, 'error_page', {
      source: params.from ?? window.location.pathname
    })
  }, [])

  return (
    <Base isPublic hideBg={true} bottomBar={false} allowFullHeight={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - Privacy Policy</title>
        <meta name="description" content="See the Coteri Privacy Policy." />
      </Helmet>
      <Flex
        flex="1"
        direction="column"
        mb="280px"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          mt="20px"
          mb="40px"
          textAlign="center"
          color="#422d8f"
          lineHeight={{ base: '64px', md: '96px' }}
          fontSize={{ base: '56px', md: '86px' }}
          fontWeight="700"
        >
          Oops!
        </Text>
        <Text
          textAlign="center"
          color="#000000"
          fontSize="22px"
          fontWeight="600"
        >
          404 - PAGE NOT FOUND
        </Text>
        <Text
          textAlign="center"
          mt="10px"
          maxW="70%"
          color="#000000"
          fontSize="16px"
        >
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable.
        </Text>
        <Button
          className="mt-10"
          text="Go to Home page"
          onClick={() => navigate('/')}
        ></Button>
      </Flex>
    </Base>
  )
}

export default ErrorPage
