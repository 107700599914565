import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { ClockIcon } from '../../../assets/icons'
import InputMask from 'react-input-mask'
import { generateMeetingTimes } from '../../../constants'
import { Controller, useWatch } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

const Time = ({ form, setToggleDivFocus, className }) => {
  const divRef = useRef(null)

  const [opened, setOpened] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  const watcher = useWatch({
    name: 'when.time',
    control: form.control
  })

  const handleSetTime = (time) => {
    const [hour, minute] = time.split(/[:\s]/)
    const formattedTime = `${hour.padStart(2, '0')}:${minute.padStart(2, '0')}`

    form.setValue('when.time', formattedTime)
    setOpened(false)  // Close dropdown after selection
  }

  const onFocus = () => {
    setInputFocused(true)
    setOpened(true)

    if (setToggleDivFocus) setToggleDivFocus(true)
  }

  const onBlur = () => {
    // Delay to allow click event to register
    setTimeout(() => {
      if (!divRef.current.contains(document.activeElement)) {
        setOpened(false)
        setInputFocused(false)
      }
    }, 100)
  }

  const meetingDefaultTimes = useMemo(() => generateMeetingTimes(), [])

  const handleOutsideClick = useCallback(
    (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setOpened(false)
      }
    },
    []
  )

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [handleOutsideClick])

  return (
    <div
      ref={divRef}
      className={twMerge(
        'relative px-[6px] h-10 flex bg-gray items-center justify-items-center rounded-input cursor-pointer flex-[0.4]',
        className
      )}
    >
      <Controller
        name={'when.time'}
        control={form.control}
        render={({ field: { onChange, value, name } }) => (
          <InputMask
            id="dateTimeInput"
            mask="99:99"
            type="text"
            className="w-full bg-gray outline-none text-center"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            autoComplete="off"
            value={value}
          />
        )}
      />
      {!opened && !/^[0-9]/.test(watcher && watcher[0]) && (
        <ClockIcon
          fill={'grey'}
          width={22}
          height={22}
          className="absolute w-full left-0 right-0 pointer-events-none"
        />
      )}
      {opened && (
        <div className="border-1 absolute flex top-[160px] flex-col items-start rounded-input w-[120px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-background z-10 scrollbar-custom max-h-56 overflow-y-auto">
          {meetingDefaultTimes.map((option, index) => (
            <div
              key={index}
              className="hover:bg-primary hover:text-white w-full p-2 rounded-input cursor-pointer"
              onClick={() => handleSetTime(option)}
            >
              <span>{option}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default memo(Time)
