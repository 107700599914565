import * as React from 'react'

function UndefinedResponse({ fill, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      {...props}
    >
      <g className="nc-icon-wrapper" fill={fill}>
        <path
          d="M12,0C5.373,0,0,5.373,0,12s5.373,12,12,12,12-5.373,12-12C23.981,5.381,18.619,.019,12,0Zm5,13H7c-.553,0-1-.448-1-1s.447-1,1-1h10c.553,0,1,.448,1,1s-.447,1-1,1Z"
          fill={fill}
        ></path>
      </g>
    </svg>
  )
}

export default UndefinedResponse
