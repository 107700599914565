import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import React from 'react'
import Button from '../../Button/Button'
import { XIcon } from '../../../assets/icons'
import Input from '../../Input/Input'
import { categories } from '../../../utils/categories'
import { useWatch } from 'react-hook-form'

const NewEventOcassionModal = ({ isOpen, form, onClose }) => {
  const [fieldOcassion, fieldOcassionOther] = useWatch({
    name: ['ocassion', 'ocassionOther'],
    control: form.control
  })

  const handleClose = () => {
    if (fieldOcassion?.value === 'other' && !fieldOcassionOther) {
      form.setError('ocassionOther', { type: 'custom', message: 'message is required' });
      return
    }

    onClose()
  }

  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <div className='p-10'>
          <h2>Select an ocassion</h2>

          <Input
            register={form.register('ocassion')}
            type={'select'}
            className={'mt-[20px]'}
            placeholder="Select a ocassion *"
            options={categories}
            hasError={form.formState.errors.ocassion?.message}
            form={form}
          />
          {fieldOcassion?.value === 'other' && (
            <Input
              type="multiline-fixed-placeholder"
              placeholder="What’s the occasion? How do you plan to use Coteri?"
              className="mt-[18px]"
              fixedPlaceholder={'Occasion *'}
              register={form.register('ocassionOther')}
              hasError={form.formState.errors.ocassionOther?.message}
              form={form}
            />
          )}

          <div className='flex flex-row items-center justify-end mt-5'>
            <Button
              text={'Continue'}
              onClick={handleClose}
            />
          </div>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default NewEventOcassionModal
