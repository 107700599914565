import { prepareSpotifyLoginUrl, updateUser, verifySpotifyToken } from './User'
import {
  auth,
  getProfile,
  getSettings,
  updateProfile,
  updateSettings
} from '../firebase'
import axios from 'axios'
import {
  getSpotifyRecentlyPlayed,
  refreshOtherSpotifyToken,
  refreshSpotifyToken
} from './Spotify'
import { getTempProfile } from './database'

const features = [
  'danceability',
  'energy',
  'speechiness',
  'acousticness',
  'instrumentalness',
  'liveness',
  'valence',
  'tempo',
  'loudness'
]

const isActive = (circleInfo = {}) =>
  circleInfo.type !== 'event' ||
  (circleInfo.type === 'event' &&
    (!!circleInfo.completed || !!circleInfo.withCanva))
// && (circleInfo.paid || 0) === (circleInfo.cost || 0))

//calculates min limit for selecting songs
const minLimitSongs = (order = 0) => {
  if (order < 11) return 3 // 1 - 10 people
  return 0 // 11+ people
}

//calculates max limit for selecting songs
const maxLimitSongs = (order = 0) => {
  if (order === 0) return -1 // Host
  if (order < 21) return 5 // 1 - 20 people
  return 1 // 21+ people
}

//calculates the manhattan distance from the feature means
const manhattanDistance = async (trackData) => {
  await featureScaling(trackData)
  let featureMeans = await getFeatureMeans(trackData)
  let mDistance = 0
  trackData.forEach((track) => {
    mDistance = 0
    features.forEach((feature) => {
      mDistance =
        mDistance + Math.abs((track[feature] || 0) - featureMeans[feature])
    })
    track.mDistance = mDistance
  })
  return trackData
}

const featureScaling = async (trackData) => {
  //Comput min, max, and range of each attribute
  let minMaxRangeResults = await getMinMaxRange_AudioFeatures(trackData)
  ////Rescaling, min-max normalization
  trackData.forEach((track) => {
    features.forEach((feature) => {
      let trackFeatureValue = track[feature]
      let minFeatureValue = minMaxRangeResults[0][feature]
      let rangeFeatureValue = minMaxRangeResults[2][feature]
      track[feature] = (trackFeatureValue - minFeatureValue) / rangeFeatureValue
    })
  })
  return trackData
}

const getMinMaxRange_AudioFeatures = async (trackData) => {
  let min = {}
  let max = {}
  let range = {}

  features.forEach((feature) => {
    min[feature] = 9999999
    max[feature] = -9999999
    trackData.forEach((track) => {
      if (track[feature] < min[feature]) {
        min[feature] = track[feature]
      }
      if (track[feature] > max[feature]) {
        max[feature] = track[feature]
      }
    })
    range[feature] = max[feature] - min[feature]
  })

  return [min, max, range]
}

const getFeatureMeans = async (trackData) => {
  const trackQty = trackData.length
  let featureMeans = {}
  features.forEach((feature) => {
    let total = 0
    trackData.forEach((track) => {
      total = total + (track[feature] || 0)
    })
    featureMeans[feature] = total / trackQty
  })
  return featureMeans
}

const getUniqueValues = async (array) => {
  let uniqueValuesArray = []

  for (let i = 0; i < array.length; i++) {
    let element = array[i]
    if (!uniqueValuesArray.includes(element)) {
      uniqueValuesArray.push(element)
    }
  }
  return uniqueValuesArray
}

export const refreshMySpotifyToken = async (redirectUrl) => {
  const spotifyAuthURL = prepareSpotifyLoginUrl(redirectUrl)
  const spotifyData = await verifySpotifyToken()
  if (spotifyData) {
    console.log('refreshMySpotifyToken')
    return spotifyData
  }
  window.open(spotifyAuthURL, '_self')
  return null
}

const refreshAppleMusicToken = async () => {
  await window.handleAppleMusicLogout()
  const appleMusicToken = await window.handleAppleMusicLogin()
  console.log('refreshAppleMusicToken', appleMusicToken)
  const success = await updateUser({
    appleMusicToken
  })
  console.log('refreshAppleMusicToken', success)
  return { appleMusicToken }
}

const refreshTokens = async (provider, status, redirectUrl) => {
  if (provider === 'spotify' && status === 401) {
    return await refreshMySpotifyToken(redirectUrl)
  }
  if (
    provider === 'apple_music' &&
    (status === 403 || status === 401 || status === 504)
  ) {
    return await refreshAppleMusicToken()
  }
  return null
}

const getProviderToken = async (provider, redirectUrl) => {
  try {
    console.log('getProviderToken ->', provider)
    const profileSnap = !!auth.currentUser
      ? (await getProfile())?.val() ?? {}
      : getTempProfile() ?? {}
    const { accessToken, appleMusicToken, refreshToken, spotifyID } =
      profileSnap
    if (provider === 'spotify') {
      if (accessToken) {
        try {
          // Validate User Spotify token is still working
          console.log('getProviderToken getSpotifyRecentlyPlayed')
          await getSpotifyRecentlyPlayed(accessToken || '1', 1)
          console.log('getProviderToken: spotifyToken', accessToken)
          return { accessToken, spotifyID }
        } catch (e) {
          // Refresh User Spotify token
          const spotifyData = await refreshSpotifyToken(refreshToken)
          console.log('refreshSpotifyToken:', spotifyData.accessToken)
          return !!spotifyData ? spotifyData : {}
        }
      } else {
        // Authenticate to Spotify
        const spotifyAuthURL = prepareSpotifyLoginUrl(redirectUrl)
        window.open(spotifyAuthURL, '_self')
        return {}
      }
    } else if (provider === 'apple_music') {
      const newAppleToken = await window.handleAppleMusicLogin()
      console.log(
        'getProviderToken: appleMusicToken',
        newAppleToken,
        appleMusicToken
      )
      if (appleMusicToken != newAppleToken) {
        await updateProfile({
          appleMusicToken: newAppleToken
        })
      }
      if (accessToken) {
        try {
          // Validate User Spotify token is still working
          console.log('getProviderToken getSpotifyRecentlyPlayed')
          await getSpotifyRecentlyPlayed(accessToken || '1', 1)
          console.log('getProviderToken: spotifyToken', accessToken)
          return { accessToken, spotifyID, appleMusicToken: newAppleToken }
        } catch (e) {
          // Refresh User Spotify token
          const spotifyData = await refreshSpotifyToken(refreshToken)
          console.log('refreshSpotifyToken:', spotifyData.accessToken)
          return spotifyData
            ? {
                accessToken: spotifyData.accessToken,
                spotifyID: spotifyData.spotifyID,
                appleMusicToken: newAppleToken
              }
            : { appleMusicToken: newAppleToken }
        }
      } else {
        const token = await getSettingsToken()
        return {
          accessToken: token.accessToken,
          spotifyID: token.spotifyID,
          appleMusicToken: newAppleToken
        }
      }
    }
  } catch (e) {
    console.log('e', e)
    return await refreshTokens(provider, e?.response?.status, redirectUrl)
  }
}

const getHostToken = async (hostInfo) => {
  const { accessToken, refreshToken, spotifyID } = hostInfo
  console.log('getHostToken ->', hostInfo)
  if (accessToken && refreshToken) {
    try {
      // Validate User Spotify token is still working
      console.log('getHostToken getSpotifyRecentlyPlayed')
      await getSpotifyRecentlyPlayed(accessToken || '1', 1)
      console.log('getHostToken: spotifyToken', accessToken)
      return { accessToken, spotifyID }
    } catch (e) {
      // Refresh User Spotify token
      const spotifyData = await refreshOtherSpotifyToken(refreshToken)
      console.log(
        'getHostToken: refreshOtherSpotifyToken:',
        spotifyData.accessToken
      )
      return !!spotifyData ? spotifyData : {}
    }
  } else {
    return {}
  }
}

const getSettingsToken = async () => {
  const settingsSnap = await getSettings()
  const { spotifyToken, spotifyRefreshToken, spotifyID } =
    settingsSnap?.val() || {}

  try {
    // Validate Settings Spotify token is still working
    console.log('getSettingsToken getSpotifyRecentlyPlayed')
    await getSpotifyRecentlyPlayed(spotifyToken, 1)
    return { accessToken: spotifyToken, spotifyID }
  } catch (e) {
    console.log('e', e)
    // Refresh Settings Spotify token
    const updatedToken = await refreshOtherSpotifyToken(spotifyRefreshToken)
    console.log('getSettingsTokens', updatedToken)
    await updateSettings({
      spotifyToken: updatedToken.accessToken,
      spotifyID: updatedToken.spotifyID
    })
    return updatedToken
  }
}

const getTokens = async (provider, redirectUrl) => {
  if (provider === 'guest') {
    return await getSettingsToken()
  } else {
    return await getProviderToken(provider, redirectUrl)
  }
}

const setCanvaUser = async (uid, canva_user_token, state, nonce, toast) => {
  let url = null
  let error = null
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/authentication/user`,
      JSON.stringify({
        uid,
        canva_user_token,
        state,
        nonce
      }),
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      }
    )
    url = response.data?.redirectUrl || null
  } catch (e) {
    console.log(e)
    error =
      !!e.response?.data && typeof e.response?.data === 'string'
        ? e.response?.data
        : e.response?.data?.error || e.message || 'Bad Request'
  }
  if (url) {
    window.location.replace(url)
  } else if (error) {
    toast({
      title: error,
      position: 'top',
      status: 'error',
      isClosable: true
    })
  }
}

export {
  isActive,
  minLimitSongs,
  maxLimitSongs,
  manhattanDistance,
  featureScaling,
  getHostToken,
  getFeatureMeans,
  getUniqueValues,
  refreshTokens,
  getTokens,
  setCanvaUser
}
