import React, {
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { PlusIcon, RefreshIcon, SearchIcon } from '../../../../assets/icons'
import ClipLoader from 'react-spinners/ClipLoader'
import MusicCard from '../../../../components/EventDetails/MusicCard/MusicCard'
import Button from '../../../../components/Button/Button'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getTokens, maxLimitSongs } from '../../../../services/helpers'
import {
  getSpotifyRecommendations,
  searchSpotify
} from '../../../../services/Spotify'
import { searchAppleMusic } from '../../../../services/AppleMusic'
import { Container, Flex, useToast } from '@chakra-ui/react'
import SelectedModal from '../../../../components/SelectedModal'
import TracksWErrorModal from '../../../../components/TracksWErrorModal'
import SimilarSongsModal from '../../../../components/SimilarSongsModal'
import { get, ref } from 'firebase/database'
import { analytics, database, logEvent } from '../../../../firebase'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  fetchLibrary,
  fetchRecentTracks,
  fetchTopTracks
} from '../../../../services/Circle/user'
import { useObject } from 'react-firebase-hooks/database'
import Input from '../../../../components/Input/Input'
import { useRef } from 'react'
import { addUserToMembers } from '../../../../services/Circle'

let TIMEOUT_ID = null

const schema = yup
  .object({
    search: yup.string().notRequired(),
    filter: yup.string().notRequired()
  })
  .required()

const RSVPSelectSongsStep = ({
  circleCode,
  circleInfo,
  rsvpId,
  provider,
  option
}) => {
  const [searchResults, setSearchResults] = useState([])
  const [popUp, setPopUp] = useState(null)
  const [fullPlaylist, setFullPlaylist] = useState([])
  const [searchText, setSearchText] = useState([])
  const [selected, setSelected] = useState([])
  const [tracksWError, setTracksWError] = useState([])
  const [trackWithError, setTrackWithError] = useState(null)
  const fetchingRef = useRef(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const hasResponse = queryParams.get('hasResponse') === 'true'
  const [playlist, setPlaylist] = useState([])

  const toast = useToast()
  const inputRef = createRef()
  const navigate = useNavigate()

  const [memberSnap, memberLoading] = useObject(
    ref(
      database,
      `circles/${circleCode || '1'}/members/${rsvpId || '1'}/userInfo`
    )
  )
  const [playlistSnap, playlistLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/playlist`)
  )
  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode}/info`)
  )
  const [prevSelectedSongsSnap, prevSelectedSongsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/members/${rsvpId}/selected`)
  )
  const [latestTracksSnap, latestTracksLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/latestTracks`)
  )
  const memberInfo = memberSnap?.val()
  const playlistInfo = playlistSnap?.val()
  const eventInfo = eventSnap?.val()
  const hasRequestList = !!eventInfo?.extraCollab ?? false
  const prevSelectedSongs = useMemo(
    () => prevSelectedSongsSnap?.val() || [],
    [prevSelectedSongsSnap]
  )
  const latestTracks = useMemo(
    () => latestTracksSnap?.val() || [],
    [latestTracksSnap]
  )
  const maxLimit = maxLimitSongs(memberInfo?.order)
  const minLimit = 0 // minLimitSongs(memberInfo?.order)
  const [pageSize, setPageSize] = useState(15)
  // const limitReached =
  //   maxLimit !== -1 && (prevSelectedSongs || []).length >= maxLimit

  const filterOptions = useMemo(
    () =>
      [
        latestTracks.length > 0
          ? {
              value: 'recommendations',
              label: 'Coteri suggestions'
            }
          : null,
        {
          value: '4zAkxb3J6a5cM6KAP0nVGm',
          label: 'Popular Today'
        },
        {
          value: '7ejtgMFVvgow5Pk2Zq3KeG',
          label: 'Popular All Time'
        },
        {
          value: '6xMqitAbn0EF8pQs5UVUcZ',
          label: '2010s'
        },
        {
          value: '6oVSYR8QHmeN2tWSFqRm6o',
          label: '2000s'
        },
        {
          value: '16Zwb7N1OCH4JqzOAL9FLx',
          label: '1990s'
        },
        {
          value: '5pD9zzBjztHtTrABt7Abcb',
          label: '1980s'
        },
        {
          value: '3gHktisWbRE7EulXb0VCVh',
          label: '1970s'
        },
        {
          value: '0yN4owB2wQJ41oZFo9u8X3',
          label: '1960s'
        }
      ].filter((x) => !!x),
    [latestTracks]
  )

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [search, filter] = form.watch(['search', 'filter'])

  const searchProvider = useCallback(async () => {
    console.log('searchProvider', option, provider)
    if (!provider || !option) return
    const searchText = search

    let results = []

    try {
      const token = await getTokens(option, `streaming-${circleCode}`)
      if (!token.appleMusicToken && !token.accessToken) return

      if (provider === 'spotify') {
        results = await searchSpotify(token.accessToken, searchText)
      } else {
        results = await searchAppleMusic(
          token.appleMusicToken,
          'us',
          searchText,
          token.accessToken
        )
      }

      setSearchResults(
        results.filter(
          (value, index, self) =>
            index === self.findIndex((x) => x.isrc === value.isrc)
        )
      )
    } catch (e) {
      toast({
        title: e.message || 'Error while searching track',
        position: 'top',
        status: 'error'
      })
    }
  }, [provider, option, search, toast, circleCode])

  const debouncedSearch = (searchText) => {
    clearTimeout(TIMEOUT_ID)
    TIMEOUT_ID = window.setTimeout(
      () => searchProvider(searchText).then(() => null),
      500
    )
  }

  const searchTracks = (text, playlist) => {
    const searchText = text.toLowerCase()
    setSearchText(searchText)
    setPlaylist(
      playlist.filter(
        (x) =>
          x.name?.toLowerCase().search(searchText) > -1 ||
          x.name?.toLowerCase().includes(searchText) ||
          x.artistName?.toLowerCase().search(searchText) > -1 ||
          x.artistName?.toLowerCase().includes(searchText) ||
          x.albumName?.toLowerCase().search(searchText) > -1 ||
          x.albumName?.toLowerCase().includes(searchText) ||
          (searchText.length === 4 &&
            x.releaseDate?.toLowerCase().startsWith(searchText)) ||
          x.genres?.find((genre) => genre.toLowerCase().search(searchText) > -1)
      )
    )
    debouncedSearch(searchText)
  }

  const onSearch = () => {
    if (!searchText || searchText === '') {
      setPlaylist(fullPlaylist)
    } else {
      inputRef.current.scrollIntoView({ block: 'start' })
      searchTracks(searchText, fullPlaylist)
    }
  }

  const searchSimilars = (item) => {
    setTrackWithError(item)
  }

  const toggleSelectSong = (item) => {
    const _selected = selected.concat([])
    const selectedIdx = _selected?.findIndex((x) => x.isrc === item.isrc)
    if (selectedIdx > -1) {
      _selected.splice(selectedIdx, 1)
    } else {
      _selected.push(item)
    }
    if (_selected.length === 0) {
      setPopUp(false)
    }
    setSelected(_selected)
  }

  const replaceSong = (item, replace) => {
    const _tracks = tracksWError.concat([])
    const trackIdx = _tracks.findIndex((x) => x.isrc === item.isrc)
    if (trackIdx > -1) {
      _tracks[trackIdx] = { ...replace, original: item.original || item }
      setTracksWError(_tracks)
    }
    setTrackWithError(null)
  }

  const handleSelect = () => {
    logEvent(analytics, 'songs_selected', {
      source: 'SELECT_SONGS_EVENT'
    })
    navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
  }

  const handlePreviousStep = () => {
    if (hasResponse || hasRequestList) {
      navigate(-1)
    } else {
      navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
    }
  }

  const handleConfirm = () => {
    if (selected.length === 0) {
      handleSelect()
    } else {
      setPopUp('selected')
    }
  }

  const handleGuest = useCallback(async () => {
    console.log('handleGuest')
    try {
      logEvent(analytics, 'provider_connected', {
        source: 'STREAMING',
        provider: 'GUEST'
      })

      await addUserToMembers(
        rsvpId,
        {
          uid: rsvpId,
          option: 'guest',
          provider: 'spotify'
        },
        circleCode,
        [],
        [],
        []
      )

      return true
    } catch (err) {
      console.log('fail', err)
      toast({
        status: 'error',
        title: err.message || 'Error while connecting with spotify',
        position: 'top'
      })
      return false
    }
  }, [circleCode, rsvpId, toast])

  const handleOption = useCallback(
    async (val) => {
      console.log('handleOption', val, option, provider)
      if (!provider || !option) return
      fetchingRef.current = true
      try {
        let tracks = []
        setFullPlaylist([])

        const token = await getTokens(option, `streaming-${circleCode}`)
        if (!token.appleMusicToken && !token.accessToken) {
          toast({
            status: 'error',
            title: 'Error while refreshing tokens',
            position: 'top'
          })
          fetchingRef.current = false
          return
        }

        switch (val) {
          case 'recommendations':
            if (latestTracks.length > 0) {
              tracks = await getSpotifyRecommendations(
                token.accessToken,
                latestTracks.slice(0, 5),
                15
              )
            }
            break
          case 'recent':
            tracks = await fetchRecentTracks(provider, {
              spotifyToken: token.accessToken,
              appleMusicToken: token.appleMusicToken
            })
            break
          case 'top':
            tracks = await fetchTopTracks(provider, {
              spotifyToken: token.accessToken,
              appleMusicToken: token.appleMusicToken
            })
            break
          case 'library':
            tracks = await fetchLibrary(provider, {
              spotifyToken: token.accessToken,
              appleMusicToken: token.appleMusicToken
            })
            break
          default:
            const playlistTracksSnap = await get(
              ref(database, `coteriPlaylists/${val}/tracks`)
            )
            tracks = playlistTracksSnap.val() || []
            break
        }

        setFullPlaylist(tracks)
        // handleUpdateDateLimits(tracks)
        fetchingRef.current = false
      } catch (e) {
        fetchingRef.current = false
        toast({
          status: 'error',
          title: e.message || 'Error while fetching tracks',
          position: 'top'
        })
      }
    },
    [circleCode, provider, option, toast, latestTracks]
  )

  const handleLoadMoreSongs = () => {
    const playlist = search ? searchResults : fullPlaylist

    if (pageSize >= playlist.length) {
      toast({
        status: 'info',
        title: 'No more songs to load',
        position: 'top'
      })
      return
    }

    setPageSize(pageSize + 15)
  }

  useEffect(() => {
    if (search) {
      searchProvider()
    } else {
      setSearchResults([])
    }
  }, [search, searchProvider])

  useEffect(() => {
    if (!!option && !latestTracksLoading && !fetchingRef.current) {
      setPageSize(15)
      if (!!filter?.value) {
        handleOption(filter.value).then(() => null)
      } else {
        form.setValue('filter', filterOptions[0])
      }
    }
  }, [
    handleOption,
    provider,
    option,
    filter,
    latestTracksLoading,
    filterOptions,
    form
  ])

  useEffect(() => {
    if (!prevSelectedSongsLoading && !memberLoading && !playlistLoading) {
      if (!memberInfo) {
        const guestAdded = handleGuest()
        if (!guestAdded) {
          navigate(`/404?from=${window.location.pathname}`, { replace: true })
        }
      }
    }
  }, [
    circleCode,
    rsvpId,
    prevSelectedSongsLoading,
    memberLoading,
    playlistLoading,
    memberInfo,
    selected,
    navigate,
    handleGuest
  ])

  return (
    <>
      <div className={`mt-[20px] flex flex-1 flex-col visible`}>
        <h2 className="text-[24px]">
          Add music to the playlist for this event.
        </h2>

        <span className="text-[14px] font-medium my-3">
          Click
          <span
            className={`w-[18px] h-[18px] inline-flex border-terciary border-1 items-center justify-center rounded-full mx-1`}
            style={{ verticalAlign: -7 }}
          >
            <PlusIcon fill={'#120a31'} />
          </span>
          to add songs to your cart, then click “Continue” when you’re ready to
          add them to the playlist.
        </span>

        {!!filter?.value ? (
          <div className="flex items-center mt-[15px] sm:flex-row flex-col gap-4">
            <Input
              register={form.register('filter')}
              type={'select'}
              placeholder="Select filter"
              options={filterOptions}
              form={form}
            />
            <Input
              register={form.register('search')}
              form={form}
              type="icon"
              placeholder="Search for an artist or song..."
              className="w-full"
              iconRight={<SearchIcon fill="#000" />}
            />
          </div>
        ) : null}

        {filter?.value === 'recommendations' ? (
          <button
            className="flex items-center cursor-pointer mt-[20px]"
            onClick={() => handleOption('recommendations').then(() => null)}
          >
            <RefreshIcon fill={'#000'} width={24} height={24} />
            <span className="ml-[10px] text-secondary">
              Generate new list of song suggestions
            </span>
          </button>
        ) : null}

        <div className="flex flex-1 mb-28">
          {memberLoading ||
          prevSelectedSongsLoading ||
          latestTracksLoading ||
          fetchingRef.current ? (
            <div className="flex flex-1 items-center justify-center">
              <ClipLoader color="#5B4ABC" />
            </div>
          ) : (
            <div className="flex-col w-full">
              <div className="mt-[15px] w-full">
                {[...(search ? searchResults : fullPlaylist)]
                  .splice(0, pageSize)
                  .map((music, index) => {
                    const found = playlistInfo?.find(
                      (m) => m.isrc === music.isrc
                    )
                    const isSelected = [
                      ...selected,
                      ...prevSelectedSongs
                    ]?.find((selectedMusic) => {
                      return music.id === selectedMusic.id
                    })

                    return (
                      <MusicCard
                        key={index}
                        music={music}
                        className="mb-3"
                        canPlay={true}
                        onClick={() => toggleSelectSong(music)}
                        checked={found ? true : isSelected}
                        provider={provider}
                        disabled={found}
                      />
                    )
                  })}
              </div>
              {pageSize >=
              (search ? searchResults : fullPlaylist).length ? null : (
                <div className="w-full flex items-center justify-center">
                  <Button
                    text={'Load more songs'}
                    size={'medium'}
                    onClick={handleLoadMoreSongs}
                    className={'max-w-[250px]'}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Flex bottom="0" left="0" right="0" position="fixed" zIndex="2">
        <Container
          bg="white"
          w="100%"
          maxW="800px"
          borderTopWidth="1px"
          borderColor="#EEE"
          py="16px"
          px={{ base: '16px', md: '36px' }}
          boxShadow={{ base: 'none', md: '2px 4px 12px rgb(0 0 0 / 8%)' }}
        >
          <div className="flex flex-row justify-between items-end">
            <Button
              type="text"
              disabled={memberLoading || prevSelectedSongsLoading}
              text={hasResponse || hasRequestList ? 'Back' : 'Skip'}
              onClick={handlePreviousStep}
            />
            <Button
              disabled={memberLoading || prevSelectedSongsLoading}
              text={'View selections'}
              onClick={handleConfirm}
              iconRight={
                <span
                  className="ml-2 inline-flex items-center justify-center rounded-full bg-off-white"
                  style={{ width: '28px', height: '28px' }}
                >
                  <span className="text-secondary">
                    {selected?.length > 9 ? '9+' : selected?.length}
                  </span>
                </span>
              }
            />
          </div>
        </Container>
      </Flex>
      {!!memberInfo ? (
        <SelectedModal
          uid={rsvpId}
          isGuest={true}
          hostID={circleInfo?.hostID}
          popUp={popUp}
          circleCode={circleCode}
          memberInfo={memberInfo}
          setPopUp={setPopUp}
          provider={provider}
          option={option}
          selected={selected}
          minLimit={minLimit}
          maxLimit={maxLimit}
          connections={[]}
          setTracksWError={setTracksWError}
          prevSelectedSongs={prevSelectedSongs}
          toggleSelectSong={toggleSelectSong}
          onSelect={handleSelect}
        />
      ) : null}
      {!!memberInfo ? (
        <TracksWErrorModal
          uid={rsvpId}
          selected={selected}
          tracksWError={tracksWError}
          setTracksWError={setTracksWError}
          setTrackWithError={setTrackWithError}
          provider={provider}
          option={option}
          circleCode={circleCode}
          memberInfo={memberInfo}
          minLimit={minLimit}
          maxLimit={maxLimit}
          connections={[]}
          searchSimilars={searchSimilars}
          onSelect={handleSelect}
        />
      ) : null}
      {!!memberInfo ? (
        <SimilarSongsModal
          track={trackWithError}
          circleCode={circleCode}
          memberInfo={memberInfo}
          circleInfo={circleInfo}
          provider={provider}
          option={option}
          maxLimit={maxLimit}
          selected={selected}
          prevSelectedSongs={prevSelectedSongs}
          connections={[]}
          replaceSong={replaceSong}
          setTrackWithError={setTrackWithError}
        />
      ) : null}
    </>
  )
}

export default memo(RSVPSelectSongsStep)
