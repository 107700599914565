import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Base from './base'
import { Flex, Heading, Text } from '@chakra-ui/react'
import { getEventData } from '../services/database'

const Thankyou = () => {
  const navigate = useNavigate()

  const eventInfo = getEventData()
  const circleCode = eventInfo?.circleCode || null

  useEffect(() => {
    if (!!circleCode) {
      setTimeout(() => {
        navigate(`/event/${circleCode}/rsvps`, { replace: true })
      }, 2000)
    } else {
      navigate('/', { replace: true })
    }
  }, [circleCode, navigate])

  if (!circleCode) {
    return <Base hideMenu={true} />
  }

  return (
    <Base hideMenu={true}>
      <Flex align="center">
        <Heading
          as="h4"
          flex="1"
          color="primary"
          mt="30px"
          mb="2px"
          textAlign="center"
          fontSize={{ base: '22px', md: '30px' }}
        >
          Thanks for your payment
        </Heading>
      </Flex>
      <Text
        mt="4px"
        color="gray"
        fontSize="20px"
        fontWeight="400"
        textAlign="center"
      >
        You will be redirected to your event automatically...
      </Text>
    </Base>
  )
}

export default Thankyou
