import React from 'react'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'
import { ReactComponent as CloseSVG } from '../assets/icons/close.svg'
import { default as CustomButton } from './Button/Button'

const InvitesModal = ({ type, popUp, setResponse, handleUpgrade }) => {
  return (
    <Modal
      isOpen={popUp === 'invites'}
      onClose={() => setResponse(false)}
      isCentered
      size="xl"
    >
      <ModalOverlay />
      <ModalContent mx="20px">
        <ModalHeader color="primary" fontWeight="900">
          <Flex>
            <Flex flex="1" alignItems="center">
              <Text fontSize="20px" fontWeight="900" color="primary">
                Please note
              </Text>
            </Flex>
            <Button
              variant="ghost"
              bg="transparent"
              onClick={() => setResponse(false)}
            >
              <Flex flexDirection="row" alignItems="center">
                Cancel
                <CloseSVG style={{ width: 14, height: 14, marginLeft: 10 }} />
              </Flex>
            </Button>
          </Flex>
        </ModalHeader>
        {type === 'event' ? (
          <ModalBody px="20px" overflowY="scroll" maxH="70vh">
            <Text mb="10px" fontWeight="500">
              Anyone with the invite link can respond to your invite. These
              responses will count towards the invite limit.
            </Text>
            <Text mb="10px" fontWeight="500">
              If you hit the{' '}
              <span className="font-bold">
                35-guest limit with the free package
              </span>
              , upgrading to premium is necessary to continue to collect RSVPs.
            </Text>
            <Text fontWeight="500">
              You may turn off the invite link at any time.
            </Text>
          </ModalBody>
        ) : (
          <ModalBody px="20px" overflowY="scroll" maxH="70vh">
            <Text mb="10px" fontWeight="500">
              Anyone with the invite link can contribute songs to your playlist.
            </Text>
          </ModalBody>
        )}

        <div className="flex items-center justify-between px-4 py-4">
          <CustomButton text={'Upgrade package'} onClick={handleUpgrade} />

          <Button
            h="46px"
            px="20px"
            py="24px"
            borderRadius="25px"
            colorScheme="whiteAlpha"
            borderWidth="1px"
            borderColor="pink.400"
            _hover={{ backgroundColor: 'gray.50' }}
            onClick={() => setResponse(true)}
          >
            <Text fontSize="16px" fontWeight="600" color="pink.400">
              Okay, I understand
            </Text>
          </Button>
        </div>
      </ModalContent>
    </Modal>
  )
}

export default InvitesModal
