import { Tooltip } from '@chakra-ui/react'
import React, { useState, useRef } from 'react'
import { useWatch } from 'react-hook-form'

const NewEventNote = ({ form }) => {
  const [noteFocused, setNoteFocused] = useState(false)
  const noteInputRef = useRef()

  const fieldNote = useWatch({
    name: 'note',
    control: form.control
  })

  const handleFocusNote = () => {
    setNoteFocused(true)
    setTimeout(() => {
      noteInputRef.current?.focus()
    }, 500);
  }

  const handleDeleteNote = () => {
    form.setValue('note', '')
    setNoteFocused(false)
    setTimeout(() => {
      noteInputRef.current?.blur()
    }, 500);
  }

  return (
    <div>
      <Tooltip label='Type your event note'>
        <div className={`bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md border-1 ${(!fieldNote && !noteFocused) ? '' : 'hidden'}`} onClick={handleFocusNote}>
          <span className='ml-[8px] text-primary font-bold hover:underline cursor-pointer '>+ Add event description, or a note for your guests</span>
        </div>
      </Tooltip>

      <div className={`${(fieldNote || noteFocused) ? '' : 'hidden'}`}>
        <div className='flex items-center justify-between mt-[12px]'>
          <span>Additional event information</span>
          <span className='text-red-500 text-[14px] cursor-pointer' onClick={handleDeleteNote}>delete</span>
        </div>
        <div className='w-full h-[2px] bg-secondary' />
        <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md border-1'>
          <textarea
            className="bg-transparent w-full outline-none"
            autoComplete="off"
            {...form.register('note')}
            onFocus={() => setNoteFocused(true)}
            onBlur={() => setNoteFocused(false)}
            ref={(e) => {
              form.register('note')?.ref(e)
              noteInputRef.current = e
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default NewEventNote
