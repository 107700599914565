import React, { useContext, useEffect, useRef, useState } from 'react'
import { getTokens } from '../services/helpers'

const PlayingContext = React.createContext({
  track: null
})

const PlayingProvider = ({ children }) => {
  const [track, setTrack] = useState(null)
  const [accessToken, setAccessToken] = useState(null)
  const intervalId = useRef()

  console.log('track', track)

  const state = {
    track,
    setTrack
  }

  const handlePlay = async (item) => {
    console.log('handlePlay', item)
    const tracks = [`spotify:track:${item.spotifyID || item.id}`]
    console.log('startPlayingMusic', tracks)
    if (!accessToken) {
      console.log('could not find accessToken')
      refreshTokenThenPlay(tracks).then(() => null)
      return
    }
    try {
      await window.startPlayingMusic('spotify', tracks, accessToken)
    } catch (e) {
      console.log('handlePlay', e)
      refreshTokenThenPlay(tracks).then(() => null)
    }
  }

  const refreshTokenThenPlay = async (tracks) => {
    console.log('refreshTokenThenPlay', tracks)
    try {
      // Get access token
      const token = await getTokens('guest')
      if (!token || !token.accessToken) {
        console.log('could not find tokens')
        return
      }
      await window.startPlayingMusic('spotify', tracks, token.accessToken)
    } catch (e) {
      console.log('refreshTokenThenPlay', e)
    }
  }

  useEffect(() => {
    window.pauseMusic()
    if (!!track) {
      console.log('useEffect', track)
      handlePlay(track).then(() => null)
      intervalId.current = setInterval(() => {
        window.pauseMusic()
        setTrack(null)
      }, 10000)
      return () =>
        intervalId.current
          ? clearInterval(intervalId.current)
          : window.pauseMusic()
    }
  }, [track, setTrack])

  useEffect(() => {
    // Get settings access token
    getTokens('guest').then((token) => {
      if (!!token && !!token.accessToken) {
        setAccessToken(token.accessToken)
        window.loadSpotifyPlayer(token.accessToken)
      } else {
        console.log('could not find tokens')
      }
    })
  }, [])

  return (
    <PlayingContext.Provider value={state}>{children}</PlayingContext.Provider>
  )
}

export const usePlaying = () => useContext(PlayingContext)

export default PlayingProvider
