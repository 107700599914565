import * as React from 'react'

function Coteri({ ...props }) {
  return (
    <svg
      width={19}
      height={19}
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.5 0a9.5 9.5 0 100 19 9.5 9.5 0 000-19zm0 14.329a2.014 2.014 0 110-4.028 2.014 2.014 0 010 4.027zm0-5.629a2.014 2.014 0 110-4.028 2.014 2.014 0 010 4.028z"
        fill="#DD94F7"
      />
    </svg>
  )
}

export default Coteri
