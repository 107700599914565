import { refreshSpotifyToken } from '../Spotify'
import { auth, getProfile, setProfile, updateProfile } from '../../firebase'
import { getTempProfile } from '../database'

const location = typeof window != null ? window.location.origin : ''
const requiredScopes =
  'streaming+user-read-recently-played+user-top-read+user-library-read+ugc-image-upload+playlist-modify-private'
const Auth_URL =
  'https://accounts.spotify.com/authorize?' +
  'response_type=code' +
  '&client_id=' +
  process.env.REACT_APP_SPOTIFY_CLIENT_ID +
  '&scope=' +
  requiredScopes +
  `&redirect_uri=${location}`

const prepareSpotifyLoginUrl = (extra) =>
  `${Auth_URL}/callback${extra ? `&state=${extra}` : ''}`

const verifySpotifyToken = async (url) => {
  try {
    const profile = !!auth.currentUser
      ? (await getProfile())?.val() ?? {}
      : getTempProfile() ?? {}
    console.log('verifySpotifyToken profile', profile, url)
    if (!profile || Object.keys(profile).length === 0 || !profile?.refreshToken)
      return null
    const spotifyData = refreshSpotifyToken(profile.refreshToken)
    if (spotifyData) {
      return spotifyData
    } else {
      return null
    }
  } catch (error) {
    console.log(error)
    return null
  }
}

const updateUser = async (userData) => {
  try {
    if (!userData || Object.keys(userData).length === 0) return false

    const profileSnap = await getProfile()
    const profile = profileSnap.val()
    const data = { ...profile, ...userData }

    if (!profileSnap.exists()) {
      data.email = auth.currentUser.email
      await setProfile(data)
      return true
    }

    await updateProfile(data)
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export { verifySpotifyToken, updateUser, prepareSpotifyLoginUrl }
