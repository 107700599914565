import React, { memo, useMemo, useState } from 'react'
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
  NoteIcon,
  PeopleOutlineIcon
} from '../assets/icons'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import NoteModal from './EventDetails/NoteModal/NoteModal'
import DateModal from './EventDetails/DateModal/DateModal'
import TimeModal from './EventDetails/TimeModal/TimeModal'
import HostNameModal from './EventDetails/HostNameModal/HostNameModal'
import LocationModal from './EventDetails/LocationModal/LocationModal'
import EditEvent from './EventDetails/EditEvent/EditEvent'
import CopyLink from './CopyLink'
import Toggle from './Toggle/Toggle'
import { ref, update } from 'firebase/database'
import { database } from '../firebase'
import DisableInviteLinkModal from './EventDetails/DisableInviteLinkModal/DisableInviteLinkModal'
import EnableInviteLinkModal from './EventDetails/EnableInviteLinkModal/EnableInviteLinkModal'
import Button from './Button/Button'
import EventDetailsViewInviteModal from './EventDetailsView/EventDetailsViewInviteModal/EventDetailsViewInviteModal'

const EventDetailsView = ({ circleCode, eventInfo, guestsInfo, user }) => {
  const isGuest = eventInfo.hostID !== user?.uid

  const [noteModal, setNoteModal] = useState(false)
  const [dateModal, setDateModal] = useState(false)
  const [timeModal, setTimeModal] = useState(false)
  const [locationModal, setLocationModal] = useState(false)
  const [editEventModal, setEditEventModal] = useState(false)
  const [hostNameModal, setHostNameModal] = useState(false)
  const [toggled, setToggled] = useState(eventInfo?.allowGuests)
  const [enableInviteLinkModal, setEnableInviteLinkModal] = useState(false)
  const [disableInviteLinkModal, setDisableInviteLinkModal] = useState(false)
  const [inviteModal, setInviteModal] = useState(false)
  const navigate = useNavigate()

  const timeOfEvent = useMemo(() => {
    if (!eventInfo) return

    if (!eventInfo.time && !eventInfo.timezone) return

    const timeWithAmPm = `${eventInfo.time} ${eventInfo.amOrPm}`

    const formattedTime = moment(timeWithAmPm, 'hh:mm A').format(
      `hh:mm A [${eventInfo.timezone}]`
    )

    return formattedTime
  }, [eventInfo])

  const handleDisableInviteLinkModalOpen = () => {
    setDisableInviteLinkModal(true)
  }

  const handleEnableInviteLinkModalConfirm = async () => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), {
        allowGuests: true
      })

      setToggled(true)

      setEnableInviteLinkModal(false)
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  const handleEnableInviteLinkModalClose = () => {
    setEnableInviteLinkModal(false)
  }

  const handleDisableInviteLinkModalClose = () => {
    setDisableInviteLinkModal(false)
  }

  const handleDisableInviteLinkModalConfirm = async () => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), {
        allowGuests: false
      })

      setToggled(false)

      setDisableInviteLinkModal(false)
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  const handleDeleteNote = () => {
    try {
      update(ref(database, `circles/${circleCode}/info`), {
        noteForGuests: ''
      })
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  const handleToggle = async () => {
    if (!toggled) {
      setEnableInviteLinkModal(true)
    } else {
      handleDisableInviteLinkModalOpen()
    }
  }

  if (!eventInfo) {
    return null
  }

  return (
    <div className="flex flex-col  flex-1">
      <div>
        <div className="flex flex-row items-center justify-between mb-[15px]">
          <span className="mr-[35px] font-medium text-[24px]">
            {eventInfo.name}
          </span>
          <span
            className="text-primary cursor-pointer font-bold hover:underline min-w-[80px]"
            onClick={() => navigate(`/event/${circleCode}/edit`)}
          >
            Edit event
          </span>
        </div>
      </div>

      <div>
        <span className="mr-[35px] font-medium">
          Hosted by{' '}
          {eventInfo.hostName ? (
            eventInfo.hostName
          ) : (
            <span
              className=" text-primary cursor-pointer font-semibold hover:underline"
              onClick={() => setHostNameModal(true)}
            >
              Add host name
            </span>
          )}
        </span>

        <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] gap-3 rounded-md'>
          <div className="flex flex-row items-center justify-start">
            <CalendarIcon />
            {eventInfo.date ? (
              <span className="ml-[8px]">
                {moment(eventInfo.date).format('MMMM DD, YYYY')}
              </span>
            ) : (
              <span
                className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => setDateModal(true)}
              >
                Add date
              </span>
            )}
          </div>
          <div className="flex flex-row items-center justify-start">
            <ClockIcon />
            {eventInfo.time ? (
              <span className="ml-[8px]">{timeOfEvent}</span>
            ) : (
              <span
                className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => setTimeModal(true)}
              >
                Add time
              </span>
            )}
          </div>
        </div>

        <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md'>
          <div className="flex flex-row items-center justify-start">
            <LocationIcon fill="#5b4abc" pinColor="#422d8f" />
            {eventInfo.address?.label ? (
              <div className="flex flex-col">
                <span className="ml-[8px]">
                  {eventInfo.address?.label}
                  {eventInfo.address?.complementaryAddress ? `, ${eventInfo.address?.complementaryAddress}` : ''}
                </span>
                <span className="ml-[8px]">{eventInfo.address?.label2}</span>
              </div>
            ) : (
              <span
                className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => setLocationModal(true)}
              >
                Add event location
              </span>
            )}
          </div>
        </div>

        <div className='bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md'>
          <div className="flex flex-row items-center justify-start">
            <PeopleOutlineIcon fill="#5b4abc" />
            <div>
              <span className="ml-[8px]">
                {!!guestsInfo ? Object.values(guestsInfo).length : 0} RSVPs •{' '}
              </span>
              <span
                className="text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => navigate(`/event/${circleCode}/rsvps`)}
              >
                View guests
              </span>
            </div>
          </div>
        </div>

        <div className='mt-[12px] w-full'>
          <div className="flex flex-row items-center justify-start">
            {eventInfo.noteForGuests ? (
              <div className='w-full'>
                <div className='flex items-center justify-between'>
                  <span>Additional event information</span>
                  <span className='text-red-500 text-[14px] cursor-pointer' onClick={handleDeleteNote}>delete</span>
                </div>
                <div className='w-full h-[2px] bg-secondary mb-2' />
                <div className='bg-off-white p-3 flex flex-col justify-center rounded-md cursor-pointer' onClick={() => setNoteModal(true)}>
                  <span>{eventInfo.noteForGuests}</span>
                </div>
              </div>
            ) : (
              <div className='bg-off-white p-3 flex flex-col justify-center rounded-md'>
                <span
                  className='ml-[8px] text-primary font-semibold hover:underline cursor-pointer'
                  onClick={() => setNoteModal(true)}
                >
                  + Add event description, or a note for your guests
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <div className="flex items-center justify-between">
          <Button text="Send invites" size={"medium"} onClick={() => setInviteModal(true)} className={'w-full'} />
        </div>
        {/* <div className="mt-4">
          <Button
            type="terciary"
            size="small"
            text={"Send text blast"}
            onClick={() => setTextBlastModal(true)}
          />
        </div> */}
      </div>

      <NoteModal
        isOpen={noteModal}
        handleOnClose={() => setNoteModal(false)}
        eventInfo={eventInfo}
      />

      <DateModal isOpen={dateModal} handleOnClose={() => setDateModal(false)} />

      <TimeModal isOpen={timeModal} handleOnClose={() => setTimeModal(false)} />

      <HostNameModal
        isOpen={hostNameModal}
        handleOnClose={() => setHostNameModal(false)}
        eventInfo={eventInfo}
      />

      <LocationModal
        isOpen={locationModal}
        handleOnClose={() => setLocationModal(false)}
      />

      <EventDetailsViewInviteModal
        isOpen={inviteModal}
        onClose={() => setInviteModal(false)}
        eventInfo={eventInfo}
        circleCode={circleCode}
      />

      <EditEvent
        isOpen={editEventModal}
        handleOnClose={() => setEditEventModal(false)}
        eventInfo={eventInfo}
        circleCode={circleCode}
      />

      <DisableInviteLinkModal
        onClose={handleDisableInviteLinkModalClose}
        isOpen={disableInviteLinkModal}
        onConfirm={handleDisableInviteLinkModalConfirm}
      />

      <EnableInviteLinkModal
        onClose={handleEnableInviteLinkModalClose}
        isOpen={enableInviteLinkModal}
        onConfirm={handleEnableInviteLinkModalConfirm}
      />
    </div >
  )
}

export default memo(EventDetailsView)
