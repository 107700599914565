import React, { memo } from 'react'
import { SpotifyWithTextIcon } from '../../../../assets/icons'
import Button from '../../../../components/Button/Button'
import MusicCard from '../../../../components/EventDetails/MusicCard/MusicCard'
import { useNavigate, useParams } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'
import BackgroundBlur from '../../../../components/EventDetails/BackgroundBlur/BackgroundBlur'
import { useObject } from 'react-firebase-hooks/database'
import { database, ref } from '../../../../firebase'

const MusicTab = ({ rsvpId, playlist, isToggled, circleInfo, alreadyHasResponse }) => {
  const navigate = useNavigate()
  const { circleCode } = useParams()
  const provider = circleInfo?.provider ?? 'spotify'
  const hasMusic = !!circleInfo?.musicCollab ?? false

  const [hostSnap, hostLoading] = useObject(
    !!circleInfo?.hostID ? ref(database, `users/${circleInfo.hostID}`) : null
  )
  const hostInfo = hostSnap?.val() ?? {}
  const canAddSongs =
    !!hostInfo.accessToken && !!hostInfo.refreshToken && !!hostInfo.spotifyID

  const handleAddMoreSongs = () => {
    navigate(
      !!rsvpId
        ? `/event/${circleCode}/rsvp/${rsvpId}/help?view=music&hasResponse=${alreadyHasResponse}`
        : `/event/${circleCode}/playlist`
    )
  }

  const handleViewPlaylist = () => {
    amplitude.track(
      'Playlist navigated to',
      {
        circleCode
      },
      {
        user_id: rsvpId
      }
    )
    if (provider === 'spotify') {
      window.open(
        `https://open.spotify.com/playlist/${circleInfo.playlistID}`,
        '_blank',
        'noopener,noreferrer'
      )
    } else {
      window.open(
        `https://music.apple.com/us/playlist/${circleInfo.name}/${circleInfo.playlistID}`,
        '_blank',
        'noopener,noreferrer'
      )
    }
  }

  return (
    <div className={`${isToggled ? 'mt-[10px] relative' : 'hidden'}`}>
      {/* {!hasMusic && (
        <BackgroundBlur
          onClick={handleAddMoreSongs}
          text={'Create music playlist'}
        />
      )} */}
      <div className={`${isToggled ? 'mt[10px]' : 'hidden'}`}>
        <div id="header" className="flex flex-col mt-[10px] sm:flex-row">
          <div>
            <h2 className="text-primary text-[34px]">Music playlist</h2>
            <span className='text-[14px]'>
              To start listening, click below to go to the playlist on your
              Spotify app.
            </span>
          </div>

          <div className="flex flex-col ml-[0px] items-center mt-[18px] md:mt-[0px] md:ml-[50px]">
            <Button
              type="terciary"
              size="extra-small"
              text="Add more songs"
              onClick={handleAddMoreSongs}
            />
            <Button
              type="terciary"
              size="extra-small"
              text={
                provider === 'spotify'
                  ? 'Listen on Spotify'
                  : 'Listen on Apple Music'
              }
              onClick={handleViewPlaylist}
              className="md:w-full mt-[30px] min-w-[180px]"
            />
          </div>
        </div>

        <div
          id="content"
          className="mt-[20px] mb-[10px] flex flex-row items-center"
        >
          <div className="flex flex-col items-center">
            <span className="text-placeholder text-[13px] mr-2">Available via</span>
          </div>
          <SpotifyWithTextIcon fill={'black'} width={80} height={30} />
        </div>

        {!playlist && (
          <div className="flex flex-1 items-center justify-center mt-[15px]">
            <span>No songs found</span>
          </div>
        )}
      </div>

      {playlist?.map((song, index) => (
        <MusicCard
          key={index}
          music={song}
          className="mb-3"
          checked
          provider={provider}
        />
      ))}
    </div>
  )
}

export default memo(MusicTab)
