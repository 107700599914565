import { SvgIconProps } from '@mui/material'
import * as React from 'react'

function NoResponse({ fill, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      {...props}
    >
      <g className="nc-icon-wrapper" fill={fill}>
        <path
          d="M20.5,3.515C15.813-1.172,8.215-1.172,3.529,3.515-1.157,8.201-1.157,15.799,3.529,20.485c4.686,4.686,12.284,4.686,16.971,0,4.667-4.694,4.667-12.276,0-16.971Zm-3.793,11.778c.391,.391,.391,1.023,0,1.414-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293l-3.293-3.293-3.293,3.293c-.195,.195-.451,.293-.707,.293s-.512-.098-.707-.293c-.391-.391-.391-1.023,0-1.414l3.293-3.293-3.293-3.293c-.391-.391-.391-1.023,0-1.414s1.023-.391,1.414,0l3.293,3.293,3.293-3.293c.391-.391,1.023-.391,1.414,0s.391,1.023,0,1.414l-3.293,3.293,3.293,3.293Z"
          fill={fill}
        ></path>
      </g>
    </svg>
  )
}

export default NoResponse
