import React, { useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import Base from './base'
import { Link } from 'react-router-dom'
import '../styles/privacy.css'
import { Helmet } from 'react-helmet'

const About = () => {
  const [showPopUp, setShowPopUp] = useState(null)

  return (
    <Base
      isPublic
      showPopUp={showPopUp}
      onChangePopUp={setShowPopUp}
      onSuccess={() => setShowPopUp(null)}
      onCloseLogin={() => setShowPopUp(null)}
      hideBg={true}
      bottomBar={false}
      allowFullHeight={true}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - About</title>
        <meta
          name="description"
          content="Coteri is an innovative web application designed to reshape the way people connect through music. Try out our Online Invitations to see how it works."
        />
      </Helmet>
      <Flex flex="1" direction="column" mb="280px">
        {/* <Text fontSize="20px" fontWeight="600" mb="30px">
          About Coteri
        </Text> */}
        <div className="privacy">
          <p className="c1">
            <span className="c2">
              Coteri is an innovative web application with a suite of software
              services designed to reshape the way people connect through music.
              With Coteri, users can seamlessly integrate their favorite music
              streaming services and engage in playlist curation alongside
              friends and acquaintances from different platforms.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              The inspiration behind Coteri originated from the common challenge
              of coordinating music for social gatherings and parties.
              Organizing a playlist that pleases a diverse group of attendees
              can be a daunting task, especially when unfamiliar with their
              individual music preferences. Coteri addresses this challenge by
              empowering users to receive suggestions and collaborate on
              playlists with their guests, reducing the stress of DJing their
              event.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              One of the significant advantages of Coteri is its web-based
              nature, eliminating the need for users to download a dedicated
              app. By embracing a web app approach, Coteri ensures ease of
              access and encourages widespread adoption among different groups
              of people. This accessibility is a boon for users, as they can
              enjoy the benefits of Coteri regardless of the device they use, be
              it a smartphone, tablet, or computer, as long as it has a web
              browser. By being platform-agnostic, supporting both iOS and
              Android, Coteri extends its reach to a larger audience,
              facilitating seamless collaboration across devices and operating
              systems. Moreover, the web-based nature of Coteri avoids occupying
              valuable storage space on users' devices, offering a lightweight
              and efficient solution while minimizing cyber risks associated
              with app downloads.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Coteri is available as a free service and currently supports
              integration with popular music streaming platform Spotify. 
              However, the vision for Coteri extends beyond these
              initial integrations, with plans underway to incorporate
              additional services such as YouTube Music. By expanding its reach
              to encompass a wider range of streaming platforms, Coteri aims to
              create a unified space where music enthusiasts can effortlessly
              collaborate and share their favorite tracks, regardless of the
              platform they prefer.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              With Coteri's user-friendly interface, cross-platform
              compatibility, and continuous efforts to integrate new services
              and functionalities, the web app seeks to change the way people
              connect through music.{' '}
            </span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1 c9">
            <span className="c2"></span>
          </p>
          <p className="c1">
            <span className="c2">
              Start collaborating with Coteri at{' '}
              <Link className="c13" style={{ color: '#422d8f' }} to="/">
                www.Coteri.us
              </Link>{' '}
            </span>
          </p>
        </div>
      </Flex>
    </Base>
  )
}

export default About
