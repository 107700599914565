import React, { memo, useEffect, useState } from 'react'
import Button from '../../Button/Button'
import Modal from '../../Modal/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { XIcon } from '../../../assets/icons'
import * as yup from 'yup'
import Input from '../../Input/Input'
import * as amplitude from '@amplitude/analytics-browser'

const options = [
  {
    label: 'Cocktail parties',
    value: 'cocktail-parties'
  },
  {
    label: 'Birthday parties',
    value: 'birthday-parties'
  },
  {
    label: 'Summer BBQ',
    value: 'summer-bbq'
  },
  {
    label: 'Potlucks',
    value: 'potlucks'
  },
  {
    label: 'Baby Showers',
    value: 'baby-showers'
  },
  {
    label: 'Bridal Showers',
    value: 'bridal-showers'
  },
  {
    label: 'Other',
    value: 'other'
  }
]

export const schema = yup
  .object({
    ocassion: yup.object().required(),
    other: yup.string().when('ocassion', (value, schema) => {
      const ocassion = value[0]

      if (ocassion?.value === 'other') {
        return schema.required()
      }

      return schema
    })
  })
  .required()

const CanvaModal = ({ circleCode, isOpen, handleOnClose, oldOcassion }) => {
  const [step, setStep] = useState(0)

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const [ocassion, other] = form.watch(['ocassion', 'other'])

  const increaseStep = () => {
    setStep(step + 1)
  }

  const decreaseStep = () => {
    if (step === 0) {
      return
    }

    setStep(step - 1)
  }

  const handleSubmit = (formData) => {
    amplitude.track('Canva navigated to', {
      circleCode
    })

    let query = formData.ocassion.value

    if (formData.ocassion.value === 'other') {
      const otherSearch = form.getValues('other')
      query = otherSearch.toLowerCase().replace(/ /g, '-')
    }

    const link = `https://www.canva.com/templates/?query=${query}&category=tAEwhV3GgCA&doctype=TABQqqo4v1s`

    form.reset()
    handleOnClose()
    window.open(link, '_blank')
  }

  const hadleOnClose = () => {
    form.reset()
    handleOnClose()
  }

  useEffect(() => {
    if (oldOcassion) {
      form.setValue('ocassion', oldOcassion)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={hadleOnClose}
      className={'max-w-[700px] w-full mx-5'}
    >
      <div className="p-10 bg-modal-bg rounded-input mx-3 md:mx-0">
        <div className="flex w-full items-center mt-2 justify-between">
          <span className="mr-2 font-bold text-[18px]">
            How to design with Canva?
          </span>
          <div onClick={handleOnClose} className="cursor-pointer self-end">
            <XIcon fill={'black'} width={25} height={25} />
          </div>
        </div>

        <div className="mt-5 relative w-full h-auto">
          <video
            src={require('../../../assets/videos/canva-tutorial.mp4')}
            playsInline
            controls
            className="rounded-md w-full h-full"
            autoPlay
          />
        </div>

        <Input
          register={form.register('ocassion')}
          type={'select'}
          className={'mt-[20px]'}
          placeholder="Select a category"
          options={options}
          form={form}
          placeholderBg={'#D7DEED'}
        />

        {ocassion?.value === 'other' && (
          <Input
            register={form.register('other')}
            className={'mt-[20px]'}
            placeholder="Search for category"
            form={form}
            placeholderBg={'#D7DEED'}
          />
        )}

        <div className="mt-4 flex items-center justify-center">
          {form.formState.isSubmitting ? (
            <ClipLoader color="#5B4ABC" />
          ) : (
            <Button
              text={'Continue to canva'}
              size="medium"
              onClick={form.handleSubmit(handleSubmit)}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

export default memo(CanvaModal)
